<template>
  <div>
    <div v-if="loading">
      <div class="preloder loading_main_background" id="upload_loader">
        <img src="../../assets/images/loader.gif" />
      </div>
    </div>
    <div class="floating-heading">
      <h4><span>Add Insurance Details</span></h4>
    </div>
    <div class="content-body-wrapper mt-4">
      <div class="page-content">
        <div class="page-data">
          <ValidationObserver ref="bankform" v-slot="{ invalid }">
            <form>
              <div class="row admin-edt-frm mt-2">
                <!-- left sec  -->
                <div class="col-md-6 left-side-frm">
                  <h5 class="line-heading">
                    <span>Customer & Insurance Details</span>
                  </h5>
                  <div class="row">
                    <div class="col-md-12 mt-3">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label align-right"
                            >Type<span style="color: red">*</span></label
                          >
                        </div>
                        <div class="col-md-8">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <select
                              class="form-control"
                              name="Insuranse Type"
                              v-model="vehicle_details.INSURANCE_TYPE"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  vehicle_details.INSURANCE_TYPE != '',
                              }"
                            >
                              <option value="">Select Insurance Type</option>
                              <option value="Extention">Extention</option>
                              <option value="Renewal(T)">Renewal(T)</option>
                              <option value="External(R)">External(R)</option>
                              <option value="External">External</option>
                              <option value="Trade Sales">Trade Sales</option>
                              <option value="Rental">Rental</option>
                              <option value="Temp CI">Temp CI</option>
                              <option value="Lek Auto INS">Lek Auto INS</option>
                            </select>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="row mt-2">
                        <div class="col-md-4">
                          <label class="frm-label-2 align-right"
                            >Customer Info<span style="color: red"
                              >*</span
                            ></label
                          >
                        </div>
                        <div class="col-md-8">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <b-input-group>
                              <b-form-input
                                id="CustomerInfo"
                                name="Customer Info"
                                v-model="sellertype"
                                class="form-control"
                                placeholder="Enter customer"
                                list="datalistOptions"
                                @keyup="onKeyUpCustomerInfo"
                                @change="onChnageCustomerInfo"
                                :class="{
                                  'is-invalid': errors[0] != null,
                                  'is-valid':
                                    errors[0] == null && sellertype != '',
                                }"
                              ></b-form-input>
                              <b-input-group-append>
                                <b-button
                                  variant="info"
                                  data-bs-toggle="modal"
                                  data-bs-target="#employee_modal"
                                >
                                  <i class="fa-solid fa-plus"></i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                            <datalist id="datalistOptions">
                              <option
                                v-for="customer_info in AllCustomerArray"
                                :value="customer_info.CUSTOMER_NAME"
                                :key="customer_info.CUSTOMER_SYS_ID"
                              ></option>
                            </datalist>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12" v-if="DisplayCustomerType != ''">
                      <div class="row mt-2">
                        <div class="col-md-4">
                          <label class="frm-label-2 align-right"
                            >Customer Type<span style="color: red"
                              >*</span
                            ></label
                          >
                        </div>
                        <div class="col-md-8">
                          <input
                            type="text"
                            id="CustomerType"
                            name="CustomerType"
                            class="form-control"
                            v-model="DisplayCustomerType"
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-md-12"
                      v-if="
                        DisplayCustomerType == 'individual' &&
                        DisplayIdNumber != ''
                      "
                    >
                      <div class="row mt-2">
                        <div class="col-md-4">
                          <label class="frm-label-2 align-right"
                            >NRIC Number<span style="color: red">*</span></label
                          >
                        </div>
                        <div class="col-md-8">
                          <input
                            type="text"
                            id="NRIC Number"
                            name="NRIC Number"
                            class="form-control"
                            v-model="DisplayIdNumber"
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-md-12"
                      v-if="
                        DisplayCustomerType == 'Company' &&
                        DisplayIdNumber != ''
                      "
                    >
                      <div class="row mt-2">
                        <div class="col-md-4">
                          <label class="frm-label-2 align-right"
                            >UEN Number<span style="color: red">*</span></label
                          >
                        </div>
                        <div class="col-md-8">
                          <input
                            type="text"
                            id="UEN Number"
                            name="UEN Number"
                            class="form-control"
                            v-model="DisplayIdNumber"
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12" v-if="DisplayCustomerName != ''">
                      <div class="row mt-2">
                        <div class="col-md-4">
                          <label class="frm-label-2 align-right"
                            >Customer Name<span style="color: red"
                              >*</span
                            ></label
                          >
                        </div>
                        <div class="col-md-8">
                          <input
                            type="text"
                            id="Customer Name"
                            name="Customer Name"
                            class="form-control"
                            v-model="DisplayCustomerName"
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12" v-if="DisplayCustomerAddress != ''">
                      <div class="row mt-2">
                        <div class="col-md-4">
                          <label class="frm-label-2 align-right"
                            >Customer Address<span style="color: red"
                              >*</span
                            ></label
                          >
                        </div>
                        <div class="col-md-8">
                          <textarea
                            style="height: 4em"
                            id="Customer Address"
                            name="Customer Address"
                            class="form-control"
                            v-model="DisplayCustomerAddress"
                            disabled
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-md-12 mt-3" v-show="truez">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label"
                            >CO. Registration No.</label
                          >
                        </div>
                        <div class="col-md-8">
                            <input
                              type="text"
                              name="CO. Registration No."
                              v-model="vehicle_details.VEHICLE_REG_NO"
                              class="form-control"
                              placeholder="Enter Reg No."
                            />
                        </div>
                      </div>
                    </div> -->

                    <div class="col-md-12 mt-3">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label">Debit Note No.</label>
                        </div>
                        <div class="col-md-8">
                          <input
                            type="text"
                            v-model="vehicle_details.DEBIT_NOTE_NO"
                            name="Debit Note No."
                            class="form-control"
                            placeholder="Enter Debit Note No."
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-3">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label">Policy No.</label>
                        </div>
                        <div class="col-md-8">
                          <input
                            type="text"
                            v-model="vehicle_details.POLICY_NO"
                            name="Policy No."
                            class="form-control"
                            placeholder="Enter Policy No."
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-3">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label">Coverage</label>
                        </div>
                        <div class="col-md-8">
                          <select
                            v-model="vehicle_details.COVERAGE"
                            class="form-control"
                            name="Coverage"
                          >
                            <option value="">Select</option>
                            <option value="COMPREHENSIVE">COMPREHENSIVE</option>
                            <option value="TPFT">TPFT</option>
                            <option value="TPO">TPO</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Bill To<span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <select
                              v-model="vehicle_details.BILL_TO"
                              class="form-control"
                              name="Bill To"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  vehicle_details.BILL_TO != '',
                              }"
                            >
                              <option value="">Select</option>
                              <option value="Direct Payment">
                                Direct Payment
                              </option>
                              <option value=" Bill to Customer">
                                Bill to Customer
                              </option>
                            </select>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>

                <!-- right sec  -->
                <div class="col-md-6">
                  <h5 class="line-heading"><span>Vehicle Details</span></h5>
                  <div class="row">
                    <div class="col-md-12 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label">Vehicle No.</label>
                          </div>
                          <div class="col-md-8">
                            <b-input-group>
                              <b-form-input
                                id="VehicleNo"
                                name="VehicleNo"
                                class="form-control"
                                placeholder="Enter Vehicle No"
                                v-model="vehicle_number"
                                @keyup="onKeyUpVehicleInfo()"
                                @change="onChnageVehicleInfo"
                                :disabled="is_disabled"
                                list="datalistOptionsVehicles"
                                :class="{
                                  'is-invalid': errors[0] != null,
                                  'is-valid':
                                    errors[0] == null && vehicle_number != '',
                                }"
                              ></b-form-input>
                            </b-input-group>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                            <datalist id="datalistOptionsVehicles">
                              <option
                                v-for="(
                                  vehicle_details, index
                                ) in all_vehicle_details"
                                :key="index"
                                :value="vehicle_details.VEHICLE_NUMBER"
                              ></option>
                            </datalist>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-12 mt-3" v-if="vehicle_number">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label align-right"
                            >Vehicle Type<span style="color: red"
                              >*</span
                            ></label
                          >
                        </div>
                        <div class="col-md-8">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <select
                              class="form-control"
                              name="Vehicle Type"
                              :disabled="is_disabled"
                              v-model="
                                particular_vehicle_details.VEHICLE_STATUS
                              "
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  particular_vehicle_details.VEHICLE_STATUS !=
                                    '',
                              }"
                            >
                              <option value="">Select Vehicle Type</option>
                              <option value="Pre Order">Pre Order</option>
                              <option value="New Vehicle">New Vehicle</option>
                              <option value="Local">Local</option>
                              <option value="ETS">ETS</option>
                              <option value="Scrap">Scrap</option>
                              <option value="Trade-in">Trade-in</option>
                            </select>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-3" v-if="vehicle_number">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Make<span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <select
                              name="Make"
                              v-model="particular_vehicle_details.MAKE_SYS_ID"
                              :disabled="is_disabled"
                              class="form-control"
                              @change="
                                getVehicleMakeName(
                                  particular_vehicle_details.MAKE_SYS_ID
                                )
                              "
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  particular_vehicle_details.MAKE_SYS_ID != '',
                              }"
                            >
                              <option value="">Select Manufracturer</option>
                              <option
                                v-for="makenm in Manufracturename"
                                :value="makenm.VEHICLE_MAKE_SYS_ID"
                                :key="makenm.VEHICLE_MAKE_SYS_ID"
                              >
                                {{ makenm.VEHICLE_MAKE }}
                              </option>
                            </select>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-12 mt-3" v-if="vehicle_number">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Model<span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="particular_vehicle_details.MODEL_NAME"
                              :disabled="is_disabled"
                              class="form-control"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  particular_vehicle_details.MODEL_NAME != '',
                              }"
                              placeholder=""
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-12 mt-3" v-if="vehicle_number">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Engine No<span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              name="Engine"
                              v-model="particular_vehicle_details.ENGINE_NO"
                              :disabled="is_disabled"
                              class="form-control"
                              placeholder="Enter Engine No"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  particular_vehicle_details.ENGINE_NO != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-12 mt-3" v-if="vehicle_number">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Chasis No<span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="particular_vehicle_details.CHASIS_NO"
                              name="Chasis"
                              class="form-control"
                              placeholder="Enter Chasis No"
                              :disabled="is_disabled"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  particular_vehicle_details.CHASIS_NO != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-12 mt-3" v-if="vehicle_number">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Orig Date<span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              name="Orig Date"
                              v-model="
                                particular_vehicle_details.ORIGINAL_REGISTRATION_DATE
                              "
                              class="form-control"
                              :disabled="is_disabled"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  particular_vehicle_details.ORIGINAL_REGISTRATION_DATE !=
                                    '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-12 mt-3" v-if="vehicle_number">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Year<span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              name="Year"
                              maxlength="4"
                              :disabled="is_disabled"
                              v-model="
                                particular_vehicle_details.MANUFACTURE_YEAR
                              "
                              class="form-control"
                              placeholder="Enter Year"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  particular_vehicle_details.MANUFACTURE_YEAR !=
                                    '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-12 mt-3" v-if="vehicle_number">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Laden<span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="number"
                              name="Laden"
                              :disabled="is_disabled"
                              v-model="particular_vehicle_details.LADEN"
                              class="form-control"
                              placeholder="Enter Laden"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  particular_vehicle_details.LADEN != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-12 mt-3" v-if="vehicle_number">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Unladen<span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="number"
                              name="Unladen"
                              v-model="particular_vehicle_details.UNLADEN"
                              class="form-control"
                              :disabled="is_disabled"
                              placeholder="Enter Unladen"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  particular_vehicle_details.UNLADEN != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-12 mt-3" v-if="vehicle_number">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Seat No.<span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="particular_vehicle_details.PAX"
                              :disabled="is_disabled"
                              name="Seat No."
                              class="form-control"
                              placeholder="Enter Seat No."
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  particular_vehicle_details.PAX != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-5">
                  <!-- <div class="col-md-3"> -->
                  <button
                    type="button"
                    @click="createInsuranceDetails"
                    class="custom-btn custom-success-btn"
                    :disabled="invalid"
                  >
                    Save
                  </button>
                  <!-- </div> -->
                </div>
              </div>
            </form>
          </ValidationObserver>
          <div class="row admin-edt-frm mt-2">
            <!-- left side -->
            <div class="col-md-6 left-side-frm">
              <div class="row mt-4">
                <h5 class="line-heading">
                  <span>NCD Transfer<span style="color: red">*</span></span>
                </h5>

                <div class="col-md-12 mt-2">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label">Previous Insurer</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-2">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label"
                        >NCD% Entitlement<span style="color: red"
                          >*</span
                        ></label
                      >
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-2">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label">Cancellation/Expiry Date</label>
                    </div>
                    <div class="col-md-8">
                      <input type="date" disabled class="form-control" />
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-2">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label"
                        >NCD Transfer From<span style="color: red"
                          >*</span
                        ></label
                      >
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-2">
                  <div class="accordion accordion-flush" id="accordionExample">
                    <div class="row">
                      <div class="col-md-4">
                        <label
                          class="accordion-header frm-label"
                          id="headingTwo"
                        >
                          <a
                            class="collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            style="color: #6e6b7b"
                          >
                            <i class="fa-solid fa-circle-plus"></i>&nbsp; Add
                            Remarks
                          </a>
                        </label>
                      </div>
                      <div class="col-md-8">
                        <div class="accordion-item">
                          <div
                            id="collapseTwo"
                            class="collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div
                              class="accordion-body p-0"
                              style="border: none; outline: none"
                            >
                              <textarea
                                disabled
                                class="custom-textarea form-control"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="row mt-4">
                    <h5 class="line-heading"><span>Payable</span></h5>
                    <div class="col-md-12 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label"
                            >Company<span style="color: red">*</span></label
                          >
                        </div>
                        <div class="col-md-8">
                          <input disabled type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label"
                            >Salesman<span style="color: red">*</span></label
                          >
                        </div>
                        <div class="col-md-8">
                          <input disabled type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label"
                            >Sale Type<span style="color: red">*</span></label
                          >
                        </div>
                        <div class="col-md-8">
                          <input disabled type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label"
                            >Premium Details<span style="color: red"
                              >*</span
                            ></label
                          >
                        </div>
                        <div class="col-md-8">
                          <input disabled type="text" class="form-control" />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label"
                            >Salesman Commission($)<span style="color: red"
                              >*</span
                            ></label
                          >
                        </div>
                        <div class="col-md-8">
                          <div class="input-group">
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label"
                            >TAX<span style="color: red">*</span></label
                          >
                        </div>
                        <div class="col-md-8">
                          <input disabled type="text" class="form-control" />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label">Premium Payable</label>
                        </div>
                        <div class="col-md-8">
                          <input disabled type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-md-3">
                      <button
                        type="button"
                        class="custom-btn custom-success-btn"
                      >
                        Save
                      </button>
                    </div>
                    <div class="col-md-2">
                      <button
                        type="button"
                        class="custom-btn custom-danger-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="row mt-4">
                    <h5 class="line-heading"><span>Payment</span></h5>

                    <div class="col-md-12 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label">Entry Date</label>
                        </div>
                        <div class="col-md-8">
                          <input disabled type="date" class="form-control" />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label">AR.</label>
                        </div>
                        <div class="col-md-8">
                          <input
                            disabled
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label"
                            >Payment Voucher for Oc</label
                          >
                        </div>
                        <div class="col-md-8">
                          <input
                            disabled
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label">D/N</label>
                        </div>
                        <div class="col-md-8">
                          <input
                            disabled
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label">C/N</label>
                        </div>
                        <div class="col-md-8">
                          <input
                            disabled
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" style="margin-top: 125px">
                  <div class="row">
                    <div class="col-md-3">
                      <button
                        type="button"
                        class="custom-btn custom-success-btn"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- right side -->
            <div class="col-md-6 mt-4">
              <!-- premium section  -->
              <div class="col-md-12">
                <div class="divider-4">
                  <h5 class="line-heading"><span>premium</span></h5>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-12 mt-5">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label">Dealer</label>
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              name="Dealer"
                              :disabled="disable"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-3">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label">Insur Co.</label>
                      </div>
                      <div class="col-md-8">
                        <select
                          :disabled="disable"
                          v-model="vehicle_details.INSURANCE_COMPANY_SYS_ID"
                          class="form-control"
                          @change="getInsuranceCompanyName"
                        >
                          <option value="">Select</option>
                          <option
                            v-for="insurance_company in insurance_company_details"
                            :value="insurance_company.INSURANCE_COMPANY_SYS_ID"
                            :key="insurance_company.INSURANCE_COMPANY_SYS_ID"
                          >
                            {{ insurance_company.INSURANCE_COMPANY }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-3">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label">To Agent</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          :disabled="disable"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mt-3">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label">Periods</label>
                      </div>
                      <div class="col-md-8">
                        <div class="row">
                          <div class="col-md-5">
                            <input
                              type="date"
                              :disabled="disable"
                              v-model="vehicle_details.INSURANCE_START_DATE"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                          <div class="col-md-2">
                            <label class="frm-label mt-1">To</label>
                          </div>
                          <div class="col-md-5">
                            <input
                              type="date"
                              :disabled="disable"
                              v-model="vehicle_details.INSURANCE_END_DATE"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="divider-4"></div>
                  </div>
                  <div class="col-md-12 mt-3">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label">Sum Insured($)</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          :disabled="disable"
                          v-model="vehicle_details.SUM_INSURED"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label">Basic Premium($)</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          :disabled="disable"
                          v-model="vehicle_details.BASE_PREMIUM"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label">No-Claim Disc.</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          :disabled="disable"
                          v-model="vehicle_details.NO_CLAIM_DISC"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">Safer Disc</label><br />
                        <div class="d-flex">
                          <div class="d-block mb-1">
                            <input
                              :disabled="disable"
                              type="radio"
                              name="safer-disc"
                              class="radio yes"
                              id="safer-yes"
                            /><label
                              class="frm-label-2 radio-lbls ms-1"
                              for="safer-yes"
                              >Yes</label
                            >
                          </div>
                          <div class="d-block mb-1" style="margin-left: 10px">
                            <input
                              :disabled="disable"
                              type="radio"
                              name="safer-disc"
                              class="radio no"
                              id="safer-no"
                            /><label
                              class="frm-label-2 radio-lbls ms-1"
                              for="safer-no"
                              >No</label
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-8 calculatnbar">
                        <div class="row">
                          <div class="col-md-6 mt-2">
                            <div class="input-group">
                              <input
                                :disabled="disable"
                                type="text"
                                v-model="vehicle_details.SAFER_DISC_PERCENTAGE"
                                @keyup="saferdisc_calcu"
                                class="form-control"
                                placeholder=""
                              />
                              <span class="input-group-text" id="basic-addon2"
                                >%</span
                              >
                            </div>
                          </div>
                          <div class="col-md-6 mt-2">
                            <div class="input-group">
                              <span class="input-group-text">$</span>
                              <input
                                :disabled="disable"
                                type="text"
                                v-model="vehicle_details.SAFER_DISC_VALUE"
                                class="form-control"
                                aria-label="Amount (to the nearest dollar)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">Loyalty Disc</label><br />
                        <div class="d-flex">
                          <div class="d-block mb-1">
                            <input
                              :disabled="disable"
                              type="radio"
                              name="safer-disc2"
                              class="radio yes2"
                              id="safer-yes2"
                            /><label
                              class="frm-label-2 radio-lbls ms-1"
                              for="safer-yes"
                              >Yes</label
                            >
                          </div>
                          <div class="d-block mb-1" style="margin-left: 10px">
                            <input
                              :disabled="disable"
                              type="radio"
                              name="safer-disc2"
                              class="radio no2"
                              id="safer-no2"
                            /><label
                              class="frm-label-2 radio-lbls ms-1"
                              for="safer-no"
                              >No</label
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-8 calculatnbar2">
                        <div class="row">
                          <div class="col-md-6 mt-2">
                            <div class="input-group">
                              <input
                                type="text"
                                :disabled="disable"
                                @keyup="loyaltydisc_calcu"
                                v-model="
                                  vehicle_details.LOYALITY_DISC_PERCENTAGE
                                "
                                class="form-control"
                                placeholder=""
                              />
                              <span class="input-group-text" id="basic-addon2"
                                >%</span
                              >
                            </div>
                          </div>
                          <div class="col-md-6 mt-2">
                            <div class="input-group">
                              <span class="input-group-text">$</span>
                              <input
                                type="text"
                                disabled
                                v-model="vehicle_details.LOYALITY_DISC_VALUE"
                                class="form-control"
                                aria-label="Amount (to the nearest dollar)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">Fleet Disc</label><br />
                        <div class="d-flex">
                          <div class="d-block mb-1">
                            <input
                              type="radio"
                              disabled
                              name="safer-disc3"
                              class="radio yes3"
                              id="safer-yes3"
                            /><label
                              class="frm-label-2 radio-lbls ms-1"
                              for="safer-yes"
                              >Yes</label
                            >
                          </div>
                          <div class="d-block mb-1" style="margin-left: 10px">
                            <input
                              disabled
                              type="radio"
                              name="safer-disc3"
                              class="radio no3"
                              id="safer-no3"
                            /><label
                              class="frm-label-2 radio-lbls ms-1"
                              for="safer-no"
                              >No</label
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-8 calculatnbar3">
                        <div class="row">
                          <div class="col-md-6 mt-2">
                            <div class="input-group">
                              <input
                                type="text"
                                disabled
                                @keyup="fleetdisc_calcu"
                                v-model="vehicle_details.FLEET_DISC_PERCENTAGE"
                                class="form-control"
                                placeholder=""
                              />
                              <span class="input-group-text" id="basic-addon2"
                                >%</span
                              >
                            </div>
                          </div>
                          <div class="col-md-6 mt-2">
                            <div class="input-group">
                              <span class="input-group-text">$</span>
                              <input
                                type="text"
                                disabled
                                v-model="vehicle_details.FLEET_DISC_VALUE"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="col-md-12 mt-2">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                          <label class="frm-label">Premium</label>
                                                        </div>
                                                        <div class="col-md-8">
                                                          <input type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                </div> -->
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">Fleet Disc</label><br />
                        <div class="d-flex">
                          <div class="d-block mb-1">
                            <input
                              type="radio"
                              disabled
                              name="safer-disc3"
                              class="radio yes3"
                              id="safer-yes3"
                            /><label
                              class="frm-label-2 radio-lbls ms-1"
                              for="safer-yes"
                              >Yes</label
                            >
                          </div>
                          <div class="d-block mb-1" style="margin-left: 10px">
                            <input
                              disabled
                              type="radio"
                              name="safer-disc3"
                              class="radio no3"
                              id="safer-no3"
                            /><label
                              class="frm-label-2 radio-lbls ms-1"
                              for="safer-no"
                              >No</label
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-8 calculatnbar3">
                        <div class="row">
                          <div class="col-md-6 mt-2">
                            <div class="input-group">
                              <input
                                type="text"
                                disabled
                                @keyup="fleetdisc_calcu"
                                v-model="vehicle_details.FLEET_DISC_PERCENTAGE"
                                class="form-control"
                                placeholder=""
                              />
                              <span class="input-group-text" id="basic-addon2"
                                >%</span
                              >
                            </div>
                          </div>
                          <div class="col-md-6 mt-2">
                            <div class="input-group">
                              <span class="input-group-text">$</span>
                              <input
                                disabled
                                type="text"
                                v-model="vehicle_details.FLEET_DISC_VALUE"
                                class="form-control"
                                aria-label="Amount (to the nearest dollar)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">Direct Disc</label><br />
                        <div class="d-flex">
                          <div class="d-block mb-1">
                            <input
                              disabled
                              type="radio"
                              name="safer-disc4"
                              class="radio yes4"
                              id="safer-yes4"
                            /><label
                              class="frm-label-2 radio-lbls ms-1"
                              for="safer-yes"
                              >Yes</label
                            >
                          </div>
                          <div class="d-block mb-1" style="margin-left: 10px">
                            <input
                              disabled
                              type="radio"
                              name="safer-disc4"
                              class="radio no4"
                              id="safer-no4"
                            /><label
                              class="frm-label-2 radio-lbls ms-1"
                              for="safer-no"
                              >No</label
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-8 calculatnbar4">
                        <div class="row">
                          <div class="col-md-6 mt-2">
                            <div class="input-group">
                              <input
                                type="text"
                                disabled
                                @keyup="directdisc_calcu"
                                v-model="vehicle_details.DIRECT_DISC_PERCENTAGE"
                                class="form-control"
                                placeholder=""
                              />
                              <span class="input-group-text" id="basic-addon2"
                                >%</span
                              >
                            </div>
                          </div>
                          <div class="col-md-6 mt-2">
                            <div class="input-group">
                              <span class="input-group-text">$</span>
                              <input
                                type="text"
                                disabled
                                v-model="vehicle_details.DIRECT_DISC_VALUE"
                                class="form-control"
                                aria-label="Amount (to the nearest dollar)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-2">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2">Insurance Disc</label><br />
                      <div class="d-flex">
                        <div class="d-block mb-1">
                          <input
                            disabled
                            type="radio"
                            name="safer-disc5"
                            class="radio yes5"
                            id="safer-yes5"
                          /><label
                            class="frm-label-2 radio-lbls ms-1"
                            for="safer-yes"
                            >Yes</label
                          >
                        </div>
                        <div class="d-block mb-1" style="margin-left: 10px">
                          <input
                            disabled
                            type="radio"
                            name="safer-disc5"
                            class="radio no5"
                            id="safer-no5"
                          /><label
                            class="frm-label-2 radio-lbls ms-1"
                            for="safer-no"
                            >No</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 calculatnbar5">
                      <div class="row">
                        <div class="col-md-6 mt-2">
                          <div class="input-group">
                            <input
                              type="text"
                              disabled
                              @keyup="insurancedisc_calcu"
                              v-model="
                                vehicle_details.INSURANCE_DISC_PERCENTAGE
                              "
                              class="form-control"
                              placeholder=""
                            />
                            <span class="input-group-text" id="basic-addon2"
                              >%</span
                            >
                          </div>
                        </div>
                        <div class="col-md-6 mt-2">
                          <div class="input-group">
                            <span class="input-group-text">$</span>
                            <input
                              type="text"
                              disabled
                              v-model="vehicle_details.INSURANCE_DISC_VALUE"
                              class="form-control"
                              aria-label="Amount (to the nearest dollar)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label">TAX</label>
                    </div>
                    <div class="col-md-8">
                      <div class="row">
                        <div class="col-md-6">
                          <select
                            disabled
                            v-model="vehicle_details.TAX_SYS_ID"
                            class="form-control"
                            @change="getgstName"
                          >
                            <option value="">Select</option>
                            <option
                              v-for="tax_info in tax_info_details"
                              :value="tax_info.TAX_SYS_ID"
                              :key="tax_info.TAX_SYS_ID"
                            >
                              {{ tax_info.TAX_NAME }}-{{
                                tax_info.TAX_PERCENTAGE
                              }}
                              %
                            </option>
                          </select>
                        </div>
                        <!-- <div class="col-md-2 mt-2" style="text-align: left;">
                                                         <label class="frm-label-2 ">%</label>
                                                      </div> -->
                        <div class="col-md-6">
                          <input
                            type="text"
                            disabled
                            v-model="vehicle_details.TAX_AMOUNT"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label">Gross Premium</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        v-model="vehicle_details.FINAL_PREMIUM"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- receivable sewction  -->
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row mt-4">
                      <h5 class="line-heading"><span>Receivable</span></h5>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label">Premium</label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" disabled class="form-control" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label">Dealer Comm</label>
                          </div>
                          <div class="col-md-8">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="input-group mb-3">
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                  />
                                  <span
                                    class="input-group-text"
                                    id="basic-addon2"
                                    >% (-)</span
                                  >
                                </div>
                              </div>
                              <div class="col-md-6">
                                <input
                                  disabled
                                  type="text"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label">TAX</label>
                          </div>
                          <div class="col-md-8">
                            <div class="row">
                              <div class="col-md-6">
                                <select disabled class="form-control">
                                  <option>Select</option>
                                  <option>GST 7%</option>
                                  <option>GST 10%</option>
                                </select>
                              </div>
                              <!-- <div class="col-md-2 mt-2" style="text-align: left;">
                                                                <label class="frm-label-2 ">%</label>
                                                              </div> -->
                              <div class="col-md-6">
                                <input
                                  disabled
                                  type="text"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label">Premium Receivable</label>
                          </div>
                          <div class="col-md-8">
                            <input disabled type="text" class="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="row mt-5">
                      <div class="col-md-3">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="divider-4"></div>
          </div>
          <div class="col-md-12 mt-5">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4">
                    <label class="frm-label">Excess (Named Driver)</label>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-6">
                        <input disabled type="text" class="form-control" />
                      </div>
                      <div class="col-md-6">
                        <input disabled type="text" class="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4">
                    <label class="frm-label">Excess (Unnamed Driver)</label>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-6">
                        <input disabled type="text" class="form-control" />
                      </div>
                      <div class="col-md-6">
                        <input disabled type="text" class="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 mt-2">
                <div class="row">
                  <div class="col-md-4">
                    <label class="frm-label">Excess (Work Permit Hold)</label>
                  </div>
                  <div class="col-md-8">
                    <input
                      disabled
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6 mt-2">
                <div class="row">
                  <div class="col-md-4">
                    <label class="frm-label">Excess (Young Driver)</label>
                  </div>
                  <div class="col-md-8">
                    <input
                      disabled
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6 mt-2">
                <div class="row">
                  <div class="col-md-4">
                    <label class="frm-label">Excess (Windscreen)</label>
                  </div>
                  <div class="col-md-8">
                    <input
                      disabled
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6 mt-2">
                <div class="row">
                  <div class="col-md-4">
                    <label class="frm-label">Workshop Scheme</label>
                  </div>
                  <div class="col-md-8">
                    <select disabled class="form-control">
                      <option>Select</option>
                      <option>Option 1</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-6 mt-2">
                <div class="row">
                  <div class="col-md-4">
                    <label class="frm-label">Extra Benefit</label>
                  </div>
                  <div class="col-md-8">
                    <input
                      disabled
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div id="driver" v-show="show">
                <div class="col-md-12 mt-5">
                  <div class="row">
                    <div class="col-md-4 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label">Driver Name</label>
                        </div>
                        <div class="col-md-8">
                          <input
                            disabled
                            type="text"
                            v-model="driver_details.DRIVER_NAME"
                            class="form-control"
                            placeholder="Enter Driver Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label">Occupation</label>
                        </div>
                        <div class="col-md-8">
                          <input
                            type="text"
                            disabled
                            v-model="driver_details.OCCUPATION"
                            class="form-control"
                            placeholder="Enter Occupation"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label">Driving Pass Dt</label>
                        </div>
                        <div class="col-md-8">
                          <input
                            disabled
                            type="date"
                            v-model="driver_details.DRIVING_PASS_DATE"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label">Relationship</label>
                        </div>
                        <div class="col-md-8">
                          <input
                            disabled
                            type="text"
                            v-model="driver_details.RELATIONSHIP"
                            class="form-control"
                            placeholder="Enter Relationship"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label">I/C Number</label>
                        </div>
                        <div class="col-md-8">
                          <input
                            disabled
                            type="text"
                            v-model="driver_details.I_C_NUMBER"
                            class="form-control"
                            placeholder="Enter I/C Number"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mt-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label">DOB</label>
                        </div>
                        <div class="col-md-8">
                          <input
                            disabled
                            type="date"
                            v-model="driver_details.DOB"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12" id="add_item">
                  <div class="row">
                    <div class="col-md-2 mt-4">
                      <button
                        type="button"
                        class="custom-btn custom-secondary-btn-4 aliasadd"
                      >
                        <i class="fa-solid fa-user-plus"></i>&nbsp; Add Driver
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-4">
                <button
                  type="button"
                  @click="add_driver"
                  class="custom-btn custom-success-btn"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          class="modal modal-right fade"
          id="employee_modal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl" style="width: 75% !important">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Add Customer</h5>
                <button
                  type="button"
                  class="btn-normal modal-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </button>
                <!-- <button type="button" class="btn-normal modal-close" data-bs-dismiss="modal" aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                      </button> -->
              </div>
              <div class="modal-body">
                <div class="row">
                  <!-- <div class="col-md-5 mt-2">
                            <label class="frm-label">user type</label>
                            <input type="text" class="form-control">
                        </div> -->
                  <!-- <div class="col-md-6"> -->
                  <!-- <div class="row"> -->
                  <div class="col-md-6 mt-2">
                    <label class="frm-label"
                      >Customer Type <span style="color: red">*</span></label
                    >
                    <select
                      id="customertype"
                      v-model="create_customer.CUSTOMER_TYPE"
                      class="form-control"
                    >
                      <option value="">Select</option>
                      <option value="individual">Individual</option>
                      <option value="Company">Company</option>
                    </select>
                  </div>
                  <!-- </div>
                  </div> -->
                  <div class="individual box">
                    <ValidationObserver ref="cus">
                      <div class="row">
                        <div class="col-md-4 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label class="frm-label"
                              >NRIC Number
                              <span style="color: red">*</span></label
                            >
                            <input
                              type="text"
                              name="NRIC Number"
                              v-model.trim="create_customer.ID_NUMBER"
                              class="form-control"
                              placeholder="Enter NRIC Number"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_customer.ID_NUMBER != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label class="frm-label"
                              >Full Name
                              <span style="color: red">*</span></label
                            >
                            <input
                              type="name"
                              name="Full Name"
                              v-model.trim="create_customer.CUSTOMER_NAME"
                              class="form-control"
                              placeholder="Enter full Name"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_customer.CUSTOMER_NAME != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4 mt-3">
                          <ValidationProvider
                            rules="required|mobileNumber"
                            v-slot="{ errors }"
                          >
                            <label class="frm-label"
                              >Mobile Number<span style="color: red"
                                >*</span
                              ></label
                            >
                            <input
                              type="name"
                              name="Mobile.NO"
                              v-model.trim="create_customer.CUSTOMER_CONTACT"
                              class="form-control"
                              placeholder="Enter Mobile Number"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_customer.CUSTOMER_CONTACT != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-4 mt-3">
                          <ValidationProvider
                            rules="required|email"
                            v-slot="{ errors }"
                          >
                            <label class="frm-label"
                              >Email ID <span style="color: red">*</span></label
                            >
                            <input
                              type="text"
                              name="Email Id"
                              v-model.trim="create_customer.CUSTOMER_EMAIL"
                              class="form-control"
                              placeholder="Enter Email ID"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_customer.CUSTOMER_EMAIL != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label class="frm-label"
                              >Address Line 1
                              <span style="color: red">*</span></label
                            >
                            <input
                              type="name"
                              name="Address 1"
                              v-model.trim="create_customer.ADDRESS_LINE_1"
                              class="form-control"
                              placeholder="Enter Address"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_customer.ADDRESS_LINE_1 != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-4 mt-3">
                          <label class="frm-label">Address Line 2</label>
                          <input
                            type="text"
                            name="Address Line 2"
                            v-model.trim="create_customer.ADDRESS_LINE_2"
                            class="form-control"
                            placeholder="Enter Address"
                          />
                        </div>
                        <div class="col-md-4 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label class="frm-label"
                              >post Code
                              <span style="color: red">*</span></label
                            >
                            <input
                              type="number"
                              name="Post code"
                              v-model.trim="create_customer.PIN_CODE"
                              class="form-control"
                              placeholder="Enter Post Code"
                              maxlength="6"
                              minlength="6"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_customer.PIN_CODE != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4 mt-3">
                          <ValidationProvider v-slot="{ errors }">
                            <label class="frm-label"
                              >Country <span style="color: red">*</span></label
                            >
                            <b-form-select
                              name="Country"
                              v-model="create_customer.COUNTRY_NAME"
                              :class="{
                                'form-control': true,
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_company.COUNTRY_NAME != '',
                              }"
                            >
                              <template #first>
                                <b-form-select-option :value="null" disabled
                                  >-- Select --</b-form-select-option
                                >
                              </template>
                              <option value="Singapore">Singapore</option>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>

                        <div class="modal-footer">
                          <div class="row mt-4">
                            <div class="col-md-3">
                              <button
                                type="button"
                                class="custom-btn custom-success-btn"
                                @click="Addcustomer"
                              >
                                Save
                              </button>
                            </div>
                            <div class="col-md-2">
                              <button
                                type="button"
                                class="custom-btn custom-danger-btn"
                                data-bs-dismiss="modal"
                                @click="emptyfu"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ValidationObserver>
                  </div>

                  <div class="Company box">
                    <ValidationObserver ref="bankfo">
                      <div class="row">
                        <div class="col-md-4 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label class="frm-label"
                              >UEN Number
                              <span style="color: red">*</span></label
                            >
                            <input
                              type="text"
                              name="UEN Number"
                              v-model.trim="create_company.ID_NUMBER"
                              class="form-control"
                              placeholder="Enter UEN Number"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_company.ID_NUMBER != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label class="frm-label"
                              >Company Name
                              <span style="color: red">*</span></label
                            >
                            <input
                              type="name"
                              name="Company Name"
                              v-model.trim="create_company.CUSTOMER_NAME"
                              class="form-control"
                              placeholder="Enter Company Name"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_company.CUSTOMER_NAME != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label class="frm-label"
                              >Person Incharge
                              <span style="color: red">*</span></label
                            >
                            <input
                              type="name"
                              name="person incharge"
                              v-model.trim="create_company.PERSON_INCHARGE"
                              class="form-control"
                              placeholder="Enter Person incharge"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_company.PERSON_INCHARGE != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4 mt-3">
                          <ValidationProvider
                            rules="required|mobileNumber"
                            v-slot="{ errors }"
                          >
                            <label class="frm-label"
                              >Mobile.NO
                              <span style="color: red">*</span></label
                            >
                            <input
                              type="name"
                              name="Mobile Number"
                              v-model.trim="create_company.CUSTOMER_CONTACT"
                              class="form-control"
                              placeholder="Enter Mobile Number"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_company.CUSTOMER_CONTACT != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4 mt-3">
                          <ValidationProvider
                            rules="required|email"
                            v-slot="{ errors }"
                          >
                            <label class="frm-label"
                              >Email ID<span style="color: red">*</span></label
                            >
                            <input
                              type="text"
                              name="gmail"
                              v-model.trim="create_company.CUSTOMER_EMAIL"
                              class="form-control"
                              placeholder="Enter Email ID"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_company.CUSTOMER_EMAIL != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label class="frm-label"
                              >Address Line 1
                              <span style="color: red">*</span></label
                            >
                            <input
                              type="name"
                              name="address line 1"
                              v-model.trim="create_company.ADDRESS_LINE_1"
                              class="form-control"
                              placeholder="Enter Address"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_company.ADDRESS_LINE_1 != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-4 mt-3">
                          <label class="frm-label">Address Line 2 </label>
                          <input
                            type="name"
                            name="Address Line 2"
                            v-model.trim="create_company.ADDRESS_LINE_2"
                            class="form-control"
                            placeholder="Enter Address"
                          />
                        </div>

                        <div class="col-md-4 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label class="frm-label"
                              >post Code
                              <span style="color: red">*</span></label
                            >
                            <input
                              type="name"
                              name="Post code"
                              v-model.trim="create_company.PIN_CODE"
                              class="form-control"
                              placeholder="Enter Post Code"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_company.PIN_CODE != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-4 mt-3">
                          <ValidationProvider v-slot="{ errors }">
                            <label class="frm-label"
                              >Country <span style="color: red">*</span></label
                            >
                            <b-form-select
                              name="Country"
                              v-model="create_company.COUNTRY_NAME"
                              :class="{
                                'form-control': true,
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_company.COUNTRY_NAME != '',
                              }"
                            >
                              <template #first>
                                <b-form-select-option :value="null" disabled
                                  >-- Select --</b-form-select-option
                                >
                              </template>
                              <option value="">Singapore</option>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>

                        <div class="modal-footer">
                          <div class="row mt-4">
                            <div class="col-md-3">
                              <button
                                type="button"
                                class="custom-btn custom-success-btn"
                                @click="Addcompany"
                              >
                                Save
                              </button>
                            </div>
                            <div class="col-md-2">
                              <button
                                type="button"
                                class="custom-btn custom-danger-btn"
                                data-bs-dismiss="modal"
                                @click="emptyfu"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- page section ends here -->
  </div>
</template>

<script>
import { addincurance } from "../insurance/addinsurance";
export default addincurance;
</script>
<style scoped>
.loading_main_background {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(42, 42, 43, 0.45);
  z-index: 9999;
  /* display: none; */
}
/* .preloder {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgb(0, 155, 254);
    z-index: 9999;
} */

.preloder img {
  width: auto;
  margin: 12% 38%;
  border-radius: 50%;
  opacity: 0.6;
}
.loading_main_background img {
  width: 5% !important;
  margin: 20% 50% !important;
  opacity: 1 !important;
  position: absolute;
}
img {
  vertical-align: middle;
  border-style: none;
}
</style>
