<template>
  <div>
    <div class="floating-heading">
      <h4><b>Vehicle Commission</b></h4>
    </div>
    <div class="content-body-wrapper mt-4">
      <div class="page-content">
        <div class="row mb-3">
          <div class="col-md-2">
            <label class="frm-label-2"
              >Broker Name <span style="color: red">*</span>
            </label>
            <select
              v-model="USER_SYS_ID"
              class="form-control"
              name="Incharge By"
            >
              <option value="">Select</option>
              <option
                v-for="broker in brokerDetailsArray"
                :value="broker.USER_SYS_ID"
                :key="broker.USER_SYS_ID"
              >
                {{ broker.PERSON_NAME }}
              </option>
            </select>
          </div>
          <div class="col-md-2">
            <label class="frm-label-2">
              Start Date <span style="color: red">*</span></label
            >
            <input
              type="date"
              class="form-control"
              v-model="DELIVERY_OUT_START_DATE"
              placeholder="Enter  Start Date"
            />
          </div>
          <div class="col-md-2">
            <label class="frm-label-2">
              End Date <span style="color: red">*</span></label
            >
            <input
              type="date"
              class="form-control"
              v-model="DELIVERY_OUT_END_DATE"
              placeholder="Enter  end Date"
            />
          </div>

          <div class="col-md-4">
            <div class="">
              <label class="frm-label-2">Vehicle Number</label>
              <b-input-group>
                <b-form-input
                  id="VehicleNo"
                  name="VehicleNo"
                  class="form-control"
                  placeholder="Enter Vehicle No"
                  v-model="VEHICLE_NUMBER"
                  @keyup="onKeyUpVehicleInfo(), vehiclemake()"
                  @change="onChnageVehicleInfo(), getvehiclesysid()"
                  list="datalistOptionsVehicles"
                ></b-form-input>
                <datalist id="datalistOptionsVehicles">
                  <option
                    v-for="(vehicle_details, index) in all_vehicle_details"
                    :key="index"
                    :value="vehicle_details.VEHICLE_NUMBER"
                  ></option>
                </datalist>
              </b-input-group>
            </div>
          </div>
          <div class="col-md-2">
            <button
              style="
                margin-top: 18px;
                font-size: 13px;
                color: #fff;
                background-color: #754115;
              "
              type="button"
              @click="searchvechic"
              class="custom-btn btn btn-success"
            >
              <i class="fa fa-search" aria-hidden="true"></i> Search
            </button>
          </div>
        </div>

        <div class="page-data">
          <div class="data-table admin-master-table mt-4">
            <table class="table table-bordered" v-show="truefalse">
              <thead>
                <tr style="background: #ebd8c3">
                  <th scope="col">SL. No.</th>
                  <th scope="col">Commission Head</th>
                  <th scope="col">Handling</th>
                  <th scope="col">Amount ($)</th>
                  <th scope="col">Vehicle Number</th>
                  <th scope="col">Commission To</th>
                </tr>
              </thead>
              <tbody
                v-for="(user_detail, index) in vehicle_details"
                :key="user_detail.RECORD_SYS_ID"
              >
                <tr>
                  <td>{{ index + 1 }}</td>
                  <td>{{ user_detail.COMMISSION_HEAD }}</td>
                  <td>{{ user_detail.HANDLING }}</td>
                  <td>{{ user_detail.COMMISSION_AMOUNT }}</td>
                  <td>{{ user_detail.VEHICLE_NUMBER }}</td>
                  <td>{{ user_detail.COMMISSION_TO }}</td>
                  <!-- <td class="table-btn">
                    <button
                      class="custom-btn p-0"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne-1"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                      @click="viewcommison"
                    >
                      Details
                    </button>
                  </td> -->
                </tr>
                <tr class="table-under-table">
                  <td colspan="12" class="p-0" style="padding: 0 !important">
                    <div
                      id="flush-collapseOne-1"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body p-2 mt-1">
                        <table class="table table-bordered">
                          <thead>
                            <tr style="background: #d4d4d4">
                              <th>SL. No.</th>
                              <th scope="col">commission head</th>
                              <th scope="col">handling</th>
                              <th scope="col">amount ($)</th>
                              <th scope="col">commission to</th>
                              <th scope="col">timestamp</th>
                            </tr>
                          </thead>
                          <tbody
                            v-for="(user_de, INDEX) in user_deta"
                            :key="user_de.RECORD_SYS_ID"
                          >
                            <tr>
                              <td>{{ INDEX + 1 }}</td>

                              <td>{{ user_de.COMMISSION_HEAD }}</td>
                              <td>{{ user_de.HANDLING }}</td>
                              <td>{{ user_de.COMMISSION_AMOUNT }}</td>
                              <td>{{ user_de.COMMISSION_TO }}</td>
                              <td>{{ user_de.CREATED_DATE }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- </div>
                                    </div>
                                 </div>
                              </div>
                           </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
export default {
  data() {
    return {
      //for data property decalire
      VEHICLE_NUMBER: "",
      truefalse: false,
      trueg: false,
      truea: false,
      trueb: false,
      truec: false,
      create_system: {},
      vehicle_details: {},
      VEHICLE_SYS_ID: "",
      user_details: {},
      create_system_role: {},
      USER_SYS_ID: "",
      user_de: [],
      VEHICLE_ID: "",
      user_deta: {},
      DELIVERY_OUT_END_DATE: "",
      DELIVERY_OUT_START_DATE: "",
      brokerDetailsArray: [],
      all_vehicle_details: [],
    };
  },
  mounted() {
    this.getExternalBrokerDetailsList();
    this.getVehicledetails();
  },
  methods: {
    // searchvechicle() {
    //   this.axios
    //     .get(apiEndPoints.view_vehicle_number, {
    //       params: {
    //         ITEM: "SPECIFIC",

    //         VEHICLE_NUMBER: this.VEHICLE_NUMBER,
    //       },
    //     })
    //     .then((response) => {

    //       if (response.data.status == "true") {

    //         this.vehicle_details = response.data.response[0];
    //       } else if (
    //         response.data.status == false ||
    //         response.data.status == "false"
    //       ) {
    //         this.truefalse = false;
    //         this.vehicle_details = [];
    //         Global.showErrorAlert(true, "error", response.data.response);
    //       }
    //     })
    //     .catch((err) => {
    //       this.truefalse = false;

    //     });
    // },

    // for search the vehicle commison
    searchvechic() {
      this.DELIVERY_OUT_START_DATE = this.formatDate(
        this.DELIVERY_OUT_START_DATE
      );
      this.DELIVERY_OUT_END_DATE = this.formatDate(this.DELIVERY_OUT_END_DATE);
      this.axios
        .get(apiEndPoints.vehicle_commison, {
          params: {
            ITEM: "SPECIFIC",
            VEHICLE_SYS_ID: this.VEHICLE_SYS_ID,
            USER_SYS_ID: this.USER_SYS_ID,
            DELIVERY_OUT_START_DATE: this.DELIVERY_OUT_START_DATE,
            DELIVERY_OUT_END_DATE: this.DELIVERY_OUT_END_DATE,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.truefalse = true;
            this.trueg = true;
            this.truea = true;
            this.trueb = true;
            this.truec = true;
            this.vehicle_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.vehicle_details = [];
          }
        })
        .catch((err) => {});
    },

    // vehicle number againest vehicle-sys_id return

    getvehiclesysid() {
      this.VEHICLE_SYS_ID = this.all_vehicle_details.find(
        (o) => o.VEHICLE_NUMBER === this.VEHICLE_NUMBER
      ).RECORD_SYS_ID;
    },
    // for view the commison

    viewcommison() {
      this.axios
        .get(apiEndPoints.view_commission_summarydetails, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.vehicle_details.VEHICLE_SYS_ID,
          },
        })
        .then((response) => {
          this.user_deta = response.data.response;
        })
        .catch((err) => {});
    },

    //vehicle detailes show in auto key up
    onKeyUpVehicleInfo() {
      if (this.vehicle_number == "") {
        this.particular_vehicle_details = null;
      }
    },
    // on change vehicle data show
    onChnageVehicleInfo() {
      let is_existed = this.all_vehicle_details.find(
        (items) => items.VEHICLE_NUMBER == this.vehicle_number
      );

      if (is_existed) {
        this.particular_vehicle_details = is_existed;
      }
    },
    // for vehicle detailes show
    getVehicledetails() {
      this.axios
        .get(apiEndPoints.view_vehicle_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
            VEHICLE_STATUS: "",
            VEHICLE_NUMBER: "",
            VEHICLE_MAKE: "",
            VEHICLE_MODEL: "",
            PAGE_SIZE: 1000,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.all_vehicle_details = response.data.response;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // for broker list
    getExternalBrokerDetailsList() {
      this.axios
        .get(apiEndPoints.view_user, {
          params: {
            ITEM: "SPECIFIC",
            USER_TYPE: "External",
            PAGE_SIZE: 1000,
            PAGE_NO: 100,
          },
        })
        .then((response) => {
          this.brokerDetailsArray = response.data.response;
        })
        .catch((err) => {});
    },

    // for adding the data
    purchase() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));

      this.create_system_role["ITEM"] = "ADMIN_FEE_PURCHASE";
      this.create_system_role["RECORD_SYS_ID"] =
        this.vehicle_details.VEHICLE_SYS_ID;

      this.axios
        .post(apiEndPoints.update_commison, this.create_system_role)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            $("#master_system_role_modal").modal("hide");

            this.viewcommison();
            this.emptyfu();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })

        .catch((err) => {});
    },
    sal() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));

      this.create_system["ITEM"] = "SELL_COMMISSION";
      this.create_system["RECORD_SYS_ID"] = this.vehicle_details.VEHICLE_SYS_ID;

      this.axios
        .post(apiEndPoints.update_commison, this.create_system)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            $("#master_system_role_modal").modal("hide");

            this.viewcommison();
            this.emptyfu();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })

        .catch((err) => {});
    },

    sales() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));

      this.create_system["ITEM"] = "ADMIN_FEE_SALES";
      this.create_system["RECORD_SYS_ID"] = this.vehicle_details.VEHICLE_SYS_ID;

      this.axios
        .post(apiEndPoints.update_commison, this.create_system)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            $("#master_system_role_modal").modal("hide");

            this.viewcommison();
            this.emptyfu();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {});
    },
  },
};
</script>
