import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
import VueHtml2pdf from "vue-html2pdf";
import DatePicker from "vue2-datepicker";
import NProgress from "nprogress";
import "vue2-datepicker/index.css";
import moment from "moment";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export const view_vehicledetails = {
  components: {
    VueHtml2pdf,
    DatePicker,
    VuePdfEmbed,
  },
  data() {
    return {
      loading: false,
      loading_landing_page: false,
      calculation_plstatement:0,
      create_system: {},
      preview_pdf_url: "",
      create_vendor: {
        ITEM: "ADD",
        CREATED_BY: "",
        RECORD_SYS_ID: "0",
        VENDOR_CODE: "",
        VENDOR_NAME: "",
        ADDRESS_LINE_1: "",
        COUNTRY_SYS_ID: "1",
        COUNTRY_NAME: "Singapore",
        PIN_CODE: "",
      },
      //For Admin Master Table
      admin_edit_modal_show: false,
      admin_view_modal_show: false,
      vehicledetails: [],
      vehicledetails_page_no: 1,
      vehicledetailsfields: [
        { key: "index", label: "Sl.No" },
        { key: "VEHICLE_STATUS", label: "Vehicle Status" },
        { key: "VEHICLE_NUMBER", label: "Vehicle Number" },
        { key: "TEMPORARY_VEHICLE_NUMBER", label: "Temporary Vehicle Number" },
        { key: "ADDITIONAL_VEHICLE_NUMBER", label: "New Vehicle Number" },
        { key: "NAME_AND_MODEL", label: "Name And Model" },
        { key: "BODY_TYPE", label: "Body Type" },
        { key: "PROPELLANT", label: "Propellant" },
        { key: "TOTAL_RECEIVABLE_AMOUNT", label: "Total Receiveable" },
        { key: "Action", label: "Action" },
      ],

      //For Global Variables
      AllVendordetails: [],
      AllCustomerArray: [],

      //For Admin Master Update
      //For Stock Record Section
      addmasterdata: {
        ITEM: "UPDATE",
        RECORD_SYS_ID: "0",
        VEHICLE_STATUS: "",
        VEHICLE_NUMBER: "",
        TEMPORARY_VEHICLE_NUMBER: "",
        ADDITIONAL_VEHICLE_NUMBER: "",
        MAKE_SYS_ID: "",
        MAKE_NAME: "",
        MODEL_SYS_ID: "",
        MODEL_NAME: "",
        LAYUP_AMOUNT: "",
        MODEL_SHORT_NAME: "",
        PRICE_LIST_STATUS: "",
        BODY_TYPE_SYS_ID: "",
        BODY_TYPE: "",
        PRICE_LIST_PLUS: "",
        ACCESSORY: "",
        ASKING_PRICE: "",
        CHASIS_NO: "",
        ENGINE_NO: "",
        PROPELLANT: "",
        COLOR_SYS_ID: "",
        COLOR_NAME: "",
        LADEN: "",
        UNLADEN: "",
        ENGINE_TON: "",
        ENGINE_CAP: "",
        PAX: "",
        MANUFACTURE_YEAR: "",
        ORIGINAL_REGISTRATION_DATE: "",
        TSF_NUMBER: "",
        INCHARGE_BY_BROKER_SYS_ID: "",
        CREATED_BY: "",
        BULK_COUNT: "",

        COE_EXPIRY_DATE: "",
        COE_NUMBER: "",
        COE_TO_PAY: "",
        OPEN_MARKET_VALUE: "",
        PARF_AMOUNT: "",
        REGISTRATION_FEE: "",
        ARF_AMOUNT: "",
        CEVS_SURCHARGE: "",
        CEVS_REBATE: "",
        ETS_PAPER_FROM: "",
        USE_TCOE: "",
        COE_LOG_GUARD: "",
        COE_ACC_DPQP: "",
      },
      Vehiclelocations: [],
      brokerDetailsArray: [],
      Manufracturename: {},
      Modeltype_: {},
      Bodytype: {},
      Coloursname: {},
      etsfromdisable: false,

      //For Seller Details Section
      addupdatesellerdata: {
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        //Seller Upper Section Field Start Here
        CUSTOMER_TYPE: "",
        PURCHASE_DATE: "",
        NO_DELIVERY_DATE: "",
        AGREEMENT_NUMBER: "",
        ESTIMATE_DELIVERY_IN_DATE: "",
        ADMIN_FEE: "",
        DELIVERY_IN_DATE: "",
        DELIVERY_IN_TIME: "",
        EXCLUDE_COE: "",
        EXCLUDE_REGISTRATION_FEE_AND_ARF: "",
        EXCLUDE_ROAD_TAX: "",
        PURCHASE_PRICE_IS_ZERO: "",
        PURCHASE_PRICE_SD: "",
        PURCHASE_PRICE: "",
        PURCHASE_OUTSIDE_COMMISSION: "",
        TOTAL_PURCHASE_PRICE: "",
        TAX_SYS_ID: "",
        GST_AMOUNT_BEFORE_ADJUSTMENT: "",
        GST_DECIMAL_ADJUSTMENT: "",
        GST_AMOUNT: "",
        NOTE: "",
        GST: "",
        //Seller Upper Section Field End Here
        //Seller Below Section Field Start Here
        ASKING_PRICE: "", //need to store
        ALTERNATE_PRICE_SD: "",
        BUY_CODE: "",
        BROKER_DEAL: "",
        TRADE_IN_BY: "",
        BROKER_1: "",
        BROKER_2: "",
        TRANSACTION_TYPE: "",
        COM_RTN_1: "",
        E_TRANSFER_BY: "",
        E_TRANSFER_IN_DATE: "",
        LOG_CARD_TRANSFER_IN: "",
        LOG_CARD_TRANSFER_IN_2 : "",
        RETURN_DATE_2: "",
        //Seller Upper Section Field End Here

        ID_NUMBER: "",
        sellertype: "",
        SUB_CUSTOMER_TYPE: "",
        vendorcode: "",
        AMOUNT: "0",
        PIN_CODE: "",
        AP_PAYMENT: "0",
        SALES_PERSON_SYS_ID: "58",
        ID_NUMBER: "",
        SETTLEMENT_NUMBER: "",
        DN_CN_NUMBER: "",
        DN_CN_AMOUNT: "",
        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",
        PIN_CODE: "",
        COUNTRY_NAME: "",
        ALTERNATE: "",
        COM_RTN_2: 0,
        CUSTOMER_SYS_ID: "",
        ID_NUMBER: "",
      },
      isDisabledLogCardIn: false,
      isDisabledLogCardIn2: false,
      SELLER_LOG_CARD_TRANSFER_IN: "",
      SELLER_LOG_CARD_TRANSFER_IN_2: "",
      SELLER_CUSTOMER_INFO: "",
      DisplaySellerCustomerType: "",
      DisplaySellerCustomerIdNumber: "",
      DisplaySellerCustomerName: "",
      DisplaySellerCustomerAddress: "",
      AllSellerCustomerDetailsArray: [],
      DisplaySellerVendorName: "",
      DisplaySellerVendorCode: "",
      DisplaySellerVendorAddress: "",
      SELLER_VENDOR_INFO: "",
      AllSellerVendorDetailsArray: [],
      isDisabled: false,
      isDisablePriceAmount: false,
      GLOBAL_PURCHASE_PRICE_SD: "",
      GLOBAL_SELLER_CUSTOMER_TYPE: "",
      GLOBAL_ASKING_PRICE_SD: "",
      SELLER_AP_REDUCED_RECEIVABLE_AMOUNT: "",
      SELLER_AP_PURCHASE_AMOUNT: "",
      SELLER_AP_REDUCED_PURCHASE_AMOUNT: "",
      addupdatesellerapdata: {
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        MODULE: "SELLER",
        PAYMENT_DESC: "",
        AMOUNT: "",
        PAYMENT_MODE: "",
        BANK_NAME: "",
        BANK_SYS_ID: "",
        CHEQUE_DATE: "",
        CHEQUE_NO: "",
        TO_FROM_WHOM: "",
        SETTLEMENT_REMARKS: "0",
        REMARKS: "",
        CREATED_BY: "",
        REDUCED_RECEIVABLE_AMOUNT: "",
        PAYMENT_DESC_OTHER: "",
        //PURCHASE_SETTLEMENT_NO:"",
      },
      Banklist: {},
      finance_company_bank_details: [],
      FinanceCompanyDetails: {},
      sellerapdetails: [],
      SellerAPFields: [
        { key: "index", label: "Sl.No." },
        { key: "PAYMENT_DESC", label: "AP Payment" },
        { key: "AMOUNT", label: "Amount($)" },
        { key: "BALANCE", label: "Balance($)" },
        { key: "PAYMENT_MODE", label: "Payment Mode" },
        { key: "CHEQUE_DATE", label: "Payment Date" },
        { key: "BANK_NAME", label: "Bank" },
        { key: "CHEQUE_NO", label: "Cheque No." },
        { key: "TO_FROM_WHOM", label: "To" },
        { key: "REMARKS", label: "Remarks" },
        { key: "Action", label: "Action" },
      ],
      PurchaseNoteDetails: {},
      InternalUserList: [],
      brokerdetails: {},
      purchasesettlement: {
        PURCHASE_SETTLEMENT_INVOICE_NO: "",
        PURCHASE_SETTLEMENT_INVOICE_DATE: "",
      },

      //For Buyer Details Section
      addupdatebuyerdata: {
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        ID_NUMBER: 0,
        CUSTOMER_TYPE: "",
        SUB_CUSTOMER_TYPE: "",
        SALES_OUTSIDE_COMMISSION: "",
        DELIVERY_OUT_DATE: "",
        TAXABLE_SUPPLIES: "",
        NON_TAXABLE_SUPPLIES: "",
        TIME: "",
        SELL_CODE: "",
        SALES_PERSON: "",
        SELL_PRICE: "",
        INVOICE_NUMBER: "",
        INVOICE_DATE: "",
        P_L_DATE: "",
        BOOKING_DATE: "",
        SELL_CODE: "",
        SALES_AGREEMENT_NUMBER: "",
        SALE_AGGREEMENT_PRICE: 0,
        SELLING_PRICE: "",
        BROKER_1: "",
        GST_TYPE: "",
        GST: "",
        GST_AMOUNT: "",
        GST_DECIMAL_ADJUSTMENT: "",
        GST_AMOUNT_BEFORE_ADJUSTMENT: "",
        TOTAL_SELLING_PRICE: "",
        CREATED_BY: "",
        TOTAL_RECEIVABLE: "",
        TRANSACTION_TYPE: "",
        TOTAL_RECEIVED: "",
        E_TRANSFER_OUT_DATE: "",
        AR_BALANCE: "",
        TRANSFER_OUT_BY: "",
        REMARKS: "",
        LOG_CARD_TRANSFER_OUT: "",
        LOG_CARD_TRANSFER_OUT_2: "",
        STATUS: "",
        SELLER_SYS_ID: "1",
        TAX_SYS_ID: "",
        PURCHASE_OUTSIDE_COMMISSION: "",
        BROKER_1_SYS_ID: "",
        BROKER_2_SYS_ID: "",
        SALES_PERSON_SYS_ID: 0,
        DEPOSIT_PAY_AMOUNT: "",
        DEPOSIT_TAX_AMOUNT_SYS_ID: "",
        DEPOSIT_TAX_AMOUNT: "",
        DEPOSIT_AMOUNT_WITHOUT_TAX: "",
        DEPOSIT_AMOUNT_WITH_TAX: "",
      },
      isDisabledLogCardOut: false,
      isDisabledLogCardOut2: false,
      BUYER_LOG_CARD_TRANSFER_OUT: "",
      BUYER_LOG_CARD_TRANSFER_OUT_2: "",
      BUYER_CUSTOMER_INFO: "",
      DisplayBuyerCustomerType: "",
      DisplayBuyerCustomerIdNumber: "",
      DisplayBuyerCustomerName: "",
      DisplayBuyerCustomerAddress: "",
      AllBuyerCustomerDetailsArray: [],
      DisplayBuyerVendorName: "",
      DisplayBuyerVendorCode: "",
      DisplayBuyerVendorAddress: "",
      BUYER_VENDOR_INFO: "",
      AllBuyerVendorDetailsArray: [],
      VEHICLE_TOTAL_RECEIVABLE_AMOUNT: "",
      VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT: "",
      addupdatebuyerapdata: {
        ITEM: "ADD",
        RECORD_SYS_ID: 4,
        MODULE: "BUYER",
        PAYMENT_DESC: "",
        AMOUNT: "",
        BALANCE: 0,
        ID_NUMBER1: "",
        ID_NUMBER: "",
        VENDORCODE: "",
        CUSTOMERTYPE: "",
        PAYMENT_MODE: "",
        BANK_NAME: "",
        BANK_SYS_ID: "",
        ADMIN_FEE: "",
        CHEQUE_DATE: "",
        CHEQUE_NO: "",
        TO_FROM_WHOM: "",
        SETTLEMENT_REMARKS: "",
        REMARKS: "",
        CREATED_BY: "",
        PAYMENT_DESC_OTHER: "",
      },
      buyerapdetails: [],
      BuyerARFields: [
        { key: "index", label: "Sl.No." },
        { key: "PAYMENT_DESC", label: "AR Payment" },
        { key: "AMOUNT", label: "Amount($)" },
        { key: "BALANCE", label: "Balance($)" },
        { key: "PAYMENT_MODE", label: "Payment Mode" },
        { key: "CHEQUE_DATE", label: "Payment Date" },
        { key: "BANK_NAME", label: "Bank" },
        { key: "TO_FROM_WHOM", label: "To" },
        { key: "REMARKS", label: "Remarks" },
        { key: "Action", label: "Action" },
      ],

      //For ETS Transaction Details Section
      addupdateetsdata: {
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        VEHICLE_DETAILS_SYS_ID: "",
        BUY_OVER_DATE: "",
        FIGHT_PL: "",
        ETS_PAPER_TO: "",
        BODY_PRICE: "",
        DEREG_DATE: "",
        COE_ENCASHMENT: "",
        PARF_ENCASHMENT: "",
        ADMIN_FEE: "",
        ETS_TRANSFER_VALUE: "",
        ETS_PAPER_EXTERNAL: "",
        INVOICE_NUMBER_2: "",
        INVOICE_DATE_2: "",
        ETS_PAPER_BUYER: "",
        CREATED_BY: "",
        TO_FROM_WHO: "",
        CUSTOMER_SYS_ID: "",
      },
      ETS_CUSTOMER_INFO: "",
      DisplayEtsCustomerType: "",
      DisplayEtsCustomerIdNumber: "",
      DisplayEtsCustomerName: "",
      DisplayEtsCustomerAddress: "",
      AllEtsCustomerDetailsArray: [],
      DisplayEtsVendorName: "",
      DisplayEtsVendorCode: "",
      DisplayEtsVendorAddress: "",
      ETS_VENDOR_INFO: "",
      AllEtsVendorDetailsArray: [],

      //For Sales Receipt Section
      //For Insurance Info Tab
      insurance_company_details: [],
      InsuranceInfoDetails: {
        ITEM: "INSURANCE_INFO",
        RECORD_SYS_ID: 0,
        CREATED_BY: "",
        PREMIUM_AMOUNT: "",
        VEHICLE_SYS_ID: "",
        INSURANCE_COMPANY_SYS_ID: "",
        INSURANCE_COMPANY:""
      },
      payinvoice:{
        PAYMENT_CODE:"",
        PAYMENT_DATE:"",
        BANK_SYS_ID:"",
        PAYMENT_TO:"",
      },
      BANK_SYS_ID:"",
      PAYMENT_TO:"",

      //For Trade Info Tab
      trade_info: {
        RECORD_SYS_ID: 0,
        VEHICLE_SYS_ID: "",
        TRADE_IN_VEH_NO: "",
        TRADE_IN_AMOUNT: "",
        PAYMENT_MADE: "",
        FULL_SETTLEMENT_REIMBURSEMENT: "",
        CREATED_BY: null,
      },
      multiTradeInfo: [],
      search_all_vehicle_details: [],

      //For Other Info Tab
      OTHER_PAYMENT_DETAILS: [],
      OTHER_PAYMENT_DETAILS_FIELDS: [
        { key: "index", label: "Sl.No." },
        { key: "DESCRIPTION", label: "Item Description" },
        { key: "TOTAL_PRICE", label: "Gross Amt. ($)" },
        { key: "Action", label: "Action" },
      ],
      addotherpayment: {
        DESCRIPTION: "",
        TAX_VALUE: "",
        TAX_SYS_ID: "",
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        TOTAL_PRICE: "",
        UNIT_PRICE: "",
        VEHICLE_SYS_ID: "",
        CODE: "",
        DESCRIPTION_OTHER: "",
      },
      updateotherpayment: {},

      //For Sales Receipt Tab
      SalesReceiptNoteDetails: [],
      truesales: false,
      calculation: "",
      salesinvoicedata: {
        SALES_RECEIPT_INVOICE_NO: "",
        SALES_RECEIPT_INVOICE_DATE: "",
      },

      //For Loan Info Section
      //For Loan Info Tab
      GLOBAL_LOAN_INFO_FIN_COMP_SYS_ID: "",
      loan_info: {
        RECORD_SYS_ID: "",
        LOAN_TAX_AMOUNT: "",
        LOAN_TAX_DECIMAL_ADJUSTMENT: "",
        LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT: "",
        LOAN_TAX_SYS_ID: "",
        LOAN_AMOUNT: "",
        LOAN_AMOUNT_WITHOUT_TAX: "",
        LOAN_TENURE: "",
        INTEREST_RATE: "",
        FINANCE_COMPANY: "",
        FIN_COMP_SYS_ID: "",
        ADVANCE_PAYMENT_DECIMAL_ADJUSTMENT: "",
        ADVANCE_PAYMENT_BEFORE_ADJUSTMENT: "",
        ADVANCE_PAYMENT: "",
        SUBTOTAL: "",
        LOAN_COMMISSION_PERCENTAGE: "",
        LOAN_COMMISSION: "",
        SALES_COMMISSION: "",
        INVOICE_NO: "",
        BANK_SYS_ID: "",
      },

      //For Finance Company Tax Invoice Tab
      financetaxinvoicedata: {
        FINANCE_TAX_INVOICE_NO: "",
        FINANCE_TAX_INVOICE_DATE: "",
      },
      finance_company_tax_invoice: {},
      finance_company_invoice: {},
      finance_company_invoice_form: {
        FINANCE_COMPANY_INVOICE_NO: "",
        FINANCE_COMPANY_INVOICE_DATE: "",
      },

      //For Official Receipt Tab
      OFFICIAL_RECEIPT_PDF_OBJ: {},
      OFFICIAL_RECEIPT_CUSTOMER: "",
      OFFICIAL_RECEIPT_DETAILS_LIST: [],
      officalreceipt: {
        OFFICIAL_RECEIPT_INVOICE_NO: "",
        OFFICIAL_RECEIPT_INVOICE_DATE: "",
      },

      //For Tax Commision Invoice Tab
      LOAN_COMMISSION_DETAILS: [],
      taxcommisoninvoicedata: {
        TAX_COMMISSION_INVOICE_NO: "",
        TAX_COMMISSION_INVOICE_DATE: "",
      },

      //For Tax Invoice Section
      //For Manage Tax Invoice Tab
      item_description_details_for_MTI: {},
      addmanagetaxinvoice: {
        DESCRIPTION: "",
        QTY: "",
        TAX_VALUE: "",
        TAX_SYS_ID: "",
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        TOTAL_PRICE: "",
        UNIT_PRICE: "",
        VEHICLE_SYS_ID: "",
        ITEM_LIST_SYS_ID: "",
      },
      ManageTaxInvoiceDetails: [],
      ManageTaxInvoiceDetailsFields: [
        { key: "index", label: "Sl.No." },
        { key: "DESCRIPTION", label: "Item Description" },
        { key: "TOTAL_AMOUNT", label: "Gross Amt. ($)" },
        { key: "Action", label: "Action" },
      ],

      //For Tax Invoice Tab
      InvoiceDetailsWithTax: {},
      truettax: false,
      taxinvoicedata: {
        TAX_INVOICE_NO: "",
        TAX_INVOICE_DATE: "",
      },

      //For Invoice Tab
      InvoiceDetailsWithoutTax: {},
      invoice_obj: {
        TAX_INVOICE_NO: "",
        TAX_INVOICE_DATE: "",
      },
      invoice_obj_new: {
      
      },
      tax_obj_new:{

      },
      tax_invoice_obj_new:{

      },


      //For Credit Note Section
      //For Manage Credit Note Tab
      TaxCreditIssuedType: "",
      sellertype: "",
      TAX_CREDIT_CUSTOMER_INFO: "",
      DisplayTaxCreditCustomerType: "",
      DisplayTaxCreditCustomerIdNumber: "",
      DisplayTaxCreditCustomerName: "",
      CUS_SYS_ID: "",
      DisplayTaxCreditCustomerAddress: "",
      TAX_CREDIT_VENDOR_INFO: "",
      DisplayTaxCreditVendorCode: "",
      DisplayTaxCreditVendorName: "",
      DisplayTaxCreditVendorAddress: "",
      MCN_CUSTOMER_SYS_ID: "",
      ManageCreditNoteDetailsArray: [],
      CREDIT_NOTE_DETAILS_FIELDS: [
        { key: "index", label: "Sl. No." },
        { key: "DESCRIPTION", label: "Description" },
        { key: "UNIT_PRICE", label: "Unit Price" },
        { key: "QTY", label: "Quantity" },
        { key: "TAX_AMOUNT", label: "Tax Amount(S$)" },
        { key: "AMOUNT", label: "Total Amount(S$)" },
        { key: "Action", label: "Action" },
      ],
      ManageCreditNoteObj: {
        ITEM: "CREDIT_NOTE",
        RECORD_SYS_ID: "",
        PAYMENT_TERMS: "",
        QTY: "",
        TAX_AMOUNT: "",
        DESCRIPTION: "",
        UNIT_TOTAL: "",
        UNIT_PRICE: "",
        AMOUNT: "",
        TAX_SYS_ID: "",
        CREDIT_NOTE_RECORD_SYS_ID: "0",
      },
      addcreditnote: {
        ITEM: "CREDIT_NOTE",
        RECORD_SYS_ID: "",
        PAYMENT_TERMS: "",
        QTY: "",
        TAX_AMOUNT: "",
        DESCRIPTION: "",
        UNIT_TOTAL: "",
        UNIT_PRICE: "",
        AMOUNT: "",
        CREDIT_NOTE_RECORD_SYS_ID: "0",
      },

      //For Credit Note Tab
      creditnote_doc_obj: {
        CREDIT_NOTE_INVOICE_DATE: "",
        CREDIT_NOTE_INVOICE_NO: "",
      },

      //For Cost Center Section
      //Cost Center Customer Start
      COST_CENTER_CUSTOMER_INFO: "",
      DisplayCostCenterCustomerType: "",
      DisplayCostCenterCustomerIdNumber: "",
      DisplayCostCenterCustomerName: "",
      DisplayCostCenterCustomerAddress: "",
      AllCostCenterCustomerDetailsArray: [],
      //Cost Center Customer End
      //Cost Center Vendor Start
      DisplayCostCenterVendorName: "",
      DisplayCostCenterVendorCode: "",
      DisplayCostCenterVendorAddress: "",
      COST_CENTER_VENDOR_INFO: "",
      AllCostCenterVendorDetailsArray: [],
      //Cost Center Vendor End
      CostTypeDetailsList: [],
      CostCreatedSalesList: [],
      CostCreatedSalesListFields: [
        { key: "index", label: "Sl. No." },
        { key: "PAYMENT_DESC", label: "Sales" },
        { key: "AMOUNT", label: "Amount($)" },
        { key: "GST_AMOUNT", label: "GST Amt($)" },
      ],
      CostCreatedPurchaseList: [],
      CostCreatedPurchaseListFields: [
        { key: "index", label: "Sl. No." },
        { key: "PAYMENT_DESC", label: "Purchase" },
        { key: "AMOUNT", label: "Amount($)" },
        { key: "GST_AMOUNT", label: "GST Amt($)" },
      ],
      CostCenterDetailsList: [],
      VehicleCostCreatedDetailsList: [],
      CostCenterDetailsListFields: [
        { key: "index", label: "Sl. No." },
        { key: "INVOICE_NO", label: "Invoice No" },
        { key: "COST_TYPE", label: "Cost Type" },
        { key: "CUSTOMER_NAME", label: "To/From" },
        { key: "TOTAL_AMOUNT_BEF_GST", label: "Total Amt. Without GST (S$)" },
        { key: "GST_AMOUNT", label: "GST Amt.(S$)" },
        { key: "TOTAL_AMOUNT", label: "Total Amt. With GST (S$)" },
      ],
      ViewCostCenterFields: [
        { key: "PV_NO", label: "PV No" },
        { key: "CUSTOMER_NAME", label: "To/From" },
        { key: "PAYMENT_MODE", label: "Payment Mode" },
        { key: "TOTAL_AMOUNT_BEF_GST", label: "Total Amt. Without GST (S$)" },
        { key: "GST_AMOUNT", label: "GST Amt.(S$)" },
        { key: "TOTAL_AMOUNT", label: "Total Amt. With GST (S$)" },
        { key: "Action", label: "Action" },
      ],
      CostCenterDetailsDescriptionFields:[
        { key: "index", label: "Sl. No." },
        { key: "DETAIL_DESCRIPTION", label: "Detail Description" },
        { key: "AMOUNT", label: "Amount(S$)" },
      ],
      CostCenterDetailsDescriptionItems :[],
      VehicleCostCenterForm: {
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        CREATED_BY: "",
        VEHICLE_NUMBER: "",
        VEHICLE_SYS_ID: "",
        COST_TYPE: "",
        DETAIL_DESCRIPTION: "",
        PV_DESCRIPTION: "",
        TO_FROM: "",
        PAYMENT_MODE: "",
        AMOUNT_BEF_GST: "",
        TAX_SYS_ID: "",
        GST_AMOUNT: "",
        TOTAL_AMOUNT: "",
        CUSTOMER_SYS_ID: "",
        CUSTOMER_NAME: "",
        INVOICE_NO: "",
        PV_NO: "",
        REQUIRE_PAYMENT_VOUCHER: "",
        GST_PERCENTAGE: "",
      },
      CostTypeDetails: [],
      AdditionalCostType:[
        {
          DETAIL_DESCRIPTION: "",
          AMOUNT_BEF_GST: "",
          COST_TYPE: "",
        }
      ],
      GlobalCostAmount : 0,
      AdditionalCostTypeTemplate:{
        DETAIL_DESCRIPTION: "",
        AMOUNT_BEF_GST: "",
        COST_TYPE: "",
      },

      GLOBAL_BUYER_CUSTOMER_ID: "",
      GLOBAL_TAX_TYPE: "",
      floorstock: {
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        VEHICLE_SYS_ID: "",
        FLOOR_STOCK_INTEREST: "",
        E_TRANSFER_IN_DATE: "",
        E_TRANSFER_OUT_DATE: "",
        NO_OF_DAYS: "",
        FLOOR_STOCK_AMOUNT: "",
        CREATED_BY: "",
        PURCHASE_PRICE: "",
        FLOOR_STOCK_INTEREST_SYS_ID: "",
      },
      getfloorstock: {},
      GLOBAL_RECORD_SYS_ID: "",
      globalpaymentdesc: "",
      newgst: "",
      customeramount: "",
      calculategstvalue: "",
      perseamount: "",
      totalgst: "",
      data: "",
      value: "",
      financecompanyamount: "",
      truez: false,
      AR_REDUCED_RECEIVABLE_AMOUNT: "",
      updat_user: {},
      create_company: {
        ITEM: "ADD",
        CUSTOMER_TYPE: "",
        COMPANY_NAME: "",
        PERSON_INCHARGE: "",
        CUSTOMER_CONTACT: "",
        CUSTOMER_ADDRESS_SYS_ID: "1",
        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",
        PIN_CODE: "",
        COUNTRY_NAME: "Singapore",
        COUNTRY_SYS_ID: "1",
        RECORD_SYS_ID: "0",
        OFFICE_NUMBER: "",
        CREATED_BY: "",
        ID_NUMBER: "",
        GMAIL_ID: "",
        REDUCED_RECEIVABLE_AMOUNT: "",
      },
      insurance_company: {},
      update_user: {},
      fcti: false,
      create_customer: {
        ITEM: "ADD",
        CREATED_BY: "",
        RECORD_SYS_ID: "0",
        CUSTOMER_TYPE: "",
        CUSTOMER_NAME: "",
        CUSTOMER_CONTACT: "",
        CUSTOMER_ADDRESS_SYS_ID: "1",
        ADDRESS_LINE_1: "",
        PIN_CODE: "",
        COUNTRY_NAME: "Singapore",
        COUNTRY_SYS_ID: "1",
        ID_NUMBER: "",
        GMAIL_ID: "",
      },
      create_user: {
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        CREATED_BY: "",
        VEHICLE_NUMBER: "",
        VEHICLE_SYS_ID: "",
        COST_TYPE: "",
        DESCRIPTION: "",
        TO_FROM: "",
        PAYMENT_MODE: "",
        AMOUNT_BEF_GST: "",
        DETAIL: "",
        LATE_RENEWAL_FEE: "",
        TAX_SYS_ID: "",
        GST_AMOUNT: "",
        TOTAL_AMOUNT: "",
      },
      calculiy: "",
      truecredit: false,
      update_user: {},
      vendorname: false,
      vendoraddress: false,
      customername: false,
      customeraddress: false,
      totalvalue: "",
      pdf_det: {},
      totalamount: "",
      calculateamount: "",
      updatetaxinvoice: {},
      officalrecipt: false,
      DynamicproductFlagArr: [],
      calculationbar: "",
      officalrecipt: {},
      PAYMENT_TERMS: "",
      recipt: "",
      balancedue: "",
      insurance_info: {},
      CREDIT_NOTE_DETAILS: [],
      duefromcustomer: [],
      statusvalue: null,
      numbervalue: null,
      makevalue: null,
      modelvalue: null,
      FIN_COMPANY_SYS_ID: {},
      user_details: [],
      system_role_detai: {},
      pdfPaymentVoucherDetails: {},

      Modeltype: {},
      MODELNAME: "",
      MAKENAME: "",
      STATUS: "",
      VEHICLENUMBER: "",
      vendorcode: "",
      vendordetails: {},
      ID_NUMBER: "",
      customerdetails: {},
      sellertype: "",
      customertype: "",
      //for buyer type
      vendorcode_: "",
      vendordetails_: {},
      ID_NUMBER_: "",
      customerdetails_: {},
      sellertype_: "",
      customertype_: "",
      //end
      vehicletype: "",
      disabled: true,
      update_user: {},
      tax_info_detai: {},
      cost_type_info: {},
      user_detail: {},
      tax_info_details: {},
      upda_user: {},
      brokerdetails: "",
      pdf_detail: {},
      perseamount: "",
      VEHICLE_NO: "",
      canShowCreditNote: true,
      canShowSalesNote: true,
      canShowPurchaseNote: true,
      canShowTaxNote: true,
      show_add_loan_info: false,
      search_option: "",
      current_tax_details: null,
      activeSalesTabIndex: 0,
      activeLoanTabIndex: 0,
      activeCreditTabIndex: 0,
      activeTaxTabIndex: 0,
      tabList: {
        "loan-info": {
          "loan-tab-info": 0,
          "finance-company-tax-invoice": 1,
          "finance-company-invoice": 2,
          "official-receipt": 3,
          "loan-commission-invoice": 4,
        },
        "credit-note": {
          "manage-credit-note": 0,
        },
        "tax-invoice": {
          "manage-tax-invoice": 0,
        },
        "purchase-settlement": 1,
        "sales-receipt": {
          "insurance-info": 0,
          "trade-info": 1,
          "other-payment": 2,
          "sales-receipt-tab": 3,
        },
      },
      current_vehicle_detail: {},
      loading: false,
    };
  },

  mounted() {
    $(document).ready(function () {
      $("#dark-mode").click(function () {
        $("body").addClass("darkmodeactivated");
        $("#dark-mode").css("display", "none");
        $("#light-mode").css("display", "block");
        $(".light-mood-logo").css("display", "none");
        $(".dark-mood-logo").css("display", "block");
      });

      $("#light-mode").click(function () {
        $("body").removeClass("darkmodeactivated");
        $("#dark-mode").css("display", "block");
        $("#light-mode").css("display", "none");
        $(".light-mood-logo").css("display", "block");
        $(".dark-mood-logo").css("display", "none");
      });

      $("#collapse_btn").click(function () {
        $(".left-card-wrap").toggleClass("shrink");
        $(".right-card-wrap").toggleClass("expand");
        $(".ver-tab-txt").toggleClass("ver-tab-txt-d-none");
        $(".fa-angle-left").toggleClass("rotate");
      });

      $("#srch_btn").click(function () {
        $(".fixed-search-panel").toggleClass("active-search-panel");
        $("#left_modal_sm").modal("show");
      });

      $(".add_btn").click(function () {
        $("#right_modal_xl").modal("show");
      });

      $("#srch-btn").click(function () {
        $(".fixed-search-panel").removeClass("active-search-panel");
        $(".nav-transparent-bg").removeClass("nav-transparent-bg-active");
        $(".fixed-search-panel").removeClass("animate__animated");
        $("#srch_btn").css("display", "block");
        $(".show-hide-panel").addClass("panel-active");
        $("#left_modal_sm").modal("hide");
      });

      $(".show-single-panel-btn").click(function () {
        $(".tab-panel").slideToggle("slow");
        $(".show-single-panel-btn").toggleClass("rotate");
      });
    });

    $(document).ready(function () {
      $(".box").hide();
      $("#customertype")
        .change(function () {
          $(this)
            .find("option:selected")
            .each(function () {
              var optionValue = $(this).attr("value");
              if (optionValue) {
                $(".box")
                  .not("." + optionValue)
                  .hide();
                $("." + optionValue).show();
              } else {
                $(".box").hide();
              }
            });
        })
        .change();
    });
    $(".box2").hide();
    $("#customertypeupdate")
      .change(function () {
        $(this)
          .find("option:selected")
          .each(function () {
            var optionValue = $(this).attr("value");
            if (optionValue) {
              $(".box2")
                .not("." + optionValue)
                .hide();
              $("." + optionValue).show();
            } else {
              $(".box2").hide();
            }
          });
      })
      .change();

    this.getvehicledetails();

    //For stock record
    this.getManufracturename();
    this.getbodytype();
    this.getcolour();
    this.getBanklist();
    this.getFinanceCompanyDetails();
    this.getInternalUserList();
    this.getExternalBrokerDetailsList();
    this.getTaxInf();
    this.GetAllCustomerInfo();
    this.GetAllVendorDetails();
    this.getInsuranceCompanyName();
    this.getItemDescriptionListForManageTaxInvoice();
    this.getCostTypeInfo();
    this.getFinanceComapnyBankDetails();
    this.getTaxIn();
    this.getvehiclelocation();
    this.getBrokerArrayDetails();
     this.getOtherPayment();
    // this. getFloorStockInterestDetails()
    //  this.getInvoiceDetailsWithTax()
    this.getSearchAllVehicleDetailsForTradeInfo();
  },

  watch: {
    vehicledetails: {
      handler: function (newValue, oldValue) {
        const vehicle_id = this.$route.query.VEHICLE_SYS_ID;
        if (vehicle_id) {
          this.$bvModal.show("admin_edit_modal");
          let vehicle = newValue.find(
            (item) => item.RECORD_SYS_ID == vehicle_id
          );
          if (vehicle) {
            this.clickVehicleDetailsRow(vehicle);
          }
        }
      },
    },
  },

  computed: {
    selectedDate() {
      if (this.addmasterdata.ORIGINAL_REGISTRATION_DATE) {
        return this.addmasterdata.ORIGINAL_REGISTRATION_DATE;
      }
      return "";
    },

    system_role_groups() {
      const gr = [];
      this.CostTypeDetails.forEach((g) => {
        if (!gr.includes(g.BODY_TYPE)) gr.push(g.BODY_TYPE);
      });
      return gr;
    },

    menuPermissions() {
      let menu = JSON.parse(localStorage.getItem("activeMenu"));
      return menu.ACCESS;
    },
  },

  methods: {
    scrollTo(scrollToMe, menu = "") {
      if (menu !== "" && this.tabList.hasOwnProperty(menu)) {
        if (menu == "sales-receipt") {
          this.getOtherPayment();
          this.activeSalesTabIndex = this.tabList[menu][scrollToMe];
        } else if (menu == "tax-invoice") {
          this.getInvoiceDetailsWithTax();
          this.activeTaxTabIndex = this.tabList[menu][scrollToMe];
        } else if (menu == "credit-note") {
          this.getCreditNoteDetails();
          this.activeCreditTabIndex = this.tabList[menu][scrollToMe];
        } else if (menu == "loan-info") {
          // this.Financecompanytaxinvoice();
          // this.generateReporttaxinvoicefinancial();
          this.getInvoiceDetailsWithTax();
          this.getManageTaxInvoiceDetails();
          //this.getOfficeNoteDetail();
          this.activeLoanTabIndex = this.tabList[menu][scrollToMe];
        }
        this.$forceUpdate();
        setTimeout(() => {
          const el = this.$refs[menu];
          if (el) {
            el.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      } else {
        const el = this.$refs[scrollToMe];
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
    },

    onLogIn() {
      this.$router.replace("/adminmasteradd-data");
    },

    //Function To Get PL Statement Details
    //Author :
    getPlStatmentDetails() {
      let VEHICLE_NUMBER = this.LOCAL_VEHICLE_NUMBER;
      let RECORD_SYS_ID = this.GLOBAL_RECORD_SYS_ID;
      if (!VEHICLE_NUMBER) {
        Global.showErrorAlert(true, "error", "Vehicle Number Not Found.");
        return false;
      }
      this.axios
        .get(apiEndPoints.pl_statment, {
          params: {
            ITEM: "SPECIFIC",
            VEHICLE_NO: "null",
            RECORD_SYS_ID: RECORD_SYS_ID,
          },
        })
        .then((response) => {
          this.CostTypeDetails = response.data.response;
          if (response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
          } else {
            this.system_role_detals = response.data.response[0];
          }
          //this.exportExcel();
          this.getPLpdf();
        })
        .catch((err) => {
          ////console.log(err);
        });
    },
    
    //Function To Generate Pdf For PL Statement
    getPLpdf(){
      this.$bvModal.show("pl_modal");
      this.$refs.PLStatementHtmlPDF.generatePdf();
    },

    //Function To Generate Excel For PL Statement
    //Author :
    exportExcel() {
      const t = ["Make", "Color", "Model", "Vehicle-number"];
      const filterVal = [
        "MAKE_NAME",
        "COLOR_NAME",
        "MODEL_NAME",
        "VEHICLE_NUMBER",
      ];
      let system_role_groups = [];
      this.system_role_groups.forEach((role) => {
        this.getGroupDetails(role).forEach((item, index) => {
          if (index > 0) {
            item.BODY_TYPE = "";
          }
          system_role_groups.push(item);
        });
      });
      const data = this.formatJson(filterVal, system_role_groups);
      this.generateXlsx(t, data, "pl_statement");
    },
    getGroupDetails(group) {
      return this.CostTypeDetails.filter((role) => role.BODY_TYPE == group);
    },

    //Function To Calculate Commission
    //Author : Prasanta Saren
    calculateCommision() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      this.create_system["ITEM"] = "SELL_COMMISSION";
      this.create_system["RECORD_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;
      this.axios
        .post(apiEndPoints.update_commison, this.create_system)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {})
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Preview Log Card PDF File
    //Author : Prasanta Saren
    previewLogCardPdfFile(item_name) {
      let file_name = "";
      this.preview_pdf_url = "";
      if (item_name == "SELLER_LOG_CARD_TRANSFER_IN")
        file_name = this.addupdatesellerdata.LOG_CARD_TRANSFER_IN;
      else if (item_name == "SELLER_LOG_CARD_TRANSFER_IN_2") {
          file_name = this.addupdatesellerdata.LOG_CARD_TRANSFER_IN_2;
          item_name = "SELLER_LOG_CARD_TRANSFER_IN";
      }
      else if (item_name == "BUYER_LOG_CARD_TRANSFER_OUT") {
        file_name = this.addupdatebuyerdata.LOG_CARD_TRANSFER_OUT;
      }
      else if (item_name == "BUYER_LOG_CARD_TRANSFER_OUT_2") {
        file_name = this.addupdatebuyerdata.LOG_CARD_TRANSFER_OUT_2;
        item_name = "BUYER_LOG_CARD_TRANSFER_OUT";
      }
      this.axios({
        url: apiEndPoints.file_download,
        method: "GET",
        responseType: "blob",
        params: {
          ITEM: item_name,
          FILE_NAME: file_name,
        },
      }).then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        this.preview_pdf_url = objectUrl;
      });
    },

    //Function To Show Update Modal
    //Author :
    UpdateModalShow(row, id) {
      console.log(row, id)
      this.updat_user = [];
      this.updat_user = row;
      this.payinvoice=row
      if (id == "#update_other_payment_modal") {
        let TO_FIXED_TAX_VALUE = row.TAX_VALUE.toFixed(2);
        let RATE_WITHOUT_TAX =
          parseFloat(row.UNIT_PRICE) - parseFloat(TO_FIXED_TAX_VALUE);
        this.updat_user["RATE_WITHOUT_TAX"] = RATE_WITHOUT_TAX.toFixed(2);
        this.updat_user["TAX_VALUE"] = TO_FIXED_TAX_VALUE;
        if (
          row.DESCRIPTION != "Admin Fee" &&
          row.DESCRIPTION != "HP Facilities Fee" &&
          row.DESCRIPTION != "Transfer Fee" &&
          row.DESCRIPTION != "Road Tax"
        ) {
          this.updat_user["DESCRIPTION_OTHER"] = row.DESCRIPTION;
          this.updat_user["DESCRIPTION"] = "Others Fee";
        }
      } else if (id == "#manage_credit_note_update_modal") {
        this.updat_user["PAYMENT_TERMS"] =
          this.ManageCreditNoteDetailsArray.PAYMENT_TERMS;
        this.updat_user["CUSTOMER_SYS_ID"] =
          this.ManageCreditNoteDetailsArray.CUSTOMER_SYS_ID;
        this.updat_user["CUSTOMER_ADDRESS"] =
          this.ManageCreditNoteDetailsArray.CUSTOMER_ADDRESS;
      } else if (id == "#pay_invoice_update_modal") {
        this.payinvoice["PAYMENT_CODE"] =
          this.payinvoice.PAYMENT_CODE;
          this.payinvoice["PAYMENT_DATE"] =
        
            this.payinvoice.PAYMENT_DATE
            this.payinvoice["BANK_SYS_ID"] =
        
            this.payinvoice.BANK_SYS_ID ||""
            this.payinvoice["PAYMENT_TO"] =
        
            this.payinvoice.PAYMENT_TO ||""
                       
          
        this.updat_user["CUSTOMER_SYS_ID"] =
          this.ManageCreditNoteDetailsArray.CUSTOMER_SYS_ID;
        this.updat_user["CUSTOMER_ADDRESS"] =
          this.ManageCreditNoteDetailsArray.CUSTOMER_ADDRESS;
      } else if (id == "#cost_center_update_modal") {
        this.VehicleCostCenterForm["TO_FROM"] = row.TO_FROM;
        if (row.TO_FROM == "Vendor") {
          let vendorObj = this.AllVendordetails.find(
            (items) => items.VENDOR_SYS_ID == row.CUSTOMER_SYS_ID
          );
          if (vendorObj) {
            this.COST_CENTER_VENDOR_INFO = vendorObj.VENDOR_NAME;
            this.VehicleCostCenterForm.CUSTOMER_SYS_ID =
              vendorObj.VENDOR_SYS_ID;
            this.DisplayCostCenterVendorName = vendorObj.VENDOR_NAME;
            this.DisplayCostCenterVendorCode = vendorObj.VENDOR_CODE;
            this.DisplayCostCenterVendorAddress =
              vendorObj.ADDRESS_LINE_1 +
              "," +
              vendorObj.COUNTRY_NAME +
              "-" +
              vendorObj.PIN_CODE;
          }
        } else if (row.TO_FROM == "Customer") {
          let cusObj = this.AllCustomerArray.find(
            (items) => items.CUSTOMER_SYS_ID == row.CUSTOMER_SYS_ID
          );
          if (cusObj) {
            this.COST_CENTER_CUSTOMER_INFO = cusObj.CUSTOMER_NAME;
            this.VehicleCostCenterForm.CUSTOMER_SYS_ID = cusObj.CUSTOMER_SYS_ID;
            this.DisplayCostCenterCustomerType = cusObj.CUSTOMER_TYPE;
            this.DisplayCostCenterCustomerIdNumber = cusObj.ID_NUMBER;
            this.DisplayCostCenterCustomerName = cusObj.CUSTOMER_NAME;
            let address =
              cusObj.ADDRESS_LINE_1 +
              " , " +
              cusObj.COUNTRY_NAME +
              "-" +
              cusObj.PIN_CODE;
            this.DisplayCostCenterCustomerAddress = address;
          }
        }
      }
      $(id).modal("show");
    },

    //Function To Calculate GST
    //Author :
    globalCalculateGST(calc_type) {
      let existTaxObj = {};
      let taxType = "";
      let taxPercentage = "";
      let taxValue = "";
      if (calc_type == "SELLER_DETAILS") {
        existTaxObj = this.tax_info_detai.find(
          (tax) => tax.TAX_SYS_ID == this.addupdatesellerdata.TAX_SYS_ID
        );
        if (existTaxObj) {
          taxType = existTaxObj.TAX_NAME;
          taxPercentage = existTaxObj.TAX_PERCENTAGE;
          taxValue = existTaxObj.VALUE;
        }
      } else if (calc_type == "BUYER_DETAILS") {
        existTaxObj = this.tax_info_detai.find(
          (tax) => tax.TAX_SYS_ID == this.addupdatebuyerdata.TAX_SYS_ID
        );
        if (existTaxObj) {
          this.GLOBAL_TAX_TYPE = existTaxObj.TAX_NAME;
          taxType = existTaxObj.TAX_NAME;
          taxPercentage = existTaxObj.TAX_PERCENTAGE;
          taxValue = existTaxObj.VALUE;
          this.addupdatebuyerdata.GST_TYPE = taxType;
          this.addupdatebuyerdata.GST = taxPercentage;
        }
      } else if (calc_type == "LOAN_INFO") {
        existTaxObj = this.tax_info_detai.find(
          (tax) => tax.TAX_SYS_ID == this.loan_info.LOAN_TAX_SYS_ID
        );
        if (existTaxObj) {
          taxType = existTaxObj.TAX_NAME;
          taxPercentage = existTaxObj.TAX_PERCENTAGE;
          taxValue = existTaxObj.VALUE;
        }
      } else if (calc_type == "BUYER_DEPOSIT_DETAILS") {
        existTaxObj = this.tax_info_detai.find(
          (tax) =>
            tax.TAX_SYS_ID == this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID
        );
        if (existTaxObj) {
          taxType = existTaxObj.TAX_NAME;
          taxPercentage = existTaxObj.TAX_PERCENTAGE;
          taxValue = existTaxObj.VALUE;
        }
      } else if (calc_type == "OTHER_PAYMENT_DETAILS") {
        existTaxObj = this.tax_info_detai.find(
          (tax) => tax.TAX_SYS_ID == this.addotherpayment.TAX_SYS_ID
        );
        if (existTaxObj) {
          taxType = existTaxObj.TAX_NAME;
          taxPercentage = existTaxObj.TAX_PERCENTAGE;
          taxValue = existTaxObj.VALUE;
        }
      } else if (calc_type == "OTHER_PAYMENT_DETAILS_FOR_UPDATE") {
        existTaxObj = this.tax_info_detai.find(
          (tax) => tax.TAX_SYS_ID == this.updat_user.TAX_SYS_ID
        );
        if (existTaxObj) {
          taxType = existTaxObj.TAX_NAME;
          taxPercentage = existTaxObj.TAX_PERCENTAGE;
          taxValue = existTaxObj.VALUE;
        }
      } else if (calc_type == "MANAGE_TAX_INVOICE") {
        existTaxObj = this.tax_info_detai.find(
          (tax) => tax.TAX_SYS_ID == this.addmanagetaxinvoice.TAX_SYS_ID
        );
        if (existTaxObj) {
          taxType = existTaxObj.TAX_NAME;
          taxPercentage = existTaxObj.TAX_PERCENTAGE;
          taxValue = existTaxObj.VALUE;
        }
      } else if (calc_type == "MANAGE_TAX_INVOICE_FOR_UPDATE") {
        existTaxObj = this.tax_info_detai.find(
          (tax) => tax.TAX_SYS_ID == this.updat_user.TAX_SYS_ID
        );
        if (existTaxObj) {
          taxType = existTaxObj.TAX_NAME;
          taxPercentage = existTaxObj.TAX_PERCENTAGE;
          taxValue = existTaxObj.VALUE;
        }
      } else if (calc_type == "MANAGE_CREDIT_NOTE") {
        existTaxObj = this.tax_info_detai.find(
          (tax) => tax.TAX_SYS_ID == this.ManageCreditNoteObj.TAX_SYS_ID
        );
        if (existTaxObj) {
          taxType = existTaxObj.TAX_NAME;
          taxPercentage = existTaxObj.TAX_PERCENTAGE;
          taxValue = existTaxObj.VALUE;
        }
      } else if (calc_type == "MANAGE_CREDIT_NOTE_FOR_UPDATE") {
        existTaxObj = this.tax_info_detai.find(
          (tax) => tax.TAX_SYS_ID == this.updat_user.TAX_SYS_ID
        );
        if (existTaxObj) {
          taxType = existTaxObj.TAX_NAME;
          taxPercentage = existTaxObj.TAX_PERCENTAGE;
          taxValue = existTaxObj.VALUE;
        }
      } else if (calc_type == "COST_CENTER") {
        existTaxObj = this.tax_info_detai.find(
          (tax) => tax.TAX_SYS_ID == this.VehicleCostCenterForm.TAX_SYS_ID
        );
        if (existTaxObj) {
          taxType = existTaxObj.TAX_NAME;
          taxPercentage = existTaxObj.TAX_PERCENTAGE;
          taxValue = existTaxObj.VALUE;
          this.VehicleCostCenterForm["GST_PERCENTAGE"] = taxPercentage;
        }
      } else if (calc_type == "COST_CENTER_FOR_UPDATE") {
        existTaxObj = this.tax_info_detai.find(
          (tax) => tax.TAX_SYS_ID == this.updat_user.TAX_SYS_ID
        );
        if (existTaxObj) {
          taxType = existTaxObj.TAX_NAME;
          taxPercentage = existTaxObj.TAX_PERCENTAGE;
          taxValue = existTaxObj.VALUE;
          this.updat_user["GST_PERCENTAGE"] = taxPercentage;
        }
      }

      //For GST Tax Calculation
      //For Tax Value 7
      if (taxType == "GST_7" && calc_type == "SELLER_DETAILS") {
        if (
          this.addupdatesellerdata.PURCHASE_PRICE == "" ||
          this.addupdatesellerdata.PURCHASE_PRICE == "null" ||
          this.addupdatesellerdata.PURCHASE_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        let PRICE_AMOUNT = this.addupdatesellerdata.PURCHASE_PRICE;
        let CALC_VALUE = 100 / (100 + taxPercentage);
        let MULTI_VALUE = PRICE_AMOUNT * CALC_VALUE;
        let GST_AMOUNT = PRICE_AMOUNT - MULTI_VALUE;
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        let SELLING_PRICE = PRICE_AMOUNT - GST_AMOUNT;
        this.addupdatesellerdata.PURCHASE_PRICE_SD = SELLING_PRICE.toFixed(2);
        this.addupdatesellerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT_FIXED;
        this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT = "";
        if (this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT == ".undefined")
          this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT = "0";
        this.addupdatesellerdata.TOTAL_PURCHASE_PRICE = PRICE_AMOUNT;
      } else if (taxType == "GST_7" && calc_type == "BUYER_DETAILS") {
        if (
          this.addupdatebuyerdata.SELL_PRICE == "" ||
          this.addupdatebuyerdata.SELL_PRICE == "null" ||
          this.addupdatebuyerdata.SELL_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        let PRICE_AMOUNT = this.addupdatebuyerdata.SELL_PRICE;
        let CALC_VALUE = 100 / (100 + taxPercentage);
        let MULTI_VALUE = PRICE_AMOUNT * CALC_VALUE;
        let GST_AMOUNT = PRICE_AMOUNT - MULTI_VALUE;
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        let SELLING_PRICE = PRICE_AMOUNT - GST_AMOUNT;
        this.addupdatebuyerdata.SELLING_PRICE = SELLING_PRICE.toFixed(2);
        this.addupdatebuyerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT = "";
        if (this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT == ".undefined")
          this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT = "0";
        this.addupdatebuyerdata.TOTAL_SELLING_PRICE = PRICE_AMOUNT;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID =
          this.addupdatebuyerdata.TAX_SYS_ID;
        this.globalCalculateGST("BUYER_DEPOSIT_DETAILS");
      } else if (taxType == "GST_7" && calc_type == "LOAN_INFO") {
        if (
          this.loan_info.LOAN_AMOUNT == "" ||
          this.loan_info.LOAN_AMOUNT == "null" ||
          this.loan_info.LOAN_AMOUNT == null
        ) {
          Global.showErrorAlert(true, "error", "Loan Amount Is Missing.");
          return false;
        }
        let LOAN_TAX_AMOUNT = this.loan_info.LOAN_AMOUNT;
        let CALC_VALUE = 100 / (100 + taxPercentage);
        let MULTI_VALUE = LOAN_TAX_AMOUNT * CALC_VALUE;
        let GST_AMOUNT = LOAN_TAX_AMOUNT - MULTI_VALUE;
        let LOAN_AMOUNT_WITHOUT_TAX = LOAN_TAX_AMOUNT - GST_AMOUNT;
        this.loan_info.LOAN_AMOUNT_WITHOUT_TAX =
          LOAN_AMOUNT_WITHOUT_TAX.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT.toFixed(2);
      } else if (taxType == "GST_7" && calc_type == "BUYER_DEPOSIT_DETAILS") {
        let DEPOSIT_PAY_AMOUNT =
          this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX;
        let CALC_VALUE = 100 / (100 + taxPercentage);
        let MULTI_VALUE = DEPOSIT_PAY_AMOUNT * CALC_VALUE;
        let GST_AMOUNT = DEPOSIT_PAY_AMOUNT - MULTI_VALUE;
        let DEPOSIT_AMOUNT_WITHOUT_GST = DEPOSIT_PAY_AMOUNT - GST_AMOUNT;
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITHOUT_TAX =
          DEPOSIT_AMOUNT_WITHOUT_GST.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX = DEPOSIT_PAY_AMOUNT;
      } else if (taxType == "GST_7" && calc_type == "OTHER_PAYMENT_DETAILS") {
        let RATE = this.addotherpayment.UNIT_PRICE;
        let CALC_VALUE = 100 / (100 + taxPercentage);
        let MULTI_VALUE = RATE * CALC_VALUE;
        let TAX_AMOUNT = RATE - MULTI_VALUE;
        let RATE_WITHOUT_TAX = RATE - TAX_AMOUNT;
        this.addotherpayment.RATE_WITHOUT_TAX = RATE_WITHOUT_TAX.toFixed(2);
        this.addotherpayment.TAX_VALUE = TAX_AMOUNT.toFixed(2);
        this.addotherpayment.TOTAL_PRICE = RATE;
      } else if (
        taxType == "GST_7" &&
        calc_type == "OTHER_PAYMENT_DETAILS_FOR_UPDATE"
      ) {
        let RATE = this.updat_user.UNIT_PRICE;
        let CALC_VALUE = 100 / (100 + taxPercentage);
        let MULTI_VALUE = RATE * CALC_VALUE;
        let TAX_AMOUNT = RATE - MULTI_VALUE;
        let RATE_WITHOUT_TAX = RATE - TAX_AMOUNT;
        this.updat_user.RATE_WITHOUT_TAX = RATE_WITHOUT_TAX.toFixed(2);
        this.updat_user.TAX_VALUE = TAX_AMOUNT.toFixed(2);
        this.updat_user.TOTAL_PRICE = RATE;
      } else if (
        taxType == "GST_7" &&
        calc_type == "MANAGE_TAX_INVOICE" &&
        this.addmanagetaxinvoice.UNIT_PRICE != "" &&
        this.addmanagetaxinvoice.QTY != ""
      ) {
        let UNIT_PRICE = this.addmanagetaxinvoice.UNIT_PRICE;
        let QTY = this.addmanagetaxinvoice.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = (TOTAL_PRICE_WITHOUT_TAX * taxPercentage) / 100;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.addmanagetaxinvoice.TAX_VALUE = TAX_AMOUNT.toFixed(2);
        this.addmanagetaxinvoice.TOTAL_PRICE = TOTAL_PRICE_WITH_TAX.toFixed(2);
        this.addmanagetaxinvoice.TAX_PERCENTAGE = taxPercentage;
      } else if (
        taxType == "GST_7" &&
        calc_type == "MANAGE_TAX_INVOICE" &&
        this.addmanagetaxinvoice.UNIT_PRICE == "" &&
        this.addmanagetaxinvoice.QTY == ""
      ) {
        this.addmanagetaxinvoice.TAX_VALUE = "";
        this.addmanagetaxinvoice.TOTAL_PRICE = "";
        this.addmanagetaxinvoice.TAX_PERCENTAGE = "";
      } else if (
        taxType == "GST_7" &&
        calc_type == "MANAGE_TAX_INVOICE_FOR_UPDATE" &&
        this.updat_user.UNIT_PRICE != "" &&
        this.updat_user.QTY != ""
      ) {
        let UNIT_PRICE = this.updat_user.UNIT_PRICE;
        let QTY = this.updat_user.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = (TOTAL_PRICE_WITHOUT_TAX * taxPercentage) / 100;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.updat_user["TAX_VALUE"] = TAX_AMOUNT.toFixed(2);
        this.updat_user["TOTAL_AMOUNT"] = TOTAL_PRICE_WITH_TAX.toFixed(2);
        this.updat_user["TAX_PERCENTAGE"] = taxPercentage;
      } else if (
        taxType == "GST_7" &&
        calc_type == "MANAGE_TAX_INVOICE_FOR_UPDATE" &&
        this.updat_user.UNIT_PRICE == "" &&
        this.updat_user.QTY == ""
      ) {
        this.updat_user["TAX_VALUE"] = "";
        this.updat_user["TOTAL_AMOUNT"] = "";
        this.updat_user["TAX_PERCENTAGE"] = "";
      } else if (
        taxType == "GST_7" &&
        calc_type == "MANAGE_CREDIT_NOTE" &&
        this.ManageCreditNoteObj.UNIT_PRICE != "" &&
        this.ManageCreditNoteObj.QTY != ""
      ) {
        let UNIT_PRICE = this.ManageCreditNoteObj.UNIT_PRICE;
        let QTY = this.ManageCreditNoteObj.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = (TOTAL_PRICE_WITHOUT_TAX * taxPercentage) / 100;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.ManageCreditNoteObj.UNIT_TOTAL =
          TOTAL_PRICE_WITHOUT_TAX.toFixed(2);
        this.ManageCreditNoteObj.TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        this.ManageCreditNoteObj.AMOUNT = TOTAL_PRICE_WITH_TAX.toFixed(2);
      } else if (
        taxType == "GST_7" &&
        calc_type == "MANAGE_CREDIT_NOTE" &&
        this.ManageCreditNoteObj.UNIT_PRICE == "" &&
        this.ManageCreditNoteObj.QTY == ""
      ) {
        this.ManageCreditNoteObj.TAX_AMOUNT = "";
        this.ManageCreditNoteObj.AMOUNT = "";
      } else if (
        taxType == "GST_7" &&
        calc_type == "MANAGE_CREDIT_NOTE_FOR_UPDATE" &&
        this.updat_user.UNIT_PRICE != "" &&
        this.updat_user.QTY != ""
      ) {
        let UNIT_PRICE = this.updat_user.UNIT_PRICE;
        let QTY = this.updat_user.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = (TOTAL_PRICE_WITHOUT_TAX * taxPercentage) / 100;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.updat_user.UNIT_TOTAL = TOTAL_PRICE_WITHOUT_TAX.toFixed(2);
        this.updat_user.TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        this.updat_user.AMOUNT = TOTAL_PRICE_WITH_TAX.toFixed(2);
      } else if (taxType == "GST_7" && calc_type == "COST_CENTER") {
        let AMOUNT_BEF_GST = this.VehicleCostCenterForm.AMOUNT_BEF_GST;
        let TAX_AMOUNT = (AMOUNT_BEF_GST * taxPercentage) / 100;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(AMOUNT_BEF_GST) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.VehicleCostCenterForm.GST_AMOUNT = TAX_AMOUNT.toFixed(2);
        this.VehicleCostCenterForm.TOTAL_AMOUNT = TOTAL_PRICE_WITH_TAX.toFixed(2);
      } else if (taxType == "GST_7" && calc_type == "COST_CENTER_FOR_UPDATE") {
        let AMOUNT_BEF_GST = this.updat_user.AMOUNT_BEF_GST;
        let TAX_AMOUNT = (AMOUNT_BEF_GST * taxPercentage) / 100;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(AMOUNT_BEF_GST) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.updat_user.GST_AMOUNT = TAX_AMOUNT.toFixed(2);
        this.updat_user.TOTAL_AMOUNT = TOTAL_PRICE_WITH_TAX.toFixed(2);
      }

      //For GST Tax Calculation
      //For Tax Value 8
      if (taxType == "GST_8" && calc_type == "SELLER_DETAILS") {
        if (
          this.addupdatesellerdata.PURCHASE_PRICE == "" ||
          this.addupdatesellerdata.PURCHASE_PRICE == "null" ||
          this.addupdatesellerdata.PURCHASE_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        let PRICE_AMOUNT = this.addupdatesellerdata.PURCHASE_PRICE;
        let CALC_VALUE = 100 / (100 + taxPercentage);
        let MULTI_VALUE = PRICE_AMOUNT * CALC_VALUE;
        let GST_AMOUNT = PRICE_AMOUNT - MULTI_VALUE;
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        let SELLING_PRICE = PRICE_AMOUNT - GST_AMOUNT;
        this.addupdatesellerdata.PURCHASE_PRICE_SD = SELLING_PRICE.toFixed(2);
        this.addupdatesellerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT_FIXED;
        this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT = "";
        if (this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT == ".undefined")
          this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT = "0";
        this.addupdatesellerdata.TOTAL_PURCHASE_PRICE = PRICE_AMOUNT;
      } else if (taxType == "GST_8" && calc_type == "BUYER_DETAILS") {
        if (
          this.addupdatebuyerdata.SELL_PRICE == "" ||
          this.addupdatebuyerdata.SELL_PRICE == "null" ||
          this.addupdatebuyerdata.SELL_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        let PRICE_AMOUNT = this.addupdatebuyerdata.SELL_PRICE;
        let CALC_VALUE = 100 / (100 + taxPercentage);
        let MULTI_VALUE = PRICE_AMOUNT * CALC_VALUE;
        let GST_AMOUNT = PRICE_AMOUNT - MULTI_VALUE;
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        let SELLING_PRICE = PRICE_AMOUNT - GST_AMOUNT;
        this.addupdatebuyerdata.SELLING_PRICE = SELLING_PRICE.toFixed(2);
        this.addupdatebuyerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT = "";
        if (this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT == ".undefined")
          this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT = "0";
        this.addupdatebuyerdata.TOTAL_SELLING_PRICE = PRICE_AMOUNT;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID =
          this.addupdatebuyerdata.TAX_SYS_ID;
        this.globalCalculateGST("BUYER_DEPOSIT_DETAILS");
      } else if (taxType == "GST_8" && calc_type == "LOAN_INFO") {
        if (
          this.loan_info.LOAN_AMOUNT == "" ||
          this.loan_info.LOAN_AMOUNT == "null" ||
          this.loan_info.LOAN_AMOUNT == null
        ) {
          Global.showErrorAlert(true, "error", "Loan Amount Is Missing.");
          return false;
        }
        let LOAN_TAX_AMOUNT = this.loan_info.LOAN_AMOUNT;
        let CALC_VALUE = 100 / (100 + taxPercentage);
        let MULTI_VALUE = LOAN_TAX_AMOUNT * CALC_VALUE;
        let GST_AMOUNT = LOAN_TAX_AMOUNT - MULTI_VALUE;
        let LOAN_AMOUNT_WITHOUT_TAX = LOAN_TAX_AMOUNT - GST_AMOUNT;
        this.loan_info.LOAN_AMOUNT_WITHOUT_TAX =
          LOAN_AMOUNT_WITHOUT_TAX.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT.toFixed(2);
      } else if (taxType == "GST_8" && calc_type == "BUYER_DEPOSIT_DETAILS") {
        let DEPOSIT_PAY_AMOUNT =
          this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX;
        let CALC_VALUE = 100 / (100 + taxPercentage);
        let MULTI_VALUE = DEPOSIT_PAY_AMOUNT * CALC_VALUE;
        let GST_AMOUNT = DEPOSIT_PAY_AMOUNT - MULTI_VALUE;
        let DEPOSIT_AMOUNT_WITHOUT_GST = DEPOSIT_PAY_AMOUNT - GST_AMOUNT;
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITHOUT_TAX =
          DEPOSIT_AMOUNT_WITHOUT_GST.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX = DEPOSIT_PAY_AMOUNT;
      } else if (taxType == "GST_8" && calc_type == "OTHER_PAYMENT_DETAILS") {
        let RATE = this.addotherpayment.UNIT_PRICE;
        let CALC_VALUE = 100 / (100 + taxPercentage);
        let MULTI_VALUE = RATE * CALC_VALUE;
        let TAX_AMOUNT = RATE - MULTI_VALUE;
        let RATE_WITHOUT_TAX = RATE - TAX_AMOUNT;
        this.addotherpayment.RATE_WITHOUT_TAX = RATE_WITHOUT_TAX.toFixed(2);
        this.addotherpayment.TAX_VALUE = TAX_AMOUNT.toFixed(2);
        this.addotherpayment.TOTAL_PRICE = RATE;
      } else if (
        taxType == "GST_8" &&
        calc_type == "OTHER_PAYMENT_DETAILS_FOR_UPDATE"
      ) {
        let RATE = this.updat_user.UNIT_PRICE;
        let CALC_VALUE = 100 / (100 + taxPercentage);
        let MULTI_VALUE = RATE * CALC_VALUE;
        let TAX_AMOUNT = RATE - MULTI_VALUE;
        let RATE_WITHOUT_TAX = RATE - TAX_AMOUNT;
        this.updat_user.RATE_WITHOUT_TAX = RATE_WITHOUT_TAX.toFixed(2);
        this.updat_user.TAX_VALUE = TAX_AMOUNT.toFixed(2);
        this.updat_user.TOTAL_PRICE = RATE;
      } else if (
        taxType == "GST_8" &&
        calc_type == "MANAGE_TAX_INVOICE" &&
        this.addmanagetaxinvoice.UNIT_PRICE != "" &&
        this.addmanagetaxinvoice.QTY != ""
      ) {
        let UNIT_PRICE = this.addmanagetaxinvoice.UNIT_PRICE;
        let QTY = this.addmanagetaxinvoice.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = (TOTAL_PRICE_WITHOUT_TAX * taxPercentage) / 100;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.addmanagetaxinvoice.TAX_VALUE = TAX_AMOUNT;
        this.addmanagetaxinvoice.TOTAL_PRICE = TOTAL_PRICE_WITH_TAX;
        this.addmanagetaxinvoice.TAX_PERCENTAGE = taxPercentage;
      } else if (
        taxType == "GST_8" &&
        calc_type == "MANAGE_TAX_INVOICE" &&
        this.addmanagetaxinvoice.UNIT_PRICE == "" &&
        this.addmanagetaxinvoice.QTY == ""
      ) {
        this.addmanagetaxinvoice.TAX_VALUE = "";
        this.addmanagetaxinvoice.TOTAL_PRICE = "";
        this.addmanagetaxinvoice.TAX_PERCENTAGE = "";
      } else if (
        taxType == "GST_8" &&
        calc_type == "MANAGE_TAX_INVOICE_FOR_UPDATE" &&
        this.updat_user.UNIT_PRICE != "" &&
        this.updat_user.QTY != ""
      ) {
        let UNIT_PRICE = this.updat_user.UNIT_PRICE;
        let QTY = this.updat_user.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = (TOTAL_PRICE_WITHOUT_TAX * taxPercentage) / 100;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.updat_user["TAX_VALUE"] = TAX_AMOUNT;
        this.updat_user["TOTAL_AMOUNT"] = TOTAL_PRICE_WITH_TAX;
        this.updat_user["TAX_PERCENTAGE"] = taxPercentage;
      } else if (
        taxType == "GST_8" &&
        calc_type == "MANAGE_TAX_INVOICE_FOR_UPDATE" &&
        this.updat_user.UNIT_PRICE == "" &&
        this.updat_user.QTY == ""
      ) {
        this.updat_user["TAX_VALUE"] = "";
        this.updat_user["TOTAL_AMOUNT"] = "";
        this.updat_user["TAX_PERCENTAGE"] = "";
      } else if (
        taxType == "GST_8" &&
        calc_type == "MANAGE_CREDIT_NOTE" &&
        this.ManageCreditNoteObj.UNIT_PRICE != "" &&
        this.ManageCreditNoteObj.QTY != ""
      ) {
        let UNIT_PRICE = this.ManageCreditNoteObj.UNIT_PRICE;
        let QTY = this.ManageCreditNoteObj.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = (TOTAL_PRICE_WITHOUT_TAX * taxPercentage) / 100;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.ManageCreditNoteObj.UNIT_TOTAL =
          TOTAL_PRICE_WITHOUT_TAX.toFixed(2);
        this.ManageCreditNoteObj.TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        this.ManageCreditNoteObj.AMOUNT = TOTAL_PRICE_WITH_TAX.toFixed(2);
      } else if (
        taxType == "GST_8" &&
        calc_type == "MANAGE_CREDIT_NOTE" &&
        this.ManageCreditNoteObj.UNIT_PRICE == "" &&
        this.ManageCreditNoteObj.QTY == ""
      ) {
        this.ManageCreditNoteObj.TAX_AMOUNT = "";
        this.ManageCreditNoteObj.AMOUNT = "";
      } else if (
        taxType == "GST_8" &&
        calc_type == "MANAGE_CREDIT_NOTE_FOR_UPDATE" &&
        this.updat_user.UNIT_PRICE != "" &&
        this.updat_user.QTY != ""
      ) {
        let UNIT_PRICE = this.updat_user.UNIT_PRICE;
        let QTY = this.updat_user.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = (TOTAL_PRICE_WITHOUT_TAX * taxPercentage) / 100;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.updat_user.UNIT_TOTAL = TOTAL_PRICE_WITHOUT_TAX.toFixed(2);
        this.updat_user.TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        this.updat_user.AMOUNT = TOTAL_PRICE_WITH_TAX.toFixed(2);
      } else if (taxType == "GST_8" && calc_type == "COST_CENTER") {
        let AMOUNT_BEF_GST = this.VehicleCostCenterForm.AMOUNT_BEF_GST;
        let TAX_AMOUNT = (AMOUNT_BEF_GST * taxPercentage) / 100;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(AMOUNT_BEF_GST) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.VehicleCostCenterForm.GST_AMOUNT = TAX_AMOUNT.toFixed(2);
        this.VehicleCostCenterForm.TOTAL_AMOUNT = TOTAL_PRICE_WITH_TAX.toFixed(2);
      } else if (taxType == "GST_8" && calc_type == "COST_CENTER_FOR_UPDATE") {
        let AMOUNT_BEF_GST = this.updat_user.AMOUNT_BEF_GST;
        let TAX_AMOUNT = (AMOUNT_BEF_GST * taxPercentage) / 100;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(AMOUNT_BEF_GST) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.updat_user.GST_AMOUNT = TAX_AMOUNT.toFixed(2);
        this.updat_user.TOTAL_AMOUNT = TOTAL_PRICE_WITH_TAX.toFixed(2);
      }

      //For DRS Tax Calculation
      //For Tax Value 7
      if (taxType == "DRS_7" && calc_type == "SELLER_DETAILS") {
        if (
          this.addupdatesellerdata.PURCHASE_PRICE == "" ||
          this.addupdatesellerdata.PURCHASE_PRICE == "null" ||
          this.addupdatesellerdata.PURCHASE_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        let SELL_PRICE = this.addupdatesellerdata.PURCHASE_PRICE;
        let GST_AMOUNT = (SELL_PRICE * taxPercentage) / parseInt(taxValue);
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        this.addupdatesellerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT_FIXED;
        let SELLING_PRICE = SELL_PRICE - GST_AMOUNT_FIXED;
        this.addupdatesellerdata.PURCHASE_PRICE_SD = SELLING_PRICE.toFixed(2);
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT_FIXED;
        this.addupdatesellerdata.TOTAL_PURCHASE_PRICE = SELL_PRICE;
      } else if (taxType == "DRS_7" && calc_type == "BUYER_DETAILS") {
        if (
          this.addupdatebuyerdata.SELL_PRICE == "" ||
          this.addupdatebuyerdata.SELL_PRICE == "null" ||
          this.addupdatebuyerdata.SELL_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        let SELL_PRICE = this.addupdatebuyerdata.SELL_PRICE;
        let GST_AMOUNT = (SELL_PRICE * taxPercentage) / parseInt(taxValue);
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        this.addupdatebuyerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = GST_AMOUNT_FIXED;
        let SELLING_PRICE = SELL_PRICE - GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.SELLING_PRICE = SELLING_PRICE.toFixed(2);
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.TOTAL_SELLING_PRICE = SELL_PRICE;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID =
          this.addupdatebuyerdata.TAX_SYS_ID;
        this.globalCalculateGST("BUYER_DEPOSIT_DETAILS");
      } else if (taxType == "DRS_7" && calc_type == "LOAN_INFO") {
        if (
          this.loan_info.LOAN_AMOUNT == "" ||
          this.loan_info.LOAN_AMOUNT == "null" ||
          this.loan_info.LOAN_AMOUNT == null
        ) {
          Global.showErrorAlert(true, "error", "Loan Amount Is Missing.");
          return false;
        }
        let LOAN_TAX_AMOUNT = this.loan_info.LOAN_AMOUNT;
        let GST_AMOUNT = (LOAN_TAX_AMOUNT * taxPercentage) / parseInt(taxValue);
        let LOAN_AMOUNT_WITHOUT_TAX = LOAN_TAX_AMOUNT - GST_AMOUNT;
        this.loan_info.LOAN_AMOUNT_WITHOUT_TAX =
          LOAN_AMOUNT_WITHOUT_TAX.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT.toFixed(2);
      } else if (taxType == "DRS_7" && calc_type == "BUYER_DEPOSIT_DETAILS") {
        let DEPOSIT_PAY_AMOUNT =
          this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX;
        let GST_AMOUNT =
          (DEPOSIT_PAY_AMOUNT * taxPercentage) / parseInt(taxValue);
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        let DEPOSIT_AMOUNT_WITHOUT_GST = DEPOSIT_PAY_AMOUNT - GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITHOUT_TAX =
          DEPOSIT_AMOUNT_WITHOUT_GST.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX = DEPOSIT_PAY_AMOUNT;
      }

      //For DRS Tax Calculation
      //For Tax Value 8
      if (taxType == "DRS_8" && calc_type == "SELLER_DETAILS") {
        if (
          this.addupdatesellerdata.PURCHASE_PRICE == "" ||
          this.addupdatesellerdata.PURCHASE_PRICE == "null" ||
          this.addupdatesellerdata.PURCHASE_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        let SELL_PRICE = this.addupdatesellerdata.PURCHASE_PRICE;
        let GST_AMOUNT = (SELL_PRICE * taxPercentage) / parseInt(taxValue);
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        this.addupdatesellerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT_FIXED;
        let SELLING_PRICE = SELL_PRICE - GST_AMOUNT_FIXED;
        this.addupdatesellerdata.PURCHASE_PRICE_SD = SELLING_PRICE.toFixed(2);
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT_FIXED;
        this.addupdatesellerdata.TOTAL_PURCHASE_PRICE = SELL_PRICE;
      } else if (taxType == "DRS_8" && calc_type == "BUYER_DETAILS") {
        if (
          this.addupdatebuyerdata.SELL_PRICE == "" ||
          this.addupdatebuyerdata.SELL_PRICE == "null" ||
          this.addupdatebuyerdata.SELL_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        let SELL_PRICE = this.addupdatebuyerdata.SELL_PRICE;
        let GST_AMOUNT = (SELL_PRICE * taxPercentage) / parseInt(taxValue);
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        this.addupdatebuyerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = GST_AMOUNT_FIXED;
        let SELLING_PRICE = SELL_PRICE - GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.SELLING_PRICE = SELLING_PRICE.toFixed(2);
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.TOTAL_SELLING_PRICE = SELL_PRICE;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID =
          this.addupdatebuyerdata.TAX_SYS_ID;
        this.globalCalculateGST("BUYER_DEPOSIT_DETAILS");
      } else if (taxType == "DRS_8" && calc_type == "LOAN_INFO") {
        if (
          this.loan_info.LOAN_AMOUNT == "" ||
          this.loan_info.LOAN_AMOUNT == "null" ||
          this.loan_info.LOAN_AMOUNT == null
        ) {
          Global.showErrorAlert(true, "error", "Loan Amount Is Missing.");
          return false;
        }
        let LOAN_TAX_AMOUNT = this.loan_info.LOAN_AMOUNT;
        let GST_AMOUNT = (LOAN_TAX_AMOUNT * taxPercentage) / parseInt(taxValue);
        let LOAN_AMOUNT_WITHOUT_TAX = LOAN_TAX_AMOUNT - GST_AMOUNT;
        this.loan_info.LOAN_AMOUNT_WITHOUT_TAX =
          LOAN_AMOUNT_WITHOUT_TAX.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT.toFixed(2);
      } else if (taxType == "DRS_8" && calc_type == "BUYER_DEPOSIT_DETAILS") {
        let DEPOSIT_PAY_AMOUNT =
          this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX;
        let GST_AMOUNT =
          (DEPOSIT_PAY_AMOUNT * taxPercentage) / parseInt(taxValue);
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        let DEPOSIT_AMOUNT_WITHOUT_GST = DEPOSIT_PAY_AMOUNT - GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITHOUT_TAX =
          DEPOSIT_AMOUNT_WITHOUT_GST.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX = DEPOSIT_PAY_AMOUNT;
      }

      //For GMS Tax Calculation
      //For Tax Value 7
      if (taxType == "GMS_7" && calc_type == "SELLER_DETAILS") {
        if (
          this.addupdatesellerdata.PURCHASE_PRICE == "" ||
          this.addupdatesellerdata.PURCHASE_PRICE == "null" ||
          this.addupdatesellerdata.PURCHASE_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        if (
          this.addupdatebuyerdata.SELL_PRICE == "" ||
          this.addupdatebuyerdata.SELL_PRICE == "null" ||
          this.addupdatebuyerdata.SELL_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Selllng Price Is Missing ");
          this.addupdatesellerdata.GST_AMOUNT = "";
          this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = "";
          this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT = "";
          this.addupdatesellerdata.TOTAL_PURCHASE_PRICE =
            this.addupdatesellerdata.PURCHASE_PRICE;
          this.addupdatesellerdata.PURCHASE_PRICE_SD =
            this.addupdatesellerdata.PURCHASE_PRICE;
          return false;
        }
        let SELL_PRICE =
          this.addupdatebuyerdata.SELL_PRICE ||
          this.addupdatesellerdata.SELLING_PRICE;
        let PURCHASE_PRICE = this.addupdatesellerdata.PURCHASE_PRICE;
        let GST_AMOUNT =
          ((SELL_PRICE - PURCHASE_PRICE) * taxPercentage) / parseInt(taxValue);
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        this.addupdatesellerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT_FIXED;
        let SELLING_PRICE = PURCHASE_PRICE - GST_AMOUNT_FIXED;
        this.addupdatesellerdata.PURCHASE_PRICE_SD = SELLING_PRICE.toFixed(2);
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT_FIXED;
        this.addupdatesellerdata.TOTAL_PURCHASE_PRICE = PURCHASE_PRICE;
      } else if (taxType == "GMS_7" && calc_type == "BUYER_DETAILS") {
        if (
          this.addupdatebuyerdata.SELL_PRICE == "" ||
          this.addupdatebuyerdata.SELL_PRICE == "null" ||
          this.addupdatebuyerdata.SELL_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        if (this.addupdatesellerdata.PURCHASE_PRICE_SD == "") {
          Global.showErrorAlert(true, "error", "purchase Price Is Missing ");
          this.addupdatebuyerdata.GST_AMOUNT = "";
          this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = "";
          this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT = "";
          this.addupdatebuyerdata.SELLING_PRICE =
            this.addupdatebuyerdata.SELL_PRICE;
          this.addupdatebuyerdata.TOTAL_SELLING_PRICE =
            this.addupdatebuyerdata.SELL_PRICE;
          return false;
        }
        let SELL_PRICE = this.addupdatebuyerdata.SELL_PRICE;
        let PURCHASE_PRICE =
          this.addupdatesellerdata.PURCHASE_PRICE ||
          this.addupdatesellerdata.PURCHASE_PRICE_SD;
        let GST_AMOUNT =
          ((SELL_PRICE - PURCHASE_PRICE) * taxPercentage) / parseInt(taxValue);
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        this.addupdatebuyerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = GST_AMOUNT_FIXED;
        let SELLING_PRICE = SELL_PRICE - GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.SELLING_PRICE = SELLING_PRICE.toFixed(2);
        this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.TOTAL_SELLING_PRICE = SELL_PRICE;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID =
          this.addupdatebuyerdata.TAX_SYS_ID;
        this.globalCalculateGST("BUYER_DEPOSIT_DETAILS");
      } else if (taxType == "GMS_7" && calc_type == "LOAN_INFO") {
        if (
          this.loan_info.LOAN_AMOUNT == "" ||
          this.loan_info.LOAN_AMOUNT == "null" ||
          this.loan_info.LOAN_AMOUNT == null
        ) {
          Global.showErrorAlert(true, "error", "Loan Amount Is Missing.");
          return false;
        }
        if (this.addupdatesellerdata.PURCHASE_PRICE_SD == "") {
          Global.showErrorAlert(true, "error", "Purchase Price Is Missing ");
          this.loan_info.LOAN_AMOUNT_WITHOUT_TAX = "";
          return false;
        }
        let SELL_PRICE = this.addupdatebuyerdata.TOTAL_SELLING_PRICE;
        let LOAN_TAX_AMOUNT = this.loan_info.LOAN_AMOUNT;
        let PURCHASE_PRICE = this.addupdatesellerdata.PURCHASE_PRICE_SD;
        let GST_AMOUNT =
          ((SELL_PRICE - PURCHASE_PRICE) * taxPercentage) / parseInt(taxValue);
        let LOAN_AMOUNT_WITHOUT_TAX = LOAN_TAX_AMOUNT - GST_AMOUNT;
        this.loan_info.LOAN_AMOUNT_WITHOUT_TAX =
          LOAN_AMOUNT_WITHOUT_TAX.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT.toFixed(2);
      } else if (taxType == "GMS_7" && calc_type == "BUYER_DEPOSIT_DETAILS") {
        if (this.addupdatesellerdata.PURCHASE_PRICE_SD == "") {
          Global.showErrorAlert(true, "error", "purchase Price Is Missing ");
          this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT = "";
          this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX = "";
          this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITHOUT_TAX = "";
          return false;
        }
        let DEPOSIT_PAY_AMOUNT =
          this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX;
        let TOTAL_SELLING_PRICE = this.addupdatebuyerdata.TOTAL_SELLING_PRICE;
        let PURCHASE_PRICE =
          this.addupdatesellerdata.PURCHASE_PRICE ||
          this.addupdatesellerdata.PURCHASE_PRICE_SD;
        let GST_AMOUNT =
          ((TOTAL_SELLING_PRICE - PURCHASE_PRICE) * taxPercentage) /
          parseInt(taxValue);
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        let DEPOSIT_AMOUNT_WITHOUT_GST = DEPOSIT_PAY_AMOUNT - GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITHOUT_TAX =
          DEPOSIT_AMOUNT_WITHOUT_GST.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX = DEPOSIT_PAY_AMOUNT;
      }

      //For GMS Tax Calculation
      //For Tax Value 8
      if (taxType == "GMS_8" && calc_type == "SELLER_DETAILS") {
        if (
          this.addupdatesellerdata.PURCHASE_PRICE == "" ||
          this.addupdatesellerdata.PURCHASE_PRICE == "null" ||
          this.addupdatesellerdata.PURCHASE_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        if (
          this.addupdatebuyerdata.SELL_PRICE == "" ||
          this.addupdatebuyerdata.SELL_PRICE == "null" ||
          this.addupdatebuyerdata.SELL_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Selllng Price Is Missing ");
          this.addupdatesellerdata.GST_AMOUNT = "";
          this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = "";
          this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT = "";
          this.addupdatesellerdata.TOTAL_PURCHASE_PRICE =
            this.addupdatesellerdata.PURCHASE_PRICE;
          this.addupdatesellerdata.PURCHASE_PRICE_SD =
            this.addupdatesellerdata.PURCHASE_PRICE;
          return false;
        }
        let SELL_PRICE =
          this.addupdatebuyerdata.SELL_PRICE ||
          this.addupdatesellerdata.SELLING_PRICE;
        let PURCHASE_PRICE = this.addupdatesellerdata.PURCHASE_PRICE;
        let GST_AMOUNT =
          ((SELL_PRICE - PURCHASE_PRICE) * taxPercentage) / parseInt(taxValue);
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        this.addupdatesellerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT_FIXED;
        let SELLING_PRICE = PURCHASE_PRICE - GST_AMOUNT_FIXED;
        this.addupdatesellerdata.PURCHASE_PRICE_SD = SELLING_PRICE.toFixed(2);
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT_FIXED;
        this.addupdatesellerdata.TOTAL_PURCHASE_PRICE = PURCHASE_PRICE;
      } else if (taxType == "GMS_8" && calc_type == "BUYER_DETAILS") {
        if (
          this.addupdatebuyerdata.SELL_PRICE == "" ||
          this.addupdatebuyerdata.SELL_PRICE == "null" ||
          this.addupdatebuyerdata.SELL_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        if (this.addupdatesellerdata.PURCHASE_PRICE_SD == "") {
          Global.showErrorAlert(true, "error", "purchase Price Is Missing ");
          this.addupdatebuyerdata.GST_AMOUNT = "";
          this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = "";
          this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT = "";
          this.addupdatebuyerdata.SELLING_PRICE =
            this.addupdatebuyerdata.SELL_PRICE;
          this.addupdatebuyerdata.TOTAL_SELLING_PRICE =
            this.addupdatebuyerdata.SELL_PRICE;
          return false;
        }
        let SELL_PRICE = this.addupdatebuyerdata.SELL_PRICE;
        let PURCHASE_PRICE =
          this.addupdatesellerdata.PURCHASE_PRICE ||
          this.addupdatesellerdata.PURCHASE_PRICE_SD;
        let GST_AMOUNT =
          ((SELL_PRICE - PURCHASE_PRICE) * taxPercentage) / parseInt(taxValue);
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        this.addupdatebuyerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = GST_AMOUNT_FIXED;
        let SELLING_PRICE = SELL_PRICE - GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.SELLING_PRICE = SELLING_PRICE.toFixed(2);
        this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.TOTAL_SELLING_PRICE = SELL_PRICE;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID =
          this.addupdatebuyerdata.TAX_SYS_ID;
        this.globalCalculateGST("BUYER_DEPOSIT_DETAILS");
      } else if (taxType == "GMS_8" && calc_type == "LOAN_INFO") {
        if (
          this.loan_info.LOAN_AMOUNT == "" ||
          this.loan_info.LOAN_AMOUNT == "null" ||
          this.loan_info.LOAN_AMOUNT == null
        ) {
          Global.showErrorAlert(true, "error", "Loan Amount Is Missing.");
          return false;
        }
        if (this.addupdatesellerdata.PURCHASE_PRICE_SD == "") {
          Global.showErrorAlert(true, "error", "Purchase Price Is Missing ");
          this.loan_info.LOAN_AMOUNT_WITHOUT_TAX = "";
          return false;
        }
        let SELL_PRICE = this.addupdatebuyerdata.TOTAL_SELLING_PRICE;
        let LOAN_TAX_AMOUNT = this.loan_info.LOAN_AMOUNT;
        let PURCHASE_PRICE = this.addupdatesellerdata.PURCHASE_PRICE_SD;
        let GST_AMOUNT =
          ((SELL_PRICE - PURCHASE_PRICE) * taxPercentage) / parseInt(taxValue);
        let LOAN_AMOUNT_WITHOUT_TAX = LOAN_TAX_AMOUNT - GST_AMOUNT;
        this.loan_info.LOAN_AMOUNT_WITHOUT_TAX =
          LOAN_AMOUNT_WITHOUT_TAX.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT.toFixed(2);
      } else if (taxType == "GMS_8" && calc_type == "BUYER_DEPOSIT_DETAILS") {
        if (this.addupdatesellerdata.PURCHASE_PRICE_SD == "") {
          Global.showErrorAlert(true, "error", "purchase Price Is Missing ");
          this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT = "";
          this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX = "";
          this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITHOUT_TAX = "";
          return false;
        }
        let DEPOSIT_PAY_AMOUNT =
          this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX;
        let TOTAL_SELLING_PRICE = this.addupdatebuyerdata.TOTAL_SELLING_PRICE;
        let PURCHASE_PRICE =
          this.addupdatesellerdata.PURCHASE_PRICE ||
          this.addupdatesellerdata.PURCHASE_PRICE_SD;
        let GST_AMOUNT =
          ((TOTAL_SELLING_PRICE - PURCHASE_PRICE) * taxPercentage) /
          parseInt(taxValue);
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        let DEPOSIT_AMOUNT_WITHOUT_GST = DEPOSIT_PAY_AMOUNT - GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITHOUT_TAX =
          DEPOSIT_AMOUNT_WITHOUT_GST.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX = DEPOSIT_PAY_AMOUNT;
      }

      //For NEW Tax Calculation
      //For tax Value 7
      if (taxType == "NEW_7" && calc_type == "SELLER_DETAILS") {
        if (
          this.addupdatesellerdata.PURCHASE_PRICE == "" ||
          this.addupdatesellerdata.PURCHASE_PRICE == "null" ||
          this.addupdatesellerdata.PURCHASE_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        let PRICE_AMOUNT = this.addupdatebuyerdata.SELL_PRICE;
        let PURCHASE_PRICE = this.addupdatesellerdata.PURCHASE_PRICE;
        let GST_AMOUNT =
          ((this.addupdatesellerdata.PURCHASE_PRICE -
            this.addmasterdata.ARF_AMOUNT -
            this.addmasterdata.COE_LOG_GUARD -
            this.addmasterdata.ROAD_TAX -
            this.addmasterdata.REGISTRATION_FEE) *
            taxPercentage) /
          parseInt(taxValue);
        let DEDACUTION_AMOUNT =
          this.addupdatesellerdata.PURCHASE_PRICE -
          this.addmasterdata.ARF_AMOUNT -
          this.addmasterdata.COE_LOG_GUARD -
          this.addmasterdata.ROAD_TAX -
          this.addmasterdata.REGISTRATION_FEE;
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        this.addupdatesellerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT_FIXED;
        this.addupdatesellerdata.PURCHASE_PRICE_SD =
          PURCHASE_PRICE - this.addupdatesellerdata.GST_AMOUNT;
        this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT = "";
        if (this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT == ".undefined")
          this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT = "0";
        this.addupdatesellerdata.TOTAL_PURCHASE_PRICE = PURCHASE_PRICE;
      } else if (taxType == "NEW_7" && calc_type == "BUYER_DETAILS") {
        if (
          this.addupdatebuyerdata.SELL_PRICE == "" ||
          this.addupdatebuyerdata.SELL_PRICE == "null" ||
          this.addupdatebuyerdata.SELL_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        let PRICE_AMOUNT = this.addupdatebuyerdata.SELL_PRICE;
        let PURCHASE_PRICE = this.addupdatesellerdata.PURCHASE_PRICE_SD;
        let GST_AMOUNT =
          ((this.addupdatebuyerdata.SELL_PRICE -
            this.addmasterdata.ARF_AMOUNT -
            this.addmasterdata.COE_LOG_GUARD -
            this.addmasterdata.ROAD_TAX -
            this.addmasterdata.REGISTRATION_FEE) *
            taxPercentage) /
          parseInt(taxValue);
        let DEDACUTION_AMOUNT =
          this.addupdatebuyerdata.SELL_PRICE -
          this.addmasterdata.ARF_AMOUNT -
          this.addmasterdata.COE_LOG_GUARD -
          this.addmasterdata.ROAD_TAX -
          this.addmasterdata.REGISTRATION_FEE;
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        this.addupdatebuyerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.SELLING_PRICE =
          PRICE_AMOUNT - this.addupdatebuyerdata.GST_AMOUNT;
        this.addupdatebuyerdata.NON_TAXABLE_SUPPLIES =
          parseInt(this.addmasterdata.ARF_AMOUNT) +
          parseInt(this.addmasterdata.COE_LOG_GUARD) +
          parseInt(this.addmasterdata.ROAD_TAX) +
          parseInt(this.addmasterdata.REGISTRATION_FEE);
        this.addupdatebuyerdata.TAXABLE_SUPPLIES =
          this.addupdatebuyerdata.SELLING_PRICE -
          this.addupdatebuyerdata.NON_TAXABLE_SUPPLIES;
        this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT = "";
        if (this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT == ".undefined")
          this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT = "0";
        this.addupdatebuyerdata.TOTAL_SELLING_PRICE = PRICE_AMOUNT;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID =
          this.addupdatebuyerdata.TAX_SYS_ID;
        this.globalCalculateGST("BUYER_DEPOSIT_DETAILS");
      } else if (taxType == "NEW_7" && calc_type == "LOAN_INFO") {
        if (
          this.loan_info.LOAN_AMOUNT == "" ||
          this.loan_info.LOAN_AMOUNT == "null" ||
          this.loan_info.LOAN_AMOUNT == null
        ) {
          Global.showErrorAlert(true, "error", "Loan Amount Is Missing.");
          return false;
        }
        let LOAN_TAX_AMOUNT = this.loan_info.LOAN_AMOUNT;
        let GST_AMOUNT =
          ((LOAN_TAX_AMOUNT -
            this.addmasterdata.ARF_AMOUNT -
            this.addmasterdata.COE_LOG_GUARD -
            this.addmasterdata.ROAD_TAX -
            this.addmasterdata.REGISTRATION_FEE) *
            taxPercentage) /
          parseInt(taxValue);
        let DEDACUTION_AMOUNT =
          LOAN_TAX_AMOUNT -
          this.addmasterdata.ARF_AMOUNT -
          this.addmasterdata.COE_LOG_GUARD -
          this.addmasterdata.ROAD_TAX -
          this.addmasterdata.REGISTRATION_FEE;
        let LOAN_AMOUNT_WITHOUT_TAX = LOAN_TAX_AMOUNT - GST_AMOUNT;
        this.loan_info.LOAN_AMOUNT_WITHOUT_TAX =
          LOAN_AMOUNT_WITHOUT_TAX.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT.toFixed(2);
      } else if (taxType == "NEW_7" && calc_type == "BUYER_DEPOSIT_DETAILS") {
        let DEPOSIT_PAY_AMOUNT =
          this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX;
        let GST_AMOUNT =
          ((DEPOSIT_PAY_AMOUNT -
            this.addmasterdata.ARF_AMOUNT -
            this.addmasterdata.COE_LOG_GUARD -
            this.addmasterdata.ROAD_TAX -
            this.addmasterdata.REGISTRATION_FEE) *
            taxPercentage) /
          parseInt(taxValue);
        let DEDACUTION_AMOUNT =
          DEPOSIT_PAY_AMOUNT -
          this.addmasterdata.ARF_AMOUNT -
          this.addmasterdata.COE_LOG_GUARD -
          this.addmasterdata.ROAD_TAX -
          this.addmasterdata.REGISTRATION_FEE;
        let DEPOSIT_AMOUNT_WITHOUT_GST = DEPOSIT_PAY_AMOUNT - GST_AMOUNT;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITHOUT_TAX =
          DEPOSIT_AMOUNT_WITHOUT_GST.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX = DEPOSIT_PAY_AMOUNT;
      }

      //For NEW Tax Calculation
      //For tax Value 8
      if (taxType == "NEW_8" && calc_type == "SELLER_DETAILS") {
        if (
          this.addupdatesellerdata.PURCHASE_PRICE == "" ||
          this.addupdatesellerdata.PURCHASE_PRICE == "null" ||
          this.addupdatesellerdata.PURCHASE_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        let PRICE_AMOUNT = this.addupdatebuyerdata.SELL_PRICE;
        let PURCHASE_PRICE = this.addupdatesellerdata.PURCHASE_PRICE;
        let GST_AMOUNT =
          ((this.addupdatesellerdata.PURCHASE_PRICE -
            this.addmasterdata.ARF_AMOUNT -
            this.addmasterdata.COE_LOG_GUARD -
            this.addmasterdata.ROAD_TAX -
            this.addmasterdata.REGISTRATION_FEE) *
            taxPercentage) /
          parseInt(taxValue);
        let DEDACUTION_AMOUNT =
          this.addupdatesellerdata.PURCHASE_PRICE -
          this.addmasterdata.ARF_AMOUNT -
          this.addmasterdata.COE_LOG_GUARD -
          this.addmasterdata.ROAD_TAX -
          this.addmasterdata.REGISTRATION_FEE;
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        this.addupdatesellerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT_FIXED;
        this.addupdatesellerdata.PURCHASE_PRICE_SD =
          PURCHASE_PRICE - this.addupdatesellerdata.GST_AMOUNT;
        this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT = "";
        if (this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT == ".undefined")
          this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT = "0";
        this.addupdatesellerdata.TOTAL_PURCHASE_PRICE = PURCHASE_PRICE;
      } else if (taxType == "NEW_8" && calc_type == "BUYER_DETAILS") {
        if (
          this.addupdatebuyerdata.SELL_PRICE == "" ||
          this.addupdatebuyerdata.SELL_PRICE == "null" ||
          this.addupdatebuyerdata.SELL_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        let PRICE_AMOUNT = this.addupdatebuyerdata.SELL_PRICE;
        let PURCHASE_PRICE = this.addupdatesellerdata.PURCHASE_PRICE_SD;
        let GST_AMOUNT =
          ((this.addupdatebuyerdata.SELL_PRICE -
            this.addmasterdata.ARF_AMOUNT -
            this.addmasterdata.COE_LOG_GUARD -
            this.addmasterdata.ROAD_TAX -
            this.addmasterdata.REGISTRATION_FEE) *
            taxPercentage) /
          parseInt(taxValue);
        let DEDACUTION_AMOUNT =
          this.addupdatebuyerdata.SELL_PRICE -
          this.addmasterdata.ARF_AMOUNT -
          this.addmasterdata.COE_LOG_GUARD -
          this.addmasterdata.ROAD_TAX -
          this.addmasterdata.REGISTRATION_FEE;
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        this.addupdatebuyerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.SELLING_PRICE =
          PRICE_AMOUNT - this.addupdatebuyerdata.GST_AMOUNT;
        this.addupdatebuyerdata.NON_TAXABLE_SUPPLIES =
          parseInt(this.addmasterdata.ARF_AMOUNT) +
          parseInt(this.addmasterdata.COE_LOG_GUARD) +
          parseInt(this.addmasterdata.ROAD_TAX) +
          parseInt(this.addmasterdata.REGISTRATION_FEE);
        this.addupdatebuyerdata.TAXABLE_SUPPLIES =
          this.addupdatebuyerdata.SELLING_PRICE -
          this.addupdatebuyerdata.NON_TAXABLE_SUPPLIES;
        this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT = "";
        if (this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT == ".undefined")
          this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT = "0";
        this.addupdatebuyerdata.TOTAL_SELLING_PRICE = PRICE_AMOUNT;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID =
          this.addupdatebuyerdata.TAX_SYS_ID;
        this.globalCalculateGST("BUYER_DEPOSIT_DETAILS");
      } else if (taxType == "NEW_8" && calc_type == "LOAN_INFO") {
        if (
          this.loan_info.LOAN_AMOUNT == "" ||
          this.loan_info.LOAN_AMOUNT == "null" ||
          this.loan_info.LOAN_AMOUNT == null
        ) {
          Global.showErrorAlert(true, "error", "Loan Amount Is Missing.");
          return false;
        }
        let LOAN_TAX_AMOUNT = this.loan_info.LOAN_AMOUNT;
        let GST_AMOUNT =
          ((LOAN_TAX_AMOUNT -
            this.addmasterdata.ARF_AMOUNT -
            this.addmasterdata.COE_LOG_GUARD -
            this.addmasterdata.ROAD_TAX -
            this.addmasterdata.REGISTRATION_FEE) *
            taxPercentage) /
          parseInt(taxValue);
        let DEDACUTION_AMOUNT =
          LOAN_TAX_AMOUNT -
          this.addmasterdata.ARF_AMOUNT -
          this.addmasterdata.COE_LOG_GUARD -
          this.addmasterdata.ROAD_TAX -
          this.addmasterdata.REGISTRATION_FEE;
        let LOAN_AMOUNT_WITHOUT_TAX = LOAN_TAX_AMOUNT - GST_AMOUNT;
        this.loan_info.LOAN_AMOUNT_WITHOUT_TAX =
          LOAN_AMOUNT_WITHOUT_TAX.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT.toFixed(2);
      } else if (taxType == "NEW_8" && calc_type == "BUYER_DEPOSIT_DETAILS") {
        let DEPOSIT_PAY_AMOUNT =
          this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX;
        let GST_AMOUNT =
          ((DEPOSIT_PAY_AMOUNT -
            this.addmasterdata.ARF_AMOUNT -
            this.addmasterdata.COE_LOG_GUARD -
            this.addmasterdata.ROAD_TAX -
            this.addmasterdata.REGISTRATION_FEE) *
            taxPercentage) /
          parseInt(taxValue);
        let DEDACUTION_AMOUNT =
          DEPOSIT_PAY_AMOUNT -
          this.addmasterdata.ARF_AMOUNT -
          this.addmasterdata.COE_LOG_GUARD -
          this.addmasterdata.ROAD_TAX -
          this.addmasterdata.REGISTRATION_FEE;
        let DEPOSIT_AMOUNT_WITHOUT_GST = DEPOSIT_PAY_AMOUNT - GST_AMOUNT;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITHOUT_TAX =
          DEPOSIT_AMOUNT_WITHOUT_GST.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX = DEPOSIT_PAY_AMOUNT;
      }

      //For EXE Tax Calculation
      if (taxType == "EXE" && calc_type == "SELLER_DETAILS") {
        if (
          this.addupdatesellerdata.PURCHASE_PRICE == "" ||
          this.addupdatesellerdata.PURCHASE_PRICE == "null" ||
          this.addupdatesellerdata.PURCHASE_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        let PURCHASE_PRICE = this.addupdatesellerdata.PURCHASE_PRICE;
        let GST_AMOUNT = (PURCHASE_PRICE * taxPercentage) / 100;
        this.addupdatesellerdata.GST_AMOUNT = GST_AMOUNT;
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = GST_AMOUNT;
        this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT = "";
        if (this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT == ".undefined")
          this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT = "0";
        this.addupdatesellerdata.PURCHASE_PRICE_SD = PURCHASE_PRICE;
        this.addupdatesellerdata.TOTAL_PURCHASE_PRICE = PURCHASE_PRICE;
      } else if (taxType == "EXE" && calc_type == "BUYER_DETAILS") {
        if (
          this.addupdatebuyerdata.SELL_PRICE == "" ||
          this.addupdatebuyerdata.SELL_PRICE == "null" ||
          this.addupdatebuyerdata.SELL_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        this.showhide = true;
        let PRICE_AMOUNT = this.addupdatebuyerdata.SELL_PRICE;
        let PURCHASE_PRICE = this.addupdatesellerdata.PURCHASE_PRICE_SD;
        let GST_AMOUNT =
          ((this.addupdatebuyerdata.SELL_PRICE -
            this.addmasterdata.ARF_AMOUNT -
            this.addmasterdata.COE_LOG_GUARD -
            this.addmasterdata.ROAD_TAX -
            this.addmasterdata.REGISTRATION_FEE) *
            taxPercentage) /
          parseInt(taxValue);
        let DEDACUTION_AMOUNT =
          this.addupdatebuyerdata.SELL_PRICE -
          this.addmasterdata.ARF_AMOUNT -
          this.addmasterdata.COE_LOG_GUARD -
          this.addmasterdata.ROAD_TAX -
          this.addmasterdata.REGISTRATION_FEE;
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        this.addupdatebuyerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.SELLING_PRICE =
          PRICE_AMOUNT - this.addupdatebuyerdata.GST_AMOUNT;
        this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT = "";
        if (this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT == ".undefined")
          this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT = "0";
        this.addupdatebuyerdata.TOTAL_SELLING_PRICE = PRICE_AMOUNT;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID =
          this.addupdatebuyerdata.TAX_SYS_ID;
        this.globalCalculateGST("BUYER_DEPOSIT_DETAILS");
      } else if (taxType == "EXE" && calc_type == "LOAN_INFO") {
        if (
          this.loan_info.LOAN_AMOUNT == "" ||
          this.loan_info.LOAN_AMOUNT == "null" ||
          this.loan_info.LOAN_AMOUNT == null
        ) {
          Global.showErrorAlert(true, "error", "Loan Amount Is Missing.");
          return false;
        }
        let LOAN_TAX_AMOUNT = this.loan_info.LOAN_AMOUNT;
        let GST_AMOUNT = (LOAN_TAX_AMOUNT * taxPercentage) / 100;
        let LOAN_AMOUNT_WITHOUT_TAX = LOAN_TAX_AMOUNT - GST_AMOUNT;
        this.loan_info.LOAN_AMOUNT_WITHOUT_TAX =
          LOAN_AMOUNT_WITHOUT_TAX.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT.toFixed(2);
      } else if (taxType == "EXE" && calc_type == "BUYER_DEPOSIT_DETAILS") {
        let DEPOSIT_PAY_AMOUNT =
          this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX;
        let GST_AMOUNT = (DEPOSIT_PAY_AMOUNT * taxPercentage) / 100;
        let DEPOSIT_AMOUNT_WITHOUT_GST = DEPOSIT_PAY_AMOUNT - GST_AMOUNT;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITHOUT_TAX =
          DEPOSIT_AMOUNT_WITHOUT_GST.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX = DEPOSIT_PAY_AMOUNT;
      } else if (taxType == "EXE" && calc_type == "OTHER_PAYMENT_DETAILS") {
        let RATE = this.addotherpayment.UNIT_PRICE;
        this.addotherpayment.RATE_WITHOUT_TAX = 0;
        this.addotherpayment.TAX_VALUE = 0;
        this.addotherpayment.TOTAL_PRICE = this.addotherpayment.UNIT_PRICE;
      } else if (
        taxType == "EXE" &&
        calc_type == "OTHER_PAYMENT_DETAILS_FOR_UPDATE"
      ) {
        let RATE = this.updat_user.UNIT_PRICE;
        this.updat_user.RATE_WITHOUT_TAX = 0;
        this.updat_user.TAX_VALUE = 0;
        this.updat_user.TOTAL_PRICE = this.updat_user.UNIT_PRICE;
      } else if (
        taxType == "EXE" &&
        calc_type == "MANAGE_TAX_INVOICE" &&
        this.addmanagetaxinvoice.UNIT_PRICE != "" &&
        this.addmanagetaxinvoice.QTY != ""
      ) {
        let UNIT_PRICE = this.addmanagetaxinvoice.UNIT_PRICE;
        let QTY = this.addmanagetaxinvoice.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = 0;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.addmanagetaxinvoice.TAX_VALUE = TAX_AMOUNT;
        this.addmanagetaxinvoice.TOTAL_PRICE = TOTAL_PRICE_WITH_TAX;
        this.addmanagetaxinvoice.TAX_PERCENTAGE = taxPercentage;
      } else if (
        taxType == "EXE" &&
        calc_type == "MANAGE_TAX_INVOICE" &&
        this.addmanagetaxinvoice.UNIT_PRICE == "" &&
        this.addmanagetaxinvoice.QTY == ""
      ) {
        this.addmanagetaxinvoice.TAX_VALUE = "";
        this.addmanagetaxinvoice.TOTAL_PRICE = "";
        this.addmanagetaxinvoice.TAX_PERCENTAGE = "";
      } else if (
        taxType == "EXE" &&
        calc_type == "MANAGE_TAX_INVOICE_FOR_UPDATE" &&
        this.updat_user.UNIT_PRICE != "" &&
        this.updat_user.QTY != ""
      ) {
        let UNIT_PRICE = this.updat_user.UNIT_PRICE;
        let QTY = this.updat_user.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = 0;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.updat_user["TAX_VALUE"] = TAX_AMOUNT;
        this.updat_user["TOTAL_AMOUNT"] = TOTAL_PRICE_WITH_TAX;
        this.updat_user["TAX_PERCENTAGE"] = taxPercentage;
      } else if (
        taxType == "EXE" &&
        calc_type == "MANAGE_TAX_INVOICE_FOR_UPDATE" &&
        this.updat_user.UNIT_PRICE == "" &&
        this.updat_user.QTY == ""
      ) {
        this.updat_user["TAX_VALUE"] = "";
        this.updat_user["TOTAL_AMOUNT"] = "";
        this.updat_user["TAX_PERCENTAGE"] = "";
      } else if (
        taxType == "EXE" &&
        calc_type == "MANAGE_CREDIT_NOTE" &&
        this.ManageCreditNoteObj.UNIT_PRICE != "" &&
        this.ManageCreditNoteObj.QTY != ""
      ) {
        let UNIT_PRICE = this.ManageCreditNoteObj.UNIT_PRICE;
        let QTY = this.ManageCreditNoteObj.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = 0;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.ManageCreditNoteObj.UNIT_TOTAL =
          TOTAL_PRICE_WITHOUT_TAX.toFixed(2);
        this.ManageCreditNoteObj.TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        this.ManageCreditNoteObj.AMOUNT = TOTAL_PRICE_WITH_TAX.toFixed(2);
      } else if (
        taxType == "EXE" &&
        calc_type == "MANAGE_CREDIT_NOTE" &&
        this.ManageCreditNoteObj.UNIT_PRICE == "" &&
        this.ManageCreditNoteObj.QTY == ""
      ) {
        this.ManageCreditNoteObj.TAX_AMOUNT = "";
        this.ManageCreditNoteObj.AMOUNT = "";
      } else if (
        taxType == "EXE" &&
        calc_type == "MANAGE_CREDIT_NOTE_FOR_UPDATE" &&
        this.updat_user.UNIT_PRICE != "" &&
        this.updat_user.QTY != ""
      ) {
        let UNIT_PRICE = this.updat_user.UNIT_PRICE;
        let QTY = this.updat_user.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = 0;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.updat_user.UNIT_TOTAL = TOTAL_PRICE_WITHOUT_TAX.toFixed(2);
        this.updat_user.TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        this.updat_user.AMOUNT = TOTAL_PRICE_WITH_TAX.toFixed(2);
      } else if (taxType == "EXE" && calc_type == "COST_CENTER") {
        let AMOUNT_BEF_GST = this.VehicleCostCenterForm.AMOUNT_BEF_GST;
        let TAX_AMOUNT = 0;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(AMOUNT_BEF_GST) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.VehicleCostCenterForm.GST_AMOUNT = TAX_AMOUNT;
        this.VehicleCostCenterForm.TOTAL_AMOUNT = TOTAL_PRICE_WITH_TAX;
      } else if (taxType == "EXE" && calc_type == "COST_CENTER_FOR_UPDATE") {
        let AMOUNT_BEF_GST = this.updat_user.AMOUNT_BEF_GST;
        let TAX_AMOUNT = 0;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(AMOUNT_BEF_GST) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.updat_user.GST_AMOUNT = TAX_AMOUNT;
        this.updat_user.TOTAL_AMOUNT = TOTAL_PRICE_WITH_TAX;
      }

      //For GMS (P) Tax Calculation
      if (taxType == "GMS (P)" && calc_type == "SELLER_DETAILS") {
        if (
          this.addupdatesellerdata.PURCHASE_PRICE == "" ||
          this.addupdatesellerdata.PURCHASE_PRICE == "null" ||
          this.addupdatesellerdata.PURCHASE_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        let PURCHASE_PRICE = this.addupdatesellerdata.PURCHASE_PRICE;
        let GST_AMOUNT = (PURCHASE_PRICE * taxPercentage) / 100;
        this.addupdatesellerdata.GST_AMOUNT = GST_AMOUNT;
        this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = GST_AMOUNT;
        this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT = "";
        if (this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT == ".undefined")
          this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT = "0";
        this.addupdatesellerdata.PURCHASE_PRICE_SD = PURCHASE_PRICE;
        this.addupdatesellerdata.TOTAL_PURCHASE_PRICE = PURCHASE_PRICE;
      } else if (taxType == "GMS (P)" && calc_type == "BUYER_DETAILS") {
        if (
          this.addupdatebuyerdata.SELL_PRICE == "" ||
          this.addupdatebuyerdata.SELL_PRICE == "null" ||
          this.addupdatebuyerdata.SELL_PRICE == null
        ) {
          Global.showErrorAlert(true, "error", "Price Amount Is Missing.");
          return false;
        }
        this.showhide = true;
        let PRICE_AMOUNT = this.addupdatebuyerdata.SELL_PRICE;
        let PURCHASE_PRICE = this.addupdatesellerdata.PURCHASE_PRICE_SD;
        let GST_AMOUNT =
          ((this.addupdatebuyerdata.SELL_PRICE -
            this.addmasterdata.ARF_AMOUNT -
            this.addmasterdata.COE_LOG_GUARD -
            this.addmasterdata.ROAD_TAX -
            this.addmasterdata.REGISTRATION_FEE) *
            taxPercentage) /
          parseInt(taxValue);
        let DEDACUTION_AMOUNT =
          this.addupdatebuyerdata.SELL_PRICE -
          this.addmasterdata.ARF_AMOUNT -
          this.addmasterdata.COE_LOG_GUARD -
          this.addmasterdata.ROAD_TAX -
          this.addmasterdata.REGISTRATION_FEE;
        let GST_AMOUNT_FIXED = GST_AMOUNT.toFixed(2);
        this.addupdatebuyerdata.GST_AMOUNT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = GST_AMOUNT_FIXED;
        this.addupdatebuyerdata.SELLING_PRICE =
          PRICE_AMOUNT - this.addupdatebuyerdata.GST_AMOUNT;
        this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT = "";
        if (this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT == ".undefined")
          this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT = "0";
        this.addupdatebuyerdata.TOTAL_SELLING_PRICE = PRICE_AMOUNT;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID =
          this.addupdatebuyerdata.TAX_SYS_ID;
        this.globalCalculateGST("BUYER_DEPOSIT_DETAILS");
      } else if (taxType == "GMS (P)" && calc_type == "LOAN_INFO") {
        if (
          this.loan_info.LOAN_AMOUNT == "" ||
          this.loan_info.LOAN_AMOUNT == "null" ||
          this.loan_info.LOAN_AMOUNT == null
        ) {
          Global.showErrorAlert(true, "error", "Loan Amount Is Missing.");
          return false;
        }
        let LOAN_TAX_AMOUNT = this.loan_info.LOAN_AMOUNT;
        let GST_AMOUNT = (LOAN_TAX_AMOUNT * taxPercentage) / 100;
        let LOAN_AMOUNT_WITHOUT_TAX = LOAN_TAX_AMOUNT - GST_AMOUNT;
        this.loan_info.LOAN_AMOUNT_WITHOUT_TAX =
          LOAN_AMOUNT_WITHOUT_TAX.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT =
          GST_AMOUNT.toFixed(2);
      } else if (taxType == "GMS (P)" && calc_type == "BUYER_DEPOSIT_DETAILS") {
        let DEPOSIT_PAY_AMOUNT =
          this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX;
        let GST_AMOUNT = (DEPOSIT_PAY_AMOUNT * taxPercentage) / 100;
        let DEPOSIT_AMOUNT_WITHOUT_GST = DEPOSIT_PAY_AMOUNT - GST_AMOUNT;
        this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT = GST_AMOUNT.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITHOUT_TAX =
          DEPOSIT_AMOUNT_WITHOUT_GST.toFixed(2);
        this.addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX = DEPOSIT_PAY_AMOUNT;
      } else if (taxType == "GMS (P)" && calc_type == "OTHER_PAYMENT_DETAILS") {
        let RATE = this.addotherpayment.UNIT_PRICE;
        this.addotherpayment.RATE_WITHOUT_TAX = 0;
        this.addotherpayment.TAX_VALUE = 0;
        this.addotherpayment.TOTAL_PRICE = this.addotherpayment.UNIT_PRICE;
      } else if (
        taxType == "GMS (P)" &&
        calc_type == "OTHER_PAYMENT_DETAILS_FOR_UPDATE"
      ) {
        let RATE = this.updat_user.UNIT_PRICE;
        this.updat_user.RATE_WITHOUT_TAX = 0;
        this.updat_user.TAX_VALUE = 0;
        this.updat_user.TOTAL_PRICE = this.updat_user.UNIT_PRICE;
      } else if (
        taxType == "GMS (P)" &&
        calc_type == "MANAGE_TAX_INVOICE" &&
        this.addmanagetaxinvoice.UNIT_PRICE != "" &&
        this.addmanagetaxinvoice.QTY != ""
      ) {
        let UNIT_PRICE = this.addmanagetaxinvoice.UNIT_PRICE;
        let QTY = this.addmanagetaxinvoice.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = 0;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.addmanagetaxinvoice.TAX_VALUE = TAX_AMOUNT;
        this.addmanagetaxinvoice.TOTAL_PRICE = TOTAL_PRICE_WITH_TAX;
        this.addmanagetaxinvoice.TAX_PERCENTAGE = taxPercentage;
      } else if (
        taxType == "GMS (P)" &&
        calc_type == "MANAGE_TAX_INVOICE" &&
        this.addmanagetaxinvoice.UNIT_PRICE == "" &&
        this.addmanagetaxinvoice.QTY == ""
      ) {
        this.addmanagetaxinvoice.TAX_VALUE = "";
        this.addmanagetaxinvoice.TOTAL_PRICE = "";
        this.addmanagetaxinvoice.TAX_PERCENTAGE = "";
      } else if (
        taxType == "GMS (P)" &&
        calc_type == "MANAGE_TAX_INVOICE_FOR_UPDATE" &&
        this.updat_user.UNIT_PRICE != "" &&
        this.updat_user.QTY != ""
      ) {
        let UNIT_PRICE = this.updat_user.UNIT_PRICE;
        let QTY = this.updat_user.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = 0;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.updat_user["TAX_VALUE"] = TAX_AMOUNT;
        this.updat_user["TOTAL_AMOUNT"] = TOTAL_PRICE_WITH_TAX;
        this.updat_user["TAX_PERCENTAGE"] = taxPercentage;
      } else if (
        taxType == "GMS (P)" &&
        calc_type == "MANAGE_TAX_INVOICE_FOR_UPDATE" &&
        this.updat_user.UNIT_PRICE == "" &&
        this.updat_user.QTY == ""
      ) {
        this.updat_user["TAX_VALUE"] = "";
        this.updat_user["TOTAL_AMOUNT"] = "";
        this.updat_user["TAX_PERCENTAGE"] = "";
      } else if (
        taxType == "GMS (P)" &&
        calc_type == "MANAGE_CREDIT_NOTE" &&
        this.ManageCreditNoteObj.UNIT_PRICE != "" &&
        this.ManageCreditNoteObj.QTY != ""
      ) {
        let UNIT_PRICE = this.ManageCreditNoteObj.UNIT_PRICE;
        let QTY = this.ManageCreditNoteObj.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = 0;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.ManageCreditNoteObj.UNIT_TOTAL =
          TOTAL_PRICE_WITHOUT_TAX.toFixed(2);
        this.ManageCreditNoteObj.TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        this.ManageCreditNoteObj.AMOUNT = TOTAL_PRICE_WITH_TAX.toFixed(2);
      } else if (
        taxType == "GMS (P)" &&
        calc_type == "MANAGE_CREDIT_NOTE" &&
        this.ManageCreditNoteObj.UNIT_PRICE == "" &&
        this.ManageCreditNoteObj.QTY == ""
      ) {
        this.ManageCreditNoteObj.TAX_AMOUNT = "";
        this.ManageCreditNoteObj.AMOUNT = "";
      } else if (
        taxType == "GMS (P)" &&
        calc_type == "MANAGE_CREDIT_NOTE_FOR_UPDATE" &&
        this.updat_user.UNIT_PRICE != "" &&
        this.updat_user.QTY != ""
      ) {
        let UNIT_PRICE = this.updat_user.UNIT_PRICE;
        let QTY = this.updat_user.QTY;
        var TOTAL_PRICE_WITHOUT_TAX = parseInt(UNIT_PRICE) * parseInt(QTY);
        let TAX_AMOUNT = 0;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(TOTAL_PRICE_WITHOUT_TAX) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.updat_user.UNIT_TOTAL = TOTAL_PRICE_WITHOUT_TAX.toFixed(2);
        this.updat_user.TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        this.updat_user.AMOUNT = TOTAL_PRICE_WITH_TAX.toFixed(2);
      } else if (taxType == "GMS (P)" && calc_type == "COST_CENTER") {
        let AMOUNT_BEF_GST = this.VehicleCostCenterForm.AMOUNT_BEF_GST;
        let TAX_AMOUNT = 0;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(AMOUNT_BEF_GST) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.VehicleCostCenterForm.GST_AMOUNT = TAX_AMOUNT;
        this.VehicleCostCenterForm.TOTAL_AMOUNT = TOTAL_PRICE_WITH_TAX;
      } else if (
        taxType == "GMS (P)" &&
        calc_type == "COST_CENTER_FOR_UPDATE"
      ) {
        let AMOUNT_BEF_GST = this.updat_user.AMOUNT_BEF_GST;
        let TAX_AMOUNT = 0;
        let TO_FIXED_TAX_AMOUNT = TAX_AMOUNT.toFixed(2);
        let TOTAL_PRICE_WITH_TAX =
          parseFloat(AMOUNT_BEF_GST) + parseFloat(TO_FIXED_TAX_AMOUNT);
        this.updat_user.GST_AMOUNT = TAX_AMOUNT;
        this.updat_user.TOTAL_AMOUNT = TOTAL_PRICE_WITH_TAX;
      }
    },

    //Function To Calculate Decimal Adjusment For GST
    //Author :
    globalDecimalAdjusment(secType) {
      //For seller details
      if (secType == "SELLER_DETAILS") {
        let GST_DECIMAL_ADJUSTMENT =
          this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT;
        let GST_AMOUNT = this.addupdatesellerdata.GST_AMOUNT;
        if (GST_DECIMAL_ADJUSTMENT == 1) {
          let CEIL_GST_AMOUNT = Math.ceil(GST_AMOUNT);
          this.addupdatesellerdata.GST_AMOUNT = CEIL_GST_AMOUNT;
          let SELLING_PRICE =
            this.addupdatesellerdata.PURCHASE_PRICE - CEIL_GST_AMOUNT;
          this.addupdatesellerdata.PURCHASE_PRICE_SD = SELLING_PRICE.toFixed(2);
          this.addupdatesellerdata.TOTAL_PURCHASE_PRICE =
            parseFloat(CEIL_GST_AMOUNT) + parseFloat(SELLING_PRICE);
        } else if (GST_DECIMAL_ADJUSTMENT == -1) {
          let FLOOR_GST_AMOUNT = Math.floor(GST_AMOUNT);
          this.addupdatesellerdata.GST_AMOUNT = FLOOR_GST_AMOUNT;
          let SELLING_PRICE =
            this.addupdatesellerdata.PURCHASE_PRICE - FLOOR_GST_AMOUNT;
          this.addupdatesellerdata.PURCHASE_PRICE_SD = SELLING_PRICE.toFixed(2);
          this.addupdatesellerdata.TOTAL_PURCHASE_PRICE =
            parseFloat(FLOOR_GST_AMOUNT) + parseFloat(SELLING_PRICE);
        } else if (GST_DECIMAL_ADJUSTMENT == "") {
          this.addupdatesellerdata.GST_AMOUNT =
            this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT;
          this.addupdatesellerdata.PURCHASE_PRICE_SD =
            parseFloat(this.addupdatesellerdata.PURCHASE_PRICE) -
            parseFloat(this.addupdatesellerdata.GST_AMOUNT);
          this.addupdatesellerdata.TOTAL_PURCHASE_PRICE =
            parseFloat(this.addupdatesellerdata.GST_AMOUNT) +
            parseFloat(this.addupdatesellerdata.PURCHASE_PRICE_SD);
        }
      }

      //For buyer details
      if (secType == "BUYER_DETAILS") {
        let GST_DECIMAL_ADJUSTMENT =
          this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT;
        let GST_AMOUNT = this.addupdatebuyerdata.GST_AMOUNT;
        if (GST_DECIMAL_ADJUSTMENT == 1) {
          let CEIL_GST_AMOUNT = Math.ceil(GST_AMOUNT);
          this.addupdatebuyerdata.GST_AMOUNT = CEIL_GST_AMOUNT;
          let SELLING_PRICE =
            this.addupdatebuyerdata.SELL_PRICE - CEIL_GST_AMOUNT;
          this.addupdatebuyerdata.SELLING_PRICE = SELLING_PRICE.toFixed(2);
          this.addupdatebuyerdata.TOTAL_SELLING_PRICE =
            parseFloat(CEIL_GST_AMOUNT) + parseFloat(SELLING_PRICE);
        } else if (GST_DECIMAL_ADJUSTMENT == -1) {
          let FLOOR_GST_AMOUNT = Math.floor(GST_AMOUNT);
          this.addupdatebuyerdata.GST_AMOUNT = FLOOR_GST_AMOUNT;
          let SELLING_PRICE =
            this.addupdatebuyerdata.SELL_PRICE - FLOOR_GST_AMOUNT;
          this.addupdatebuyerdata.SELLING_PRICE = SELLING_PRICE.toFixed(2);
          this.addupdatebuyerdata.TOTAL_SELLING_PRICE =
            parseFloat(FLOOR_GST_AMOUNT) + parseFloat(SELLING_PRICE);
        } else if (GST_DECIMAL_ADJUSTMENT == "") {
          this.addupdatebuyerdata.GST_AMOUNT =
            this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT;
          this.addupdatebuyerdata.SELLING_PRICE =
            parseFloat(this.addupdatebuyerdata.SELL_PRICE) -
            parseFloat(this.addupdatebuyerdata.GST_AMOUNT);
          this.addupdatebuyerdata.TOTAL_SELLING_PRICE =
            parseFloat(this.addupdatebuyerdata.GST_AMOUNT) +
            parseFloat(this.addupdatebuyerdata.SELLING_PRICE);
        }
      }

      //For loan info
      if (secType == "LOAN_INFO") {
        let LOAN_TAX_DECIMAL_ADJUSTMENT =
          this.loan_info.LOAN_TAX_DECIMAL_ADJUSTMENT;
        let LOAN_TAX_AMOUNT = this.loan_info.LOAN_TAX_AMOUNT;
        if (LOAN_TAX_DECIMAL_ADJUSTMENT == 1) {
          let CEIL_TAX_AMOUNT = Math.ceil(LOAN_TAX_AMOUNT);
          this.loan_info.LOAN_TAX_AMOUNT = CEIL_TAX_AMOUNT;
          let LOAN_AMOUNT_WITHOUT_TAX =
            this.loan_info.LOAN_AMOUNT - CEIL_TAX_AMOUNT;
          this.loan_info.LOAN_AMOUNT_WITHOUT_TAX =
            LOAN_AMOUNT_WITHOUT_TAX.toFixed(2);
        } else if (LOAN_TAX_DECIMAL_ADJUSTMENT == -1) {
          let FLOOR_TAX_AMOUNT = Math.floor(LOAN_TAX_AMOUNT);
          this.loan_info.LOAN_TAX_AMOUNT = FLOOR_TAX_AMOUNT;
          let LOAN_AMOUNT_WITHOUT_TAX =
            this.loan_info.LOAN_AMOUNT - FLOOR_TAX_AMOUNT;
          this.loan_info.LOAN_AMOUNT_WITHOUT_TAX =
            LOAN_AMOUNT_WITHOUT_TAX.toFixed(2);
        } else if (LOAN_TAX_DECIMAL_ADJUSTMENT == "") {
          this.loan_info.LOAN_TAX_AMOUNT =
            this.loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT;
          this.loan_info.LOAN_AMOUNT_WITHOUT_TAX =
            parseFloat(this.loan_info.LOAN_AMOUNT) -
            parseFloat(this.loan_info.LOAN_TAX_AMOUNT);
        }
      }

      //For loan info advance payment
      if (secType == "LOAN_INFO_ADVANCE_PAYMENT") {
        let ADVANCE_PAYMENT_DECIMAL_ADJUSTMENT =
          this.loan_info.ADVANCE_PAYMENT_DECIMAL_ADJUSTMENT;
        let ADVANCE_PAYMENT = this.loan_info.ADVANCE_PAYMENT;
        if (ADVANCE_PAYMENT_DECIMAL_ADJUSTMENT == 1) {
          let CEIL_ADVANCE_PAYMENT = Math.ceil(ADVANCE_PAYMENT);
          this.loan_info.ADVANCE_PAYMENT = CEIL_ADVANCE_PAYMENT;
        } else if (ADVANCE_PAYMENT_DECIMAL_ADJUSTMENT == -1) {
          let FLOOR_ADVANCE_PAYMENT = Math.floor(ADVANCE_PAYMENT);
          this.loan_info.ADVANCE_PAYMENT = FLOOR_ADVANCE_PAYMENT;
        } else if (ADVANCE_PAYMENT_DECIMAL_ADJUSTMENT == "") {
          this.loan_info.ADVANCE_PAYMENT =
            this.loan_info.ADVANCE_PAYMENT_BEFORE_ADJUSTMENT;
        }
      }
    },

    //Function To Get Tax Info details
    //Author :
    getTaxInf() {
      this.axios
        .get(apiEndPoints.tax_info, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.tax_info_detai = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.tax_info_detai = [];
          }
        })
        .catch((err) => {
          // //console.log(err);
        });
    },

    getTaxIn() {
      this.axios
        .get(apiEndPoints.tax_info, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.tax_info_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.tax_info_details = [];
          }
        })
        .catch((err) => {
          ////console.log(err);
        });
    },

    //Function To Add Vendor Details
    //Author :
    Addvendor() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_vendor["CREATED_BY"] = user_details.UserId;
      this.$refs.bankform2.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          NProgress.start();
          this.loading = true;
          this.axios
            .post(apiEndPoints.create_update_vendor, this.create_vendor)
            .then((response) => {
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                //this.getvendordetails();
                this.onChangeCostCenterToFrom();
                this.GetAllVendorDetails();
                this.onChnageBuyerTypeFun();
                this.onChnageBuyerType();
                this.onChnageSellerTypeFun();
                
                this.resetAddVendorForm();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })
            .catch((err) => {
              ////console.log(err);
            })
            .finally(() => {
              NProgress.done();
              this.loading = false;
            });
        }
      });
    },

    //Function To Reset Add Vendor Details Form
    //Author :
    resetAddVendorForm() {
      $("#vendor_modal").modall("hide");
      this.create_vendor.ADDRESS_LINE_1 = "";
      this.create_vendor.VENDOR_NAME = "";
      this.create_vendor.VENDOR_CODE = "";
      this.create_vendor.MOBILE_NO = "";
      this.create_vendor.PIN_CODE = "";
      this.create_vendor.GENDER = "";
      this.create_vendor.EMAIL_ID = "";
    },

    //Function To Add Company Type Customer
    //Author : Prasanta Saren
    Addcompany() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_company["CREATED_BY"] = user_details.UserId;
      this.create_company["CUSTOMER_TYPE"] = this.create_customer.CUSTOMER_TYPE;
      this.$refs.bankfo.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          NProgress.start();
          NProgress.set(0.1);
          this.loading = true;
          this.axios
            .post(apiEndPoints.create_update_customer, this.create_company)
            .then((response) => {
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.resetAddCustomerForm();
                this.onChnageSellerTypeFun();
                this.getCustomer();
                this.GetAllCustomerInfo();
                this.onChnageBuyerTypeFun();
                this.onChnageBuyerType();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })
            .catch((err) => {
              ////console.log(err);
            })
            .finally(() => {
              NProgress.done();
              this.loading = false;
            });
        }
      });
    },

    //Function To Add Individual Type Customer
    //Author : Prasanta Saren
    Addcustomer() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_customer["CREATED_BY"] = user_details.UserId;
      this.$refs.cus.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          NProgress.start();
          NProgress.set(0.1);
          this.loading = true;
          this.axios
            .post(apiEndPoints.create_update_customer, this.create_customer)
            .then((response) => {
              ////console.log(response);
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.resetAddCustomerForm();
                this.getCustomer();
                this.onChnageSellerTypeFun();
                this.GetAllCustomerInfo();
                this.onChnageBuyerTypeFun();
                this.onChnageBuyerType();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })
            .catch((err) => {
              ////console.log(err);
            })
            .finally(() => {
              NProgress.done();
              this.loading = false;
            });
        }
      });
    },

    //Function To Reset Add Customer Details Form
    //Author : Prasanta Saren
    resetAddCustomerForm() {
      $("#employee_modal").modal("hide");
      this.create_company.ADDRESS_LINE_1 = "";
      this.create_company.PERSON_INCHARGE = "";
      this.create_company.CUSTOMER_CONTACT = "";
      this.create_company.PIN_CODE = "";
      this.create_company.ID_NUMBER = "";
      this.create_company.CUSTOMER_TYPE = "";
      this.create_company.CUSTOMER_NAME = "";
      this.create_company.GMAIL_ID = "";

      this.create_customer.ADDRESS_LINE_1 = "";
      this.create_customer.PERSON_INCHARGE = "";
      this.create_customer.CUSTOMER_CONTACT = "";
      this.create_customer.PIN_CODE = "";
      this.create_customer.ID_NUMBER = "";
      this.create_customer.CUSTOMER_TYPE = "";
      this.create_customer.CUSTOMER_NAME = "";
      this.create_customer.GMAIL_ID = "";
    },

    //Function To Get Bank List
    //Author : Prasanta Saren
    getBanklist() {
      this.axios
        .get(apiEndPoints.bank_name_api, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.Banklist = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.Banklist = [];
          }
        })
        .catch((err) => {
          ////console.log(err);
        });
    },

    //Function To Get All Customer Info
    //Author : Prasanta Saren
    GetAllCustomerInfo() {
      this.axios
        .get(apiEndPoints.view_customer, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
            PAGE_SIZE: 1000,
            PAGE_NO: 100,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.AllCustomerArray = response.data.response;
          }
        })
        .catch((err) => {
          ////console.log(err);
        });
    },

    //Function To Get All Vendor Info
    //Author : Prasanta Saren
    GetAllVendorDetails() {
      this.axios
        .get(apiEndPoints.view_vendor, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
            PAGE_SIZE: 100,
            PAGE_NO: 10,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.AllVendordetails = response.data.response;
          } else {
            this.AllVendordetails = "";
          }
        })
        .catch((err) => {
          ////console.log(err);
          this.AllVendordetails = "";
        });
    },

    //Function To Get Vehicle Details List
    //Author :
    getvehicledetails(page_no = 1) {
      NProgress.start();
      NProgress.set(0.1);
      this.loading_landing_page = true;
      this.statusvalue = "";
      this.numbervalue = "";
      this.makevalue = "";
      this.modelvalue = "";
      if (this.search_option == "all") {
        this.statusvalue = this.STATUS;
        this.numbervalue = this.VEHICLENUMBER;
        this.makevalue = this.MAKENAME;
        this.modelvalue = this.MODELNAME;
      } else {
        if (this.search_option == "status") this.statusvalue = this.STATUS;
        if (this.search_option == "number")
          this.numbervalue = this.VEHICLENUMBER;
        if (this.search_option == "make") this.makevalue = this.MAKENAME;
        if (this.search_option == "model") this.modelvalue = this.MODELNAME;
      }

      //SEARCH_ETS_VEHICLE
      this.axios
        .get(apiEndPoints.view_vehicle_details, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
            VEHICLE_STATUS: this.statusvalue,
            VEHICLE_NUMBER: this.numbervalue,
            VEHICLE_MAKE: this.makevalue,
            VEHICLE_MODEL: this.modelvalue,
            PAGE_SIZE: 20,
            PAGE_NO: page_no,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            if (page_no == 1) {
              this.vehicledetails = [];
           
            }
            this.vehicledetails = [
              ...this.vehicledetails,
              ...response.data.response,
            ];
            this.vehicledetails_page_no += 1;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            if (page_no == 1) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          }
        })
        .catch((err) => {
          ////console.log(err);
        })
        .finally(() => {
          NProgress.done();
          this.loading_landing_page = false;
        });
    },

    //Function To Delete Vehicle Details
    //Author :
    async deleteVehicleDetails(obj) {
      const result = await Global.showConfirmationAlert(
        `Delete  ${obj.MAKE_NAME}`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
        NProgress.start();
        NProgress.set(0.1);
        this.loading_landing_page = true;
        let postarrydata = {
          ITEM: "DELETE",
          RECORD_SYS_ID: obj.RECORD_SYS_ID,
        };
        this.axios
          .post(apiEndPoints.add_vehicle_details, postarrydata)
          .then((response) => {
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getvehicledetails();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          })
          .finally(() => {
            NProgress.done();
            this.loading_landing_page = false;
          });
      }
    },

    //Function To Load More Vehicle Details
    //Author :
    loadVehicleDetails(isVisible) {
      if (isVisible) {
        this.getvehicledetails(this.vehicledetails_page_no);
      }
    },

    //Function To Click Vehicle Details Row
    //Author : Prasanta Saren
    clickVehicleDetailsRow(vehicle_detail) {
      console.log(vehicle_detail);
      if(vehicle_detail.IS_EDIT_ALLOWED == 1)
      this.admin_edit_modal_show = true;
      else
      this.admin_view_modal_show = true;
      this.current_vehicle_detail = vehicle_detail;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      this.GLOBAL_RECORD_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.LOCAL_VEHICLE_NUMBER = vehicle_detail.VEHICLE_NUMBER;
      this.LOCAL_RECORD_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.floorstock.VEHICLE_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.loan_info.RECORD_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.addmanagetaxinvoice.VEHICLE_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.VehicleCostCenterForm.VEHICLE_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.VehicleCostCenterForm.VEHICLE_NUMBER = vehicle_detail.VEHICLE_NUMBER;
      this.addupdatesellerapdata.RECORD_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.addupdatebuyerapdata.RECORD_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.addupdatesellerdata["RECORD_SYS_ID"] = vehicle_detail.RECORD_SYS_ID;
      this.RECORD_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.addcreditnote.RECORD_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.addcreditnote.VEHICLE_NUMBER = vehicle_detail.VEHICLE_NUMBER;
      this.create_user.VEHICLE_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.taxinvoicedata.VEHICLE_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.financetaxinvoicedata.VEHICLE_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.purchasesettlement.VEHICLE_SYS_ID = vehicle_detail.RECORD_SYS_ID;

      //Stock Record
      this.getVehicleStockRecordDetails(vehicle_detail);
    
      this.onchangeVehicleStatus();
      //Seller Details
      this.getSellerDetails(vehicle_detail.RECORD_SYS_ID);
      this.getsellerAPdetails(vehicle_detail.RECORD_SYS_ID);
      //Buyer Details
      this.getBuyerDetails(vehicle_detail.RECORD_SYS_ID);
      this.getBuyerARPaymentDetails(vehicle_detail.RECORD_SYS_ID);
      this.getETSTransactionDetails(vehicle_detail.RECORD_SYS_ID);
      //Insurance Info
      this.getInsuranceCompanyDetails(vehicle_detail.RECORD_SYS_ID);
      //Loan Info
      this.getVechileLoanDetails(vehicle_detail.RECORD_SYS_ID);
      //Tax Invoice
      this.getManageTaxInvoiceDetails();
      //Credit Note
      this.getManageCreditNoteDetails();
      //Cost Center
      this.getCostCenterDetailsList(vehicle_detail.RECORD_SYS_ID);
      this.getCostCenterCreatedPurchaseList(vehicle_detail.RECORD_SYS_ID);
      this.getCostCenterCreatedSalesList(vehicle_detail.RECORD_SYS_ID);
      this.getFloorStockInterestDetails()
      this.getInvoiceDetailsWithTax()
      // this.getVechileTradeDetatils(vehicle_detail.RECORD_SYS_ID);
      // this.getDebitNoteDetails();
      // this.getFloorStockInterestDetails();
      // this.GetAllVendorDetails();
      // this.getInsuranceInfo(vehicle_detail.RECORD_SYS_ID);
      // this.getCreditNoteDetails(vehicle_detail);
      // this.emptyflllu();
      // this.getPriceDetail();
      // this.getSalesReceiptNoteDetails();
      // this.getInsuranceCompany(vehicle_detail.RECORD_SYS_ID);
      // this.getVehicleCostCreatedDetailsList(vehicle_detail.RECORD_SYS_ID);
      // this.getvendordetai(vehicle_detail.RECORD_SYS_ID);
      
      // this.calculateNoOfDaysInFoorStock();
    },

    //For Admin Master Update
    //-----------------------------------For Stock Record Section Start------------------------------
    //Function To Onchange Vehicle Status
    //Author :
    onchangeVehicleStatus() {
      if (
        this.addmasterdata.VEHICLE_STATUS == "ETS" ||
        this.addmasterdata.VEHICLE_STATUS == "Pre Owned"
      )
        this.vehicletype = "Old Vehicle";
      else if (
        this.addmasterdata.VEHICLE_STATUS == "New Vehicle" ||
        this.addmasterdata.VEHICLE_STATUS == "Pre Order"
      )
        this.vehicletype = "New Vehicle";
      else if (
        this.addmasterdata.VEHICLE_STATUS == "Scrap" ||
        this.addmasterdata.VEHICLE_STATUS == "Trade-in"
      )
        this.vehicletype = "Old Vehicle";
      else if (
        this.addmasterdata.VEHICLE_STATUS == "Ordered" ||
        this.addmasterdata.VEHICLE_STATUS == "Local"
      )
        this.vehicletype = "Old Vehicle";
      if (this.addmasterdata.VEHICLE_STATUS == "ETS")
        this.etsfromdisable = true;
    },

    //Function to get vehicle locations
    //Author :
    getvehiclelocation() {
      this.axios
        .get(apiEndPoints.view_vehicle_location, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          this.Vehiclelocations = response.data.response;
        })
        .catch((err) => {
          ////console.log(err);
        });
    },

    //Function to get Incharge by list
    //Author :
    getBrokerArrayDetails() {
      this.axios
        .get(apiEndPoints.view_user, {
          params: {
            ITEM: "SPECIFIC",
            USER_TYPE: "EXTERNAL",
            PAGE_SIZE: 100,
            PAGE_NO: 100,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.brokerDetailsArray = response.data.response;
          }
        })
        .catch((err) => {
          ////console.log(err);
        });
    },

    //Function To Get Vehicle Manufracturer List
    //Author :
    getManufracturename() {
      this.axios
        .get(apiEndPoints.view_vehicle_manufacture_name, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          this.Manufracturename = response.data.response;
        })
        .catch((err) => {
          ////console.log(err);
        });
    },

    //Function To Get Vehicle Model Type
    //Author :
    getmodeltype_() {
      this.axios
        .get(apiEndPoints.view_vehicle_model_type, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.addmasterdata.MAKE_SYS_ID,
          },
        })
        .then((response) => {
          this.Modeltype_ = response.data.response;
        })
        .catch((err) => {
          ////console.log(err);
        });
    },

    //Function To Get Vehicle Body Type
    //Author :
    getbodytype() {
      this.axios
        .get(apiEndPoints.view_vehicle_body_type, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          this.Bodytype = response.data.response;
        })
        .catch((err) => {
          ////console.log(err);
        });
    },

    //Function To Get Vehicle Stock Record Details
    //Author :
    getVehicleStockRecordDetails(vehicle_detail) {
      this.axios
        .get(apiEndPoints.view_vehicle_details_for_edit, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: vehicle_detail.RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.addmasterdata = response.data.response[0];
            this.addmasterdata.TEMPORARY_VEHICLE_NO =
              response.data.response[0].TEMPORARY_VEHICLE_NUMBER;
              this.ENGINE_NO_NEW = response.data.response[0].ENGINE_NO ||"-";
              this.BODY_TYPE_NEW = response.data.response[0].BODY_TYPE ||"-";
              this.VEHICLE_NUMBER_NEW = response.data.response[0].VEHICLE_NUMBER ||"-";
              this.PROPELLANT_NEW=response.data.response[0].PROPELLANT ||"-";
              this.VEHICLE_NUMBER_NEW = response.data.response[0].VEHICLE_NUMBER ||"-";
              this.MAKE_NAME=response.data.response[0].MAKE_NAME ||"-";
              this.MODEL_NAME=response.data.response[0].MODEL_NAME ||"-";
         this.USE_TCOE=response.data.response[0].USE_TCOE ||"-";
              this.ORIGINAL_REGISTRATION_DATE=response.data.response[0].ORIGINAL_REGISTRATION_DATE ||"-";
              this.COLOR_NAME_NEW=response.data.response[0].COLOR_NAME ||"-";
              this.CHASIS_NO_NEW=response.data.response[0].CHASIS_NO ||"-";
              this.TEMPORARY_VEHICLE_NUMBER=response.data.response[0].TEMPORARY_VEHICLE_NUMBER ||"-";
              this.ROAD_TAX_EXPIRY_DATE=response.data.response[0].ROAD_TAX_EXPIRY_DATE ||"-";
              this.INSPECTION_EXPIRY_DATE=response.data.response[0].INSPECTION_EXPIRY_DATE ||"-";
              this.LAYUP_EXP_DATE=response.data.response[0].LAYUP_EXP_DATE ||"-";
              this.ASKING_PRICE=response.data.response[0].ASKING_PRICE ||"-";
              this.COE_LOG_GUARD=response.data.response[0].COE_LOG_GUARD || "-";
              this.COE_ACC_DPQP=response.data.response[0].COE_ACC_DPQP || "-";
              this.COE_NUMBER=response.data.response[0].COE_NUMBER || "-";
              this.COE_EXPIRY_DATE=response.data.response[0].COE_EXPIRY_DATE || "-";
              this.OMV_E_TSF=response.data.response[0].OMV_E_TSF || "-";
              this.ETS_PAPER_FROM=response.data.response[0].ETS_PAPER_FROM || "-";
              this.USE_TCOE=response.data.response[0].USE_TCOE || "-";


            this.addmasterdata.ADDITIONAL_VEHICLE_NUMBER =
              response.data.response[0].ADDITIONAL_VEHICLE_NUMBER;
            this.RECORD_SYS_ID = response.data.response[0].VEHICLE_NUMBER;
            this.addmasterdata.TSF_NUMBER = response.data.response[0].OMV_E_TSF;
            this.onchangeVehicleStatus();
            this.addupdatebuyerdata.BROKER_1_SYS_ID =
              response.data.response[0].INCHARGE_BY_BROKER_SYS_ID;
            this.addupdatesellerdata.BROKER_1 =
              response.data.response[0].INCHARGE_BY_BROKER_SYS_ID;
            this.addmasterdata.MODEL_SHORT_NAME =
              this.current_vehicle_detail.MODEL_SHORT_NAME;
          } else this.addmasterdata = vehicle_detail;
        })
        .catch((err) => {
          this.addmasterdata = vehicle_detail;
          ////console.log(err);
        })
        .finally(() => {
          

          let outputsObj = {};
          var obj = this.addmasterdata;
          Object.keys(obj).forEach(function (key, el) {
            if (
              obj[key] == null ||
              obj[key] == "null" ||
              obj[key] == "0" ||
              obj[key] == 0
            )
              outputsObj[key] = "";
            else outputsObj[key] = obj[key];
          });
          this.addmasterdata = outputsObj;
          if (this.addmasterdata.PRICE_LIST_STATUS == "N")
            this.addmasterdata.PRICE_LIST_STATUS = true;
          else if (this.addmasterdata.PRICE_LIST_STATUS == "Y")
            this.addmasterdata.PRICE_LIST_STATUS = false;
        });
    },

    //Function To Get Make Name
    //Author :
    getmakeName() {
      this.addmasterdata.MAKE_NAME = this.Manufracturename.find(
        (o) => o.VEHICLE_MAKE_SYS_ID === this.addmasterdata.MAKE_SYS_ID
      ).VEHICLE_MAKE;
      this.getmodeltype_();
    },

    //Function To Get Vehicle Model Short Name
    //Author :
    getmodelshortName() {
      this.addmasterdata.MODEL_SHORT_NAME = this.Modeltype_.find(
        (o) => o.VEHICLE_MODEL_SYS_ID === this.addmasterdata.MODEL_SYS_ID
      ).MODEL_SHORT_NAME;
      this.addmasterdata.MODEL_NAME = this.Modeltype_.find(
        (o) => o.VEHICLE_MODEL_SYS_ID === this.addmasterdata.MODEL_SYS_ID
      ).VEHICLE_MODEL;
    },

    //Function To Get Vehicle Body Type
    //Author :
    getbodyName() {
      this.addmasterdata.BODY_TYPE = this.Bodytype.find(
        (o) =>
          o.VEHICLE_BODY_TYPE_SYS_ID === this.addmasterdata.BODY_TYPE_SYS_ID
      ).VEHICLE_BODY_TYPE;
    },

    //Function To Get Vehicle Colour List
    //Author :
    getcolour() {
      this.axios
        .get(apiEndPoints.view_vehicle_color_name, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          this.Coloursname = response.data.response;
        })
        .catch((err) => {
          ////console.log(err);
        });
    },

    //Function To Calculate Vehicle Engine Ton Value
    //Author :
    onKeyUpCalculateTon() {
      if (this.addmasterdata.LADEN && this.addmasterdata.UNLADEN) {
        let Ton = this.addmasterdata.LADEN - this.addmasterdata.UNLADEN;
        this.addmasterdata.ENGINE_TON = Ton;
      }
    },

    //Function To Get Vehicle Colour Name
    //Author :
    getcolorName() {
      this.addmasterdata.COLOR_NAME = this.Coloursname.find(
        (o) => o.VEHICLE_COLOR_SYS_ID === this.addmasterdata.COLOR_SYS_ID
      ).VEHICLE_COLOR;
    },

    //Function To Onchange Price List Status
    //Author : Prasanta Saren
    onChangePriceListStatus(){
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      if (this.addmasterdata.PRICE_LIST_STATUS == true) this.addmasterdata["PRICE_LIST_STATUS"] = "N";
      else if (this.addmasterdata.PRICE_LIST_STATUS == false) this.addmasterdata["PRICE_LIST_STATUS"] = "Y";
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let apiParams = {
        ITEM : "PRICE_LIST_STATUS",
        PRICE_LIST_STATUS: this.addmasterdata.PRICE_LIST_STATUS,
        RECORD_SYS_ID: this.addmasterdata.RECORD_SYS_ID,
        VEHICLE_NUMBER: this.addmasterdata.VEHICLE_NUMBER,
        CREATED_BY: user_details.UserId
      }
      this.axios.post(apiEndPoints.add_vehicle_details, apiParams).then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getVehicleStockRecordDetails(this.addmasterdata);
          } 
          else if (response.data.status == false || response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
          }
      })
      .catch((err) => {
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
      })
      .finally(() => {
          NProgress.done();
          this.loading = false;
      });
    },

    //Function To Update Vehicle Stock Record Left Form Details
    //Author : Prasanta Saren
    updateVehicleStockRecordLeftFormDetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addmasterdata["CREATED_BY"] = user_details.UserId;
      this.addmasterdata["ITEM"] = "UPDATE";
      if (this.addmasterdata.PRICE_LIST_STATUS == true)
        this.addmasterdata["PRICE_LIST_STATUS"] = "N";
      else if (this.addmasterdata.PRICE_LIST_STATUS == false)
        this.addmasterdata["PRICE_LIST_STATUS"] = "Y";
      this.axios
        .post(apiEndPoints.add_vehicle_details, this.addmasterdata)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getVehicleStockRecordDetails(this.addmasterdata);
            this.getvehicledetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Vehicle Stock Record Right Form Null Value Change
    //Author : Prasanta Saren
    stockRecordRightFormNullValueChange() {
      if (this.addmasterdata.LAYUP_AMOUNT == "" || null)
        this.addmasterdata.LAYUP_AMOUNT = "0";
      if (this.addmasterdata.ROAD_TAX == "" || null)
        this.addmasterdata.ROAD_TAX = "0";
      if (this.addmasterdata.COE_ACC_DPQP == "" || null)
        this.addmasterdata.COE_ACC_DPQP = "0";
      if (this.addmasterdata.COE_NUMBER == "" || null)
        this.addmasterdata.COE_NUMBER = "0";
      if (this.addmasterdata.OPEN_MARKET_VALUE == "" || null)
        this.addmasterdata.OPEN_MARKET_VALUE = "0";
      if (this.addmasterdata.ARF_AMOUNT == "" || null)
        this.addmasterdata.ARF_AMOUNT = "0";
      if (this.addmasterdata.CEVS_SURCHARGE == "" || null)
        this.addmasterdata.CEVS_SURCHARGE = "0";
      if (this.addmasterdata.CEVS_REBATE == "" || null)
        this.addmasterdata.CEVS_REBATE = "0";
    },

    //Function To Update Vehicle Stock Record Right Form Details
    //Author : Prasanta Saren
    updateVehicleStockRecordRightFormDetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      if (
        this.addmasterdata.REGISTRATION_FEE == 0 &&
        this.vehicletype == "New Vehicle"
      ) {
        Global.showErrorAlert(true, "error", "Registration fee is required!");
        NProgress.done();
        this.loading = false;
        return false;
      } else if (
        this.addmasterdata.REGISTRATION_FEE == null &&
        this.vehicletype == "New Vehicle"
      ) {
        Global.showErrorAlert(true, "error", "Registration fee is required!");
        NProgress.done();
        this.loading = false;
        return false;
      } else if (
        this.addmasterdata.REGISTRATION_FEE == "" &&
        this.vehicletype == "New Vehicle"
      ) {
        Global.showErrorAlert(true, "error", "Registration fee is required!");
        NProgress.done();
        this.loading = false;
        return false;
      }
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addmasterdata["CREATED_BY"] = user_details.UserId;
      this.addmasterdata["LAYUP_AMOUNT"] = this.addmasterdata.LAYUP_AMOUNT || 0;
      this.addmasterdata["ITEM"] = "ADD";
      this.stockRecordRightFormNullValueChange();
      this.axios
        .post(apiEndPoints.add_update_record_section, this.addmasterdata)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getVehicleStockRecordDetails(this.addmasterdata);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Auto Populate Purchase Price In COE(ACC)/ DPQP($)
    //Author :
    onChnageETSPaperFrom() {
      let VEHICLE_SYS_ID = this.search_all_vehicle_details.find(
        (items) => items.VEHICLE_NUMBER == this.addmasterdata.ETS_PAPER_FROM
      ).RECORD_SYS_ID;
      this.axios
        .get(apiEndPoints.view_vehicle_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: VEHICLE_SYS_ID,
            VEHICLE_STATUS: "",
            VEHICLE_NUMBER: this.addmasterdata.ETS_PAPER_FROM,
            VEHICLE_MAKE: "",
            VEHICLE_MODEL: "",
            PAGE_SIZE: 1,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            let api_response = response.data.response[0];
            this.addmasterdata.COE_ACC_DPQP =
              api_response.TOTAL_PURCHASE_AMOUNT;
          }
        })
        .catch((err) => {
          ////console.log(err);
        });
    },
    //-------------------------------For Stock Record Section End------------------------------

    //-------------------------------For Seller Details Section Start----------------------------
    //Function To Onchange Seller Type
    //Author : Prasanta Saren
    onChnageSellerTypeFun() {
      let SellerType = this.addupdatesellerdata.CUSTOMER_TYPE;
      if (SellerType == "Vendor") {
        this.SELLER_CUSTOMER_INFO = "";
        this.DisplaySellerCustomerType = "";
        this.DisplaySellerCustomerIdNumber = "";
        this.DisplaySellerCustomerName = "";
        this.DisplaySellerCustomerAddress = "";
        this.axios
          .get(apiEndPoints.view_vendor, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 50,
              PAGE_NO: 10,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllSellerVendorDetailsArray = response.data.response;
            } else {
              this.AllSellerVendorDetailsArray = "";
            }
          })
          .catch((err) => {
            this.AllSellerVendorDetailsArray = "";
          });
      } else if (SellerType == "Customer") {
        this.SELLER_VENDOR_INFO = "";
        this.DisplaySellerVendorName = "";
        this.DisplaySellerVendorCode = "";
        this.DisplaySellerVendorAddress = "";
        this.axios
          .get(apiEndPoints.view_customer, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 20,
              PAGE_NO: 1,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllSellerCustomerDetailsArray = response.data.response;
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    },

    //Function To OnChange Seller Vendor Info
    //Author : Prasanta Saren
    onChnageSellerVendorInfo() {
      let vendorName = this.SELLER_VENDOR_INFO;
      let vendorObj = this.AllSellerVendorDetailsArray.find(
        (items) => items.VENDOR_NAME == vendorName
      );
      if (vendorObj) {
        this.addupdatesellerdata.CUSTOMER_SYS_ID = vendorObj.VENDOR_SYS_ID;
        this.DisplaySellerVendorName = vendorObj.VENDOR_NAME;
        this.DisplaySellerVendorCode = vendorObj.VENDOR_CODE;
        this.DisplaySellerVendorAddress =
          vendorObj.ADDRESS_LINE_1 +
          "," +
          vendorObj.COUNTRY_NAME +
          "-" +
          vendorObj.PIN_CODE;
      }
    },

    //Function To OnKeyUp Seller Vendor Info
    //Author : Prasanta Saren
    onKeyUpSellerVendorInfo() {
      if (this.SELLER_VENDOR_INFO == "") {
        this.DisplaySellerVendorName = "";
        this.DisplaySellerVendorCode = "";
        this.DisplaySellerVendorAddress = "";
        this.axios
          .get(apiEndPoints.view_vendor, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 100,
              PAGE_NO: 10,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllSellerVendorDetailsArray = response.data.response;
            } else {
              this.AllSellerVendorDetailsArray = "";
            }
          })
          .catch((err) => {
            //console.log(err);
            this.AllSellerVendorDetailsArray = "";
          });
      }
    },

    //Function To Seller Customer Info onKeyUp
    //Author : Prasanta Saren
    onKeyUpSellerCustomerInfo() {
      if (this.SELLER_CUSTOMER_INFO == "") {
        this.DisplaySellerCustomerType = "";
        this.DisplaySellerCustomerIdNumber = "";
        this.DisplaySellerCustomerName = "";
        this.DisplaySellerCustomerAddress = "";
        this.axios
          .get(apiEndPoints.view_customer, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 20,
              PAGE_NO: 1,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllSellerCustomerDetailsArray = response.data.response;
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    },

    //Function To Seller Customer Info onChange
    //Author : Prasanta Saren
    onChnageSellerCustomerInfo() {
      let CUSTOMER_NAME = this.SELLER_CUSTOMER_INFO;
      let cusObj = this.AllCustomerArray.find(
        (items) => items.CUSTOMER_NAME == CUSTOMER_NAME
      );
      //console.log(cusObj);
      if (cusObj) {
        this.addupdatesellerdata.CUSTOMER_SYS_ID = cusObj.CUSTOMER_SYS_ID;
        this.DisplaySellerCustomerType = cusObj.CUSTOMER_TYPE;
        this.DisplaySellerCustomerIdNumber = cusObj.ID_NUMBER;
        this.DisplaySellerCustomerName = cusObj.CUSTOMER_NAME;
        let address =
          cusObj.ADDRESS_LINE_1 +
          " , " +
          cusObj.COUNTRY_NAME +
          "-" +
          cusObj.PIN_CODE;
        this.DisplaySellerCustomerAddress = address;
      }
    },

    //Function To Onclick No Delivery Date
    //Author : Prasanta Saren
    clickSellerFormNoDeveryDate() {
      this.isDisabled = this.addupdatesellerdata.NO_DELIVERY_DATE;
    },

    //Function To Onkeyup Price Amount
    //Author : Prasanta Saren
    onkeyupPriceAmount() {
      this.GLOBAL_PURCHASE_PRICE_SD = this.addupdatesellerdata.PURCHASE_PRICE;
    },

    //Function To Onchange Purchase Price Is Zero
    //Author : Prasanta Saren
    onchangePurchasePriceIsZero() {
      let eventValue = this.addupdatesellerdata.PURCHASE_PRICE_IS_ZERO;
      if (eventValue == true || eventValue == "true") {
        this.addupdatesellerdata.PURCHASE_PRICE = "0";
        this.isDisablePriceAmount = true;
      } else {
        this.addupdatesellerdata.PURCHASE_PRICE = this.GLOBAL_PURCHASE_PRICE_SD;
        this.isDisablePriceAmount = false;
      }
    },

    //Function To Change Null To Zero For Seller
    //Author :
    changeNullToZeroForSeller() {
      if (this.addupdatesellerdata.AGREEMENT_NUMBER == "" || null)
        this.addupdatesellerdata.AGREEMENT_NUMBER = "0";
      if (this.addupdatesellerdata.ADMIN_FEE == "" || null)
        this.addupdatesellerdata.ADMIN_FEE = "0";
      if (this.addupdatesellerdata.PURCHASE_OUTSIDE_COMMISSION == "" || null)
        this.addupdatesellerdata.PURCHASE_OUTSIDE_COMMISSION = "0";
      if (this.addupdatesellerdata.COM_RTN_1 == "" || null)
        this.addupdatesellerdata.COM_RTN_1 = "0";
    },

    //Function To Add Update Vehicle Seller Details
    //Author : Prasanta Saren
    addUpdateSellerDetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addupdatesellerdata["CREATED_BY"] = user_details.UserId;
      if (this.GLOBAL_SELLER_CUSTOMER_TYPE)
        this.addupdatesellerdata["ITEM"] = "UPDATE";
      else this.addupdatesellerdata["ITEM"] = "ADD";
      this.addupdatesellerdata["E_TRANSFER_BY_USER_SYS_ID"] =
        user_details.UserId;
      this.addupdatesellerdata["RECORD_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;
      this.addupdatesellerdata["EXCLUDE_ROAD_TAX"] =
        this.addupdatesellerdata.EXCLUDE_ROAD_TAX == true ? "Y" : "N";
      this.addupdatesellerdata["EXCLUDE_REGISTRATION_FEE_AND_ARF"] =
        this.addupdatesellerdata.EXCLUDE_REGISTRATION_FEE_AND_ARF == true
          ? "Y"
          : "N";
      this.addupdatesellerdata["EXCLUDE_COE"] =
        this.addupdatesellerdata.EXCLUDE_COE == true ? "Y" : "N";
      this.addupdatesellerdata["NO_DELIVERY_DATE"] =
        this.addupdatesellerdata.NO_DELIVERY_DATE == true ? "Y" : "N";
      this.addupdatesellerdata["PURCHASE_PRICE_IS_ZERO"] =
        this.addupdatesellerdata.PURCHASE_PRICE_IS_ZERO == true ? "Y" : "N";
      this.addupdatesellerdata["BROKER_DEAL"] =
        this.addupdatesellerdata.BROKER_DEAL == true ? "Y" : "N";
      if (
        this.GLOBAL_ASKING_PRICE_SD != "" &&
        this.GLOBAL_ASKING_PRICE_SD != null
      )
        this.addupdatesellerdata["ALTERNATE_PRICE_SD"] =
          this.GLOBAL_ASKING_PRICE_SD;
      else this.addupdatesellerdata["ALTERNATE_PRICE_SD"] = "0";
      if (this.addupdatesellerdata.ASKING_PRICE == null)
        this.addupdatesellerdata["ASKING_PRICE"] = "0";
      else
        this.addupdatesellerdata["ASKING_PRICE"] = parseFloat(
          this.addupdatesellerdata.ASKING_PRICE
        );
      this.changeNullToZeroForSeller();
      this.axios
        .post(apiEndPoints.add_update_seller_details, this.addupdatesellerdata)
        .then((response) => {
          if (response.data.status == "true") {
            this.uploadLogCardInForSellerDetails();
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getSellerDetails(this.addupdatesellerdata.RECORD_SYS_ID);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          if (err.response.status != 401 || err.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Handle Log Card In File Object
    //Author : Prasanta Saren
    handleLogCardInFileObject() {
      let file = this.$refs.logCardInFile.files[0];
      //console.log(file);
      let fileName = file.name;
      let fileExtension = fileName.split(".").pop();
      const today = new Date();
      const date =
        today.getFullYear() +
        "_" +
        (today.getMonth() + 1) +
        "_" +
        today.getDate();
      const time =
        today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds();
      let reFileName =
        this.GLOBAL_RECORD_SYS_ID +
        "_" +
        this.LOCAL_VEHICLE_NUMBER +
        "_" +
        date +
        "_" +
        time +
        "_" +
        "SELLER." +
        fileExtension;
      this.addupdatesellerdata.LOG_CARD_TRANSFER_IN = reFileName;
      this.SELLER_LOG_CARD_TRANSFER_IN = new File([file], reFileName, {
        type: file.type,
      });
    },
    handleLogCardIn2FileObject() {
      let file = this.$refs.logCardIn2File.files[0];
      //console.log(file);
      let fileName = file.name;
      let fileExtension = fileName.split(".").pop();
      const today = new Date();
      const date =
        today.getFullYear() +
        "_" +
        (today.getMonth() + 1) +
        "_" +
        today.getDate();
      const time =
        today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds();
      let reFileName =
        this.GLOBAL_RECORD_SYS_ID +
        "_" +
        this.LOCAL_VEHICLE_NUMBER +
        "_" +
        date +
        "_" +
        time +
        "_" +
        "SELLER." +
        fileExtension;
      this.addupdatesellerdata.LOG_CARD_TRANSFER_IN_2 = reFileName;
      this.SELLER_LOG_CARD_TRANSFER_IN_2 = new File([file], reFileName, {
        type: file.type,
      });
    },

    //Function To Upload Log Card In File
    //Author : Prasanta Saren
    uploadLogCardInForSellerDetails() {
      let formData = new FormData();
      formData.append(
        "SELLER_LOG_CARD_TRANSFER_IN",
        this.SELLER_LOG_CARD_TRANSFER_IN
      );
      formData.append(
        "SELLER_LOG_CARD_TRANSFER_IN_2",
        this.SELLER_LOG_CARD_TRANSFER_IN_2
      );
      formData.append("ITEM", "SELLER_LOG_CARD_TRANSFER_IN");
      //apiEndPoints.file_upload
      this.axios
        .post(apiEndPoints.file_upload, formData, {
          headers: {
            "Content-Type":
              "multipart/form-data; charset=utf-8; boundary=" +
              Math.random().toString().substr(2),
          },
        })
        .then((response) => {
          this.$refs.logCardInFile.value = null;
          this.$refs.logCardIn2File.value = null;
        })
        .catch((err) => {});
    },

    //Function To Get Vehicle Seller Details
    //Author : Prasanta Saren
    getSellerDetails(recordSysId) {
      this.axios
        .get(apiEndPoints.view_seller_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: recordSysId,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            let api_response = response.data.response[0];


this.PURCHASE_DATE_NEW= api_response.PURCHASE_DATE ||"-"
this.SELLER_CUSTOMER_NAME= api_response.CUSTOMER_NAME ||"-"
this.ID_NO_NEW= api_response.ID_NUMBER ||"-"
this.ID_NO_NEW= api_response.ID_NUMBER ||"-"
this.ESTIMATE_DELIVERY_IN_DATE_NEW= api_response.ESTIMATE_DELIVERY_IN_DATE ||"-"
this.DELIVERY_IN_DATE= api_response.DELIVERY_IN_DATE ||"-"
this.E_TRANSFER_IN_DATE= api_response.E_TRANSFER_IN_DATE ||"-"
this.DELIVERY_IN_DATE= api_response.DELIVERY_IN_DATE ||"-"
this.PURCHASE_PRICE_SD= api_response.PURCHASE_PRICE_SD ||"-"
this.TOTAL_PURCHASE_PRICE= api_response.TOTAL_PURCHASE_PRICE ||"-"
this.PURCHASE_OUTSIDE_COMMISSION= api_response.PURCHASE_OUTSIDE_COMMISSION ||"-"
this.TRANSACTION_TYPE= api_response.TRANSACTION_TYPE ||"-"
this.ADDRESS_LINE_1_SELLER= api_response.ADDRESS_LINE_1 ||"-"
this.COUNTRY_NAME_SELLER= api_response.COUNTRY_NAME ||"-"
this.PIN_CODE_SELLER= api_response.PIN_CODE 
this.AGREEMENT_NUMBER= api_response.AGREEMENT_NUMBER ||"-"
this.GST_AMOUNT_SELLER= api_response.GST_AMOUNT ||"-"
this.GST_METHOD_SELLER= api_response.GST ||"-"
this.NOTE= api_response.NOTE ||"-"
this.E_TRANSFER_IN_DATE= api_response.E_TRANSFER_IN_DATE ||"-"


            this.addupdatesellerdata = api_response;
            this.GLOBAL_ASKING_PRICE_SD = api_response.ASKING_PRICE;
            this.SELLER_AP_PURCHASE_AMOUNT = api_response.TOTAL_PURCHASE_AMOUNT;
            this.SELLER_AP_REDUCED_PURCHASE_AMOUNT =
              api_response.REDUCED_PURCHASE_AMOUNT;
            this.SELLER_AP_REDUCED_RECEIVABLE_AMOUNT =
              api_response.REDUCED_PURCHASE_AMOUNT;
            if (this.addupdatesellerdata.LOG_CARD_TRANSFER_IN != "")
              this.isDisabledLogCardIn = true;
            else this.isDisabledLogCardIn = false;
            if (this.addupdatesellerdata.LOG_CARD_TRANSFER_IN_2 != "")
              this.isDisabledLogCardIn2 = true;
            else this.isDisabledLogCardIn2 = false;
            this.GLOBAL_PURCHASE_PRICE_SD =
              this.addupdatesellerdata.TOTAL_PURCHASE_PRICE;
            this.GLOBAL_SELLER_CUSTOMER_TYPE = "UPDATE";
            this.addupdatesellerdata.CUSTOMER_TYPE = api_response.CUSTOMER_TYPE;
            let CUSTOMER_SYS_ID = api_response.CUSTOMER_SYS_ID;
            if (api_response.CUSTOMER_TYPE == "Customer") {
              let cusObj = this.AllCustomerArray.find(
                (items) => items.CUSTOMER_SYS_ID == CUSTOMER_SYS_ID
              );
              if (cusObj) {
                this.SELLER_CUSTOMER_INFO = cusObj.CUSTOMER_NAME;
                this.DisplaySellerCustomerType = cusObj.CUSTOMER_TYPE;
                this.DisplaySellerCustomerIdNumber = cusObj.ID_NUMBER;
                this.DisplaySellerCustomerName = cusObj.CUSTOMER_NAME;
                this.DisplaySellerCustomerAddress =
                  cusObj.ADDRESS_LINE_1 +
                  " , " +
                  cusObj.COUNTRY_NAME +
                  "-" +
                  cusObj.PIN_CODE;
              }
            } else if (api_response.CUSTOMER_TYPE == "Vendor") {
              let vendorObj = this.AllVendordetails.find(
                (items) => items.VENDOR_SYS_ID == CUSTOMER_SYS_ID
              );
              if (vendorObj) {
                this.SELLER_VENDOR_INFO = vendorObj.VENDOR_NAME;
                this.DisplaySellerVendorName = vendorObj.VENDOR_NAME;
                this.DisplaySellerVendorCode = vendorObj.VENDOR_CODE;
                this.DisplaySellerVendorAddress =
                  vendorObj.ADDRESS_LINE_1 +
                  "," +
                  vendorObj.COUNTRY_NAME +
                  "-" +
                  vendorObj.PIN_CODE;
              }
            } else {
              this.SELLER_CUSTOMER_INFO = "";
              this.DisplaySellerCustomerType = "";
              this.DisplaySellerCustomerIdNumber = "";
              this.DisplaySellerCustomerName = "";
              this.DisplaySellerCustomerAddress = "";
              this.DisplaySellerVendorName = "";
              this.DisplaySellerVendorCode = "";
              this.DisplaySellerVendorAddress = "";
              this.SELLER_VENDOR_INFO = "";
            }
            this.addupdatesellerdata.sellertype = api_response.CUSTOMER_TYPE;
            this.customertype = api_response.SUB_CUSTOMER_TYPE;
            // this.customerdetails["COUNTRY_NAME"] = api_response.COUNTRY_NAME;
            // this.customerdetails["CUSTOMER_NAME"] = api_response.CUSTOMER_NAME;
            // this.customerdetails["ADDRESS_LINE_1"] = api_response.ADDRESS_LINE_1;
            // this.customerdetails["PIN_CODE"] = api_response.PIN_CODE;
            if (response.data.response[0].NO_DELIVERY_DATE == "Y")
              this.addupdatesellerdata.NO_DELIVERY_DATE = true;
            else this.addupdatesellerdata.NO_DELIVERY_DATE = false;
            if (response.data.response[0].EXCLUDE_ROAD_TAX == "Y")
              this.addupdatesellerdata.EXCLUDE_ROAD_TAX = true;
            else this.addupdatesellerdata.EXCLUDE_ROAD_TAX = false;
            if (
              response.data.response[0].EXCLUDE_REGISTRATION_FEE_AND_ARF == "Y"
            )
              this.addupdatesellerdata.EXCLUDE_REGISTRATION_FEE_AND_ARF = true;
            else
              this.addupdatesellerdata.EXCLUDE_REGISTRATION_FEE_AND_ARF = false;
            if (response.data.response[0].EXCLUDE_COE == "Y")
              this.addupdatesellerdata.EXCLUDE_COE = true;
            else this.addupdatesellerdata.EXCLUDE_COE = false;
            if (response.data.response[0].NO_DELIVERY_DATE == "Y")
              this.addupdatesellerdata.NO_DELIVERY_DATE = true;
            else this.addupdatesellerdata.NO_DELIVERY_DATE = false;
            if (response.data.response[0].PURCHASE_PRICE_IS_ZERO == "Y")
              this.addupdatesellerdata.PURCHASE_PRICE_IS_ZERO = true;
            else this.addupdatesellerdata.PURCHASE_PRICE_IS_ZERO = false;
            if (response.data.response[0].BROKER_DEAL == "Y")
              this.addupdatesellerdata.BROKER_DEAL = true;
            else this.addupdatesellerdata.BROKER_DEAL = false;
          } else {
            this.addupdatesellerdata = {};
            this.GLOBAL_ALTERNATE_PRICE_SD = "";
            this.isDisabledLogCardIn = false;
            this.isDisabledLogCardIn2 = false;
            this.GLOBAL_SELLER_CUSTOMER_TYPE = "";
          }
        })
        .catch((err) => {
          this.addupdatesellerdata["RECORD_SYS_ID"] = recordSysId;
          //console.log(err);
        })
        .finally(() => {
          let outputsObj = {};
          var obj = this.addupdatesellerdata;
          Object.keys(obj).forEach(function (key, el) {
            if (
              obj[key] == null ||
              obj[key] == "null" ||
              obj[key] == "0" ||
              obj[key] == 0
            )
              outputsObj[key] = "";
            else outputsObj[key] = obj[key];
          });
          this.addupdatesellerdata = outputsObj;
        });
      this.addupdatesellerdata["RECORD_SYS_ID"] = recordSysId;
    },

    //Function To Onchange Broker Deal
    //Author : Prasanta Saren
    onChangeBrokerDeal() {
      if (this.addupdatesellerdata.BROKER_DEAL == true) {
        this.addupdatesellerdata.BROKER_1 =
          this.addmasterdata.INCHARGE_BY_BROKER_SYS_ID;
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
        this.addupdatesellerdata.BROKER_1 = "";
      }
    },

    //Function To Get Bank Name For Seller AP Payment Details
    //Author :
    getBankNameForAPPayment() {
      this.addupdatesellerapdata.BANK_NAME = this.Banklist.find(
        (o) => o.BANK_SYS_ID === this.addupdatesellerapdata.BANK_SYS_ID
      ).BANK_NAME;
    },

    //Function To Get Finance Company Details
    //Author :
    getFinanceCompanyDetails() {
      this.axios
        .get(apiEndPoints.finace_company, {
          params: {
            ITEM: "VIEW_ALL",
            VEHICLE_NO: "null",
            RECORD_SYS_ID: this.LOCAL_RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.FinanceCompanyDetails = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.FinanceCompanyDetails = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To AP Amount Calculation For Seller AP Payment Add
    //Author : Prasanta Saren
    addAPAmountCalculationForSeller() {
      if (this.addupdatesellerapdata.AMOUNT) {
        let reducedPurchaseAmount = this.SELLER_AP_REDUCED_PURCHASE_AMOUNT;
        let amount = this.addupdatesellerapdata.AMOUNT;
        let balance = parseFloat(reducedPurchaseAmount) - parseFloat(amount);
        this.SELLER_AP_REDUCED_RECEIVABLE_AMOUNT = balance;
      }
    },

    //Function To Add Seller AP Payment Details
    //Author : Prasanta Saren
    addSellerAPPaymentDetails() {
      if (
        this.addupdatesellerapdata.PAYMENT_DESC == "" ||
        this.addupdatesellerapdata.PAYMENT_DESC == null
      ) {
        Global.showErrorAlert(true, "warning", "AP Payment Field Is Required!");
        return false;
      } else if (
        this.addupdatesellerapdata.AMOUNT == "" ||
        this.addupdatesellerapdata.AMOUNT == null
      ) {
        Global.showErrorAlert(true, "warning", "Amount Field Is Required!");
        return false;
      } else if (
        this.addupdatesellerapdata.PAYMENT_MODE == "" ||
        this.addupdatesellerapdata.PAYMENT_MODE == null
      ) {
        Global.showErrorAlert(
          true,
          "warning",
          "Payment Mode Field Is Required!"
        );
        return false;
      }
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addupdatesellerapdata["CREATED_BY"] = user_details.UserId;
      this.addupdatesellerapdata["ITEM"] = "ADD";
      this.addupdatesellerapdata["CUSTOMER_NAME"] =
        this.customerdetails.CUSTOMER_NAME;
      this.addupdatesellerapdata["BALANCE"] =
        this.SELLER_AP_REDUCED_RECEIVABLE_AMOUNT;
      if (this.addupdatesellerapdata.PAYMENT_DESC == "Others")
        this.addupdatesellerapdata["PAYMENT_DESC"] =
          this.addupdatesellerapdata.PAYMENT_DESC_OTHER;
      if (this.addupdatesellerapdata.AMOUNT == "")
        this.addupdatesellerapdata["AMOUNT"] = "0";
      if (this.addupdatesellerapdata.BANK_SYS_ID == "")
        this.addupdatesellerapdata["BANK_SYS_ID"] = "0";
      this.axios
        .post(
          apiEndPoints.add_update_ap_payment_details,
          this.addupdatesellerapdata
        )
        .then((response) => {
          if (response.data.status == "true") {
            this.resetSellerAPPaymentForm();
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getsellerAPdetails(this.addupdatesellerapdata.RECORD_SYS_ID);
            this.addupdatesellerapdata.BANK_NAME = "";
            this.addupdatesellerapdata.CHEQUE_DATE = "";
            this.SELLER_AP_REDUCED_PURCHASE_AMOUNT =
              this.SELLER_AP_REDUCED_RECEIVABLE_AMOUNT;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Get Seller AP Payment Details
    //Author : Prasanta Saren
    getsellerAPdetails(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.view_ap_payment_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: RECORD_SYS_ID,
            P_MODULE: "SELLER",
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.sellerapdetails = response.data.response;
            this.updat_user.REDUCED_RECEIVABLE_AMOUNT =
              response.data.response[0].REDUCED_RECEIVABLE_AMOUNT;
          } else {
            this.sellerapdetails = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Reset Seller AP Payment Form
    //Author : Prasanta Saren
    resetSellerAPPaymentForm() {
      this.addupdatebuyerapdata.PAYMENT_DESC = "";
      this.addupdatebuyerapdata.BANK_SYS_ID = "";
      this.addupdatebuyerapdata.CHEQUE_NO = "";
      this.addupdatebuyerapdata.TO_FROM_WHOM = "";
      this.addupdatebuyerapdata.AMOUNT = "";
      this.addupdatebuyerapdata.PAYMENT_MODE = "";
      this.addupdatebuyerapdata.CHEQUE_DATE = "";
      this.addupdatebuyerapdata.REMARKS = "";
      this.addupdatesellerapdata.PAYMENT_DESC = "";
      this.addupdatesellerapdata.BANK_SYS_ID = "";
      this.addupdatesellerapdata.CHEQUE_NO = "";
      this.addupdatesellerapdata.CHEQUE_DATE = "";
      this.addupdatesellerapdata.PAYMENT_MODE = "";
      this.addupdatesellerapdata.REMARKS = "";
      this.addupdatesellerapdata.TO_FROM_WHOM = "";
      this.addupdatesellerapdata.AMOUNT = "";
      this.addupdatesellerapdata.SETTLEMENT_REMARKS = "";
      this.addupdatebuyerapdata.PAYMENT_DESC_OTHER = "";
      this.addupdatesellerapdata.PAYMENT_DESC_OTHER = "";
    },

    //Function To Get Bank Name For Update Seller AP Payment Details
    //Author :
    getBankNameForUpdateAPPayment() {
      this.updat_user.BANK_NAME = this.Banklist.find(
        (o) => o.BANK_SYS_ID === this.updat_user.BANK_SYS_ID
      ).BANK_NAME;
    },

    //Function To AP Amount Calculation For Seller AP Payment Update
    //Author : Prasanta Saren
    updateAPAmountCalculationForSeller() {
      if (this.updat_user.AMOUNT) {
        let reducedPurchaseAmount = this.SELLER_AP_REDUCED_PURCHASE_AMOUNT;
        let amount = this.updat_user.AMOUNT;
        let balance = parseFloat(reducedPurchaseAmount) - parseFloat(amount);
        this.SELLER_AP_REDUCED_RECEIVABLE_AMOUNT = balance;
      }
    },

    //Function To Update Seller AP Payment Details
    //Author : Prasanta Saren
    updateSellerAPPaymentDetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.updat_user["CREATED_BY"] = user_details.UserId;
      this.updat_user["ITEM"] = "UPDATE";
      this.updat_user["RECORD_SYS_ID"] = this.updat_user.VEHICLE_SYS_ID;
      this.updat_user["BALANCE"] = this.SELLER_AP_REDUCED_RECEIVABLE_AMOUNT;
      this.axios
        .post(apiEndPoints.add_update_ap_payment_details, this.updat_user)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.SELLER_AP_REDUCED_PURCHASE_AMOUNT =
              this.SELLER_AP_REDUCED_RECEIVABLE_AMOUNT;
            this.getsellerAPdetails(this.updat_user.VEHICLE_SYS_ID);
            $("#sellerAPPaymentUpdateModal").modal("hide");
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Delete Seller AP Payment Details
    //Author : Prasanta Saren
    async deleteSellerAPPaymentDetails(data) {
      const result = await Global.showConfirmationAlert(
        "Are you sure?",
        "you want to delete this record!",
        "warning"
      );
      if (result.isConfirmed) {
        NProgress.start();
        NProgress.set(0.1);
        this.loading = true;
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarrydata = {
          ITEM: "DELETE",
          RECORD_SYS_ID: data.RECORD_SYS_ID,
          MODULE: data.MODULE,
          PAYMENT_DESC: data.PAYMENT_DESC,
          CREATED_BY: user_details.UserId,
        };
        this.axios
          .post(apiEndPoints.add_update_ap_payment_details, postarrydata)
          .then((response) => {
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getsellerAPdetails(this.addupdatesellerapdata.RECORD_SYS_ID);
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          })
          .finally(() => {
            NProgress.done();
            this.loading = false;
          });
      }
    },

    //Function To Get Finance Comapny Bank details
    //Author :
    getFinanceComapnyBankDetails() {
      this.axios
        .get(apiEndPoints.view_finance_company_bank, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.finance_company_bank_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.finance_company_bank_details = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function to save invoice no and date in purchase settlement
    //Author : Riju mukherjee
    pdfdatapurchaseseeelement() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.purchasesettlement["ITEM"] = "PURCHASE_SETTLEMENT";
      this.purchasesettlement["CREATED_BY"] = user_details.UserId;
      this.axios
        .post(apiEndPoints.vehicle_document_add, this.purchasesettlement)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.inputfieldpurchaseseelement();
            this.getPurchasesNoteDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //for input field blank in purchase settlement
    //Author : Riju mukherjee
    inputfieldpurchaseseelement() {
      this.purchasesettlement.PURCHASE_SETTLEMENT_INVOICE_NO = "";
      this.purchasesettlement.PURCHASE_SETTLEMENT_INVOICE_DATE = "";
    },

    //Function To Get Purchase Note Details
    //Author : Prasanta Saren
    getPurchasesNoteDetails() {
      if (!this.GLOBAL_RECORD_SYS_ID) {
        Global.showErrorAlert(true, "error", "Vehicle Number Not Found.");
        $("#staticBackdrop-6").modal("hide");
        return false;
      }
      this.axios
        .get(apiEndPoints.purchase_settlement, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
            VEHICLE_NO: "null",
          },
        })
        .then((response) => {
          if (response.data.status == "false") {
            this.PurchaseNoteDetails = [];
            Global.showErrorAlert(true, "error", response.data.response);
            $("#staticBackdrop-6").modal("hide");
          } else {
            this.PurchaseNoteDetails = response.data.response[0];
            $("#staticBackdrop-6").modal("show");
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Generate PDF For Purchase Settlement
    //Author : Prasanta Saren
    generateReportPurchase() {
      this.$refs.PurchaseSettlementHtmlPDF.generatePdf();
    },

    //Function To Get Internal User List
    //Author : Prasanta Saren
    getInternalUserList() {
      this.axios
        .get(apiEndPoints.view_user, {
          params: {
            ITEM: "SPECIFIC",
            USER_TYPE: "Internal",
            PAGE_SIZE: 1000,
            PAGE_NO: 10,
          },
        })
        .then((response) => {
          this.InternalUserList = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Get External Broker Data List
    //Author :
    getExternalBrokerDetailsList() {
      this.axios
        .get(apiEndPoints.view_user, {
          params: {
            ITEM: "SPECIFIC",
            USER_TYPE: "External",
            PAGE_SIZE: 1000,
            PAGE_NO: 100,
          },
        })
        .then((response) => {
          this.brokerdetails = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    //-------------------------------For Seller Details Section End----------------------------

    //-------------------------------For Buyer Details Section Start-----------------------------
    //Function To Onchange Buyer Type
    //Author : Prasanta Saren
    onChnageBuyerTypeFun() {
      let SellerType = this.addupdatebuyerdata.CUSTOMER_TYPE;
      if (SellerType == "Vendor") {
        this.BUYER_CUSTOMER_INFO = "";
        this.DisplayBuyerCustomerType = "";
        this.DisplayBuyerCustomerIdNumber = "";
        this.DisplayBuyerCustomerName = "";
        this.DisplayBuyerCustomerAddress = "";
        this.axios
          .get(apiEndPoints.view_vendor, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 100,
              PAGE_NO: 10,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllBuyerVendorDetailsArray = response.data.response;
            } else {
              this.AllBuyerVendorDetailsArray = "";
            }
          })
          .catch((err) => {
            //console.log(err);
            this.AllSellerVendorDetailsArray = "";
          });
      } else if (SellerType == "Customer") {
        this.BUYER_VENDOR_INFO = "";
        this.DisplayBuyerVendorName = "";
        this.DisplayBuyerVendorCode = "";
        this.DisplayBuyerVendorAddress = "";
        this.axios
          .get(apiEndPoints.view_customer, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 20,
              PAGE_NO: 1,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllBuyerCustomerDetailsArray = response.data.response;
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    },

    //Function To OnChange Buyer Vendor Info
    //Author : Prasanta Saren
    onChnageBuyerVendorInfo() {
      let vendorName = this.BUYER_VENDOR_INFO;
      let vendorObj = this.AllBuyerVendorDetailsArray.find(
        (items) => items.VENDOR_NAME == vendorName
      );
      if (vendorObj) {
        this.addupdatebuyerdata.CUSTOMER_SYS_ID = vendorObj.VENDOR_SYS_ID;
        this.DisplayBuyerVendorName = vendorObj.VENDOR_NAME;
        this.DisplayBuyerVendorCode = vendorObj.VENDOR_CODE;
        this.DisplayBuyerVendorAddress =
          vendorObj.ADDRESS_LINE_1 +
          "," +
          vendorObj.COUNTRY_NAME +
          "-" +
          vendorObj.PIN_CODE;
      }
    },

    //Function To OnKeyUp Buyer Vendor Info
    //Author : Prasanta Saren
    onKeyUpBuyerVendorInfo() {
      if (this.BUYER_VENDOR_INFO == "") {
        this.DisplayBuyerVendorName = "";
        this.DisplayBuyerVendorCode = "";
        this.DisplayBuyerVendorAddress = "";
        this.axios
          .get(apiEndPoints.view_vendor, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 100,
              PAGE_NO: 10,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllBuyerVendorDetailsArray = response.data.response;
            } else {
              this.AllBuyerVendorDetailsArray = "";
            }
          })
          .catch((err) => {
            //console.log(err);
            this.AllBuyerVendorDetailsArray = "";
          });
      }
    },

    //Function To Buyer Customer Info onKeyUp
    //Author : Prasanta Saren
    onKeyUpBuyerCustomerInfo() {
      if (this.BUYER_CUSTOMER_INFO == "") {
        this.DisplayBuyerCustomerType = "";
        this.DisplayBuyerCustomerIdNumber = "";
        this.DisplayBuyerCustomerName = "";
        this.DisplayBuyerCustomerAddress = "";
        this.axios
          .get(apiEndPoints.view_customer, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 100,
              PAGE_NO: 10,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllBuyerCustomerDetailsArray = response.data.response;
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    },

    //Function To Buyer Customer Info onChange
    //Author : Prasanta Saren
    onChnageBuyerCustomerInfo() {
      let CUSTOMER_NAME = this.BUYER_CUSTOMER_INFO;
      let cusObj = this.AllBuyerCustomerDetailsArray.find(
        (items) => items.CUSTOMER_NAME == CUSTOMER_NAME
      );
      if (cusObj) {
        this.addupdatebuyerdata.CUSTOMER_SYS_ID = cusObj.CUSTOMER_SYS_ID;
        this.DisplayBuyerCustomerType = cusObj.CUSTOMER_TYPE;
        this.DisplayBuyerCustomerIdNumber = cusObj.ID_NUMBER;
        this.DisplayBuyerCustomerName = cusObj.CUSTOMER_NAME;
        let address =
          cusObj.ADDRESS_LINE_1 +
          " , " +
          cusObj.COUNTRY_NAME +
          "-" +
          cusObj.PIN_CODE;
        this.DisplayBuyerCustomerAddress = address;
      }
    },

    //Function To Null To Zeror Value Change For Buyer
    //Author : Prasanta Saren
    nullToZeroChangeForBuyer() {
      if (this.addupdatebuyerdata.DN_CN_AMOUNT == "" || null)
        this.addupdatebuyerdata.DN_CN_AMOUNT = "0";
      if (this.addupdatebuyerdata.ADMIN_FEE == "" || null)
        this.addupdatebuyerdata.ADMIN_FEE = "0";
      if (this.addupdatebuyerdata.TOTAL_RECEIVABLE == "" || null)
        this.addupdatebuyerdata.TOTAL_RECEIVABLE = "0";
      if (this.addupdatebuyerdata.COM_RTN_1 == "" || null)
        this.addupdatebuyerdata.COM_RTN_1 = "0";
      if (this.addupdatebuyerdata.TOTAL_RECEIVED == "" || null)
        this.addupdatebuyerdata.TOTAL_RECEIVED = "0";
      if (this.addupdatebuyerdata.AR_BALANCE == "" || null)
        this.addupdatebuyerdata.AR_BALANCE = "0";
      if (this.addupdatebuyerdata.DEPOSITF == "" || null)
        this.addupdatebuyerdata.DEPOSITF = "0";
      if (this.addupdatebuyerdata.HP_FACILITIES_FEE == "" || null)
        this.addupdatebuyerdata.HP_FACILITIES_FEE = "0";
      if (this.addupdatebuyerdata.BROKER_1_SYS_ID == "" || null)
        this.addupdatebuyerdata.BROKER_1_SYS_ID = "0";
      if (this.addupdatebuyerdata.BROKER_2_SYS_ID == "" || null)
        this.addupdatebuyerdata.BROKER_2_SYS_ID = "0";
    },

    //Function To Get Buyer Details
    //Author : Prasanta Saren
    getBuyerDetails(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.view_buyer_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.addupdatebuyerdata = response.data.response[0];
            this.ID_NO_BUYER =
            response.data.response[0].ID_NUMBER ||"-";
            this.BUYER_CUSTOMER_NAME =
            response.data.response[0].CUSTOMER_NAME ||"-";
            this.SALE_AGGREEMENT_PRICE=    response.data.response[0].SALE_AGGREEMENT_PRICE ||"-";
            this.DELIVERY_OUT_DATE_BUYER =
            response.data.response[0].DELIVERY_OUT_DATE ||"-";
            this.BROKER_1_NAME =
            response.data.response[0].BROKER_1_NAME ||"-";
            this.BROKER_2_NAME =
            response.data.response[0].BROKER_2_NAME ||"-";
            this.GST_TYPE =
            response.data.response[0].GST_TYPE ||"-";
            this.TIME =
            response.data.response[0].TIME ||"-";


            this.SALES_PERSON =
            response.data.response[0].SALES_PERSON ||"-";
            this.TOTAL_SELLLING_PRICE =
            response.data.response[0].TOTAL_SELLING_PRICE ||"-";
            this.INVOICE_NO_BUYER =
            response.data.response[0].INVOICE_NUMBER ||"-";
            this.INVOICE_NO_BUYER =
            response.data.response[0].INVOICE_NUMBER ||"-";
            this.OUTSIDE_SALES_COMMSION =
            response.data.response[0].SALES_OUTSIDE_COMMISSION ||"-";
            this.GST_AMOUNT_NEW =
            response.data.response[0].GST_AMOUNT ||"-";
            this.COM_RTN_1 =
            response.data.response[0].COM_RTN_1 ||"-";
let calculation_plstatement =  this.TOTAL_SELLLING_PRICE  -  this.COM_RTN_1 -this.GST_AMOUNT_NEW 
this.calculation_plstatement =calculation_plstatement


            this.SELLING_PRICE_NEW =
            response.data.response[0].SELLING_PRICE ||"-";
        

            this.TOTAL_RECEIVABLE_AMOUNT =
            response.data.response[0].TOTAL_RECEIVABLE_AMOUNT ||"-";
            this.TOTAL_RECEIVED =
            response.data.response[0].TOTAL_RECEIVED ||"-";
            this.E_TRANSFER_OUT_DATE =
            response.data.response[0].E_TRANSFER_OUT_DATE ||"-";

            this.AR_BALANCE =
            response.data.response[0].AR_BALANCE ||"-";

            this.HP_FACILITIES_FEE =
            response.data.response[0].HP_FACILITIES_FEE ||"-";





            this.P_L_DATE =
            response.data.response[0].P_L_DATE ||"-";
            this.BOOKING_DATE =
            response.data.response[0].BOOKING_DATE ||"-";
            this.SELL_CODE =
            response.data.response[0].SELL_CODE ||"-";
            this.SALES_AGGREMENT_NUMBER =
            response.data.response[0].SALES_AGREEMENT_NUMBER ||"-";
            this.SALES_OUTSIDE_COMMISSION =
            response.data.response[0].SALES_OUTSIDE_COMMISSION ||"-";
            this.ADDRESS_LINE_1 =
            response.data.response[0].ADDRESS_LINE_1 ||"-";
            this.COUNTRY_NAME_BUYER =
            response.data.response[0].COUNTRY_NAME ;
            this.PIN_CODE_BUYER =
            response.data.response[0].PIN_CODE ;
            
            this.VEHICLE_TOTAL_RECEIVABLE_AMOUNT =
            response.data.response[0].TOTAL_RECEIVABLE_AMOUNT ;
            this.VEHICLE_TOTAL_RECEIVABLE_AMOUNT_NEW =
            response.data.response[0].TOTAL_RECEIVABLE_AMOUNT ||"-";
            // this.VEHICLE_TOTAL_RECEIVABLE_AMOUNT =
            // response.data.response[0].TOTAL_RECEIVABLE_AMOUNT;
            // this.VEHICLE_TOTAL_RECEIVABLE_AMOUNT =
            // response.data.response[0].TOTAL_RECEIVABLE_AMOUNT;
            // this.VEHICLE_TOTAL_RECEIVABLE_AMOUNT =
            // response.data.response[0].TOTAL_RECEIVABLE_AMOUNT;



            // this.VEHICLE_TOTAL_RECEIVABLE_AMOUNT =
            //   response.data.response[0].TOTAL_RECEIVABLE_AMOUNT;
            this.VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT =
              response.data.response[0].REDUCED_RECEIVABLE_AMOUNT;
              this.VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT_NEW =
              response.data.response[0].REDUCED_RECEIVABLE_AMOUNT||"-";
            this.AR_REDUCED_RECEIVABLE_AMOUNT =
              response.data.response[0].REDUCED_RECEIVABLE_AMOUNT;
            this.addupdatebuyerdata.RECORD_SYS_ID = RECORD_SYS_ID;
            this.addupdatebuyerdata.P_L_DATE =
              response.data.response[0].E_TRANSFER_OUT_DATE;
            this.loan_info.LOAN_TAX_SYS_ID =
              response.data.response[0].TAX_SYS_ID;
            if (this.addupdatebuyerdata.LOG_CARD_TRANSFER_OUT != "")
              this.isDisabledLogCardOut = true;
            else this.isDisabledLogCardOut = false;
            if (this.addupdatebuyerdata.LOG_CARD_TRANSFER_OUT_2 != "")
              this.isDisabledLogCardOut2 = true;
            else this.isDisabledLogCardOut2 = false;
            if (this.GLOBAL_LOAN_INFO_FIN_COMP_SYS_ID == null || "") {
              this.globalCalculateGST("LOAN_INFO");
            }
            if (
              this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID == "0" ||
              this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID == 0 ||
              this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID == "" ||
              this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID == null ||
              this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID == "null"
            ) {
              this.addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID =
                response.data.response[0].TAX_SYS_ID;
            }
            let CustomerType = response.data.response[0].CUSTOMER_TYPE;
            let CUSTOMER_SYS_ID = response.data.response[0].CUSTOMER_SYS_ID;
            this.GLOBAL_BUYER_CUSTOMER_ID = CUSTOMER_SYS_ID;
            if (CustomerType == "Customer") {
              let cusObj = this.AllCustomerArray.find(
                (items) => items.CUSTOMER_SYS_ID == CUSTOMER_SYS_ID
              );
              if (cusObj) {
                this.BUYER_CUSTOMER_INFO = cusObj.CUSTOMER_NAME;
                this.DisplayBuyerCustomerType = cusObj.CUSTOMER_TYPE;
                this.DisplayBuyerCustomerIdNumber = cusObj.ID_NUMBER;
                this.DisplayBuyerCustomerName = cusObj.CUSTOMER_NAME;
                this.DisplayBuyerCustomerAddress =
                  cusObj.ADDRESS_LINE_1 +
                  " , " +
                  cusObj.COUNTRY_NAME +
                  "-" +
                  cusObj.PIN_CODE;
              }
            } else if (CustomerType == "Vendor") {
              let vendorObj = this.AllVendordetails.find(
                (items) => items.VENDOR_SYS_ID == CUSTOMER_SYS_ID
              );
              if (vendorObj) {
                this.BUYER_VENDOR_INFO = vendorObj.VENDOR_NAME;
                this.DisplayBuyerVendorName = vendorObj.VENDOR_NAME;
                this.DisplayBuyerVendorCode = vendorObj.VENDOR_CODE;
                this.DisplayBuyerVendorAddress =
                  vendorObj.ADDRESS_LINE_1 +
                  "," +
                  vendorObj.COUNTRY_NAME +
                  "-" +
                  vendorObj.PIN_CODE;
              }
            } else {
              this.BUYER_CUSTOMER_INFO = "";
              this.BUYER_VENDOR_INFO = "";
              this.DisplayBuyerCustomerType = "";
              this.DisplayBuyerCustomerIdNumber = "";
              this.DisplayBuyerCustomerName = "";
              this.DisplayBuyerCustomerAddress = "";
              this.DisplayBuyerVendorName = "";
              this.DisplayBuyerVendorCode = "";
              this.DisplayBuyerVendorAddress = "";
            }
          } else {
            this.isDisabledLogCardOut = false;
            this.isDisabledLogCardOut2 = false;
            this.GST_AMOUNT_NEW =
          "-";
            this.ID_NO_BUYER =
         "-";
            this.BUYER_CUSTOMER_NAME =
           "-";
            this.SALE_AGGREEMENT_PRICE=   "-";
            this.DELIVERY_OUT_DATE_BUYER =
          "-";
          this.VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT_NEW =  "-";
            this.BROKER_1_NAME =
            "-";
            this.BROKER_2_NAME =
          "-";
            this.GST_TYPE =
         "-";
            this.TIME =
         "-";
         this.OUTSIDE_SALES_COMMSION =
      "-";


            this.SALES_PERSON =
          "-";
            this.TOTAL_SELLLING_PRICE =
           "-";
            this.INVOICE_NO_BUYER =
           "-";

            this.SELLING_PRICE_NEW =
          "-";

            this.TOTAL_RECEIVABLE_AMOUNT = "-";
            this.TOTAL_RECEIVED =
           "-";
            this.E_TRANSFER_OUT_DATE =
          "-";

            this.AR_BALANCE =
           "-";

            this.HP_FACILITIES_FEE =  "-";





            this.P_L_DATE =
         "-";
            this.BOOKING_DATE =
          "-";
            this.SELL_CODE =
          "-";
            this.SALES_AGGREMENT_NUMBER =
           "-";
            this.SALES_OUTSIDE_COMMISSION =
           "-";
            this.ADDRESS_LINE_1 =
           "-";
            
            this.VEHICLE_TOTAL_RECEIVABLE_AMOUNT =
            "-" ;
            this.VEHICLE_TOTAL_RECEIVABLE_AMOUNT_NEW = "-";
          }
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          let outputsObj = {};
          var obj = this.addupdatebuyerdata;
          Object.keys(obj).forEach(function (key, el) {
            if (
              obj[key] == null ||
              obj[key] == "null" ||
              obj[key] == "0" ||
              obj[key] == 0
            )
              outputsObj[key] = "";
            else outputsObj[key] = obj[key];
          });
          this.addupdatebuyerdata = outputsObj;
        });
      this.addupdatebuyerdata.RECORD_SYS_ID = RECORD_SYS_ID;
    },

    //Function To Add Buyer Upper Section Details
    //Author : Prasanta Saren
    addBuyerUpperSectionDetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addupdatebuyerdata["CREATED_BY"] = user_details.UserId;
      if (
        this.addupdatebuyerdata.SALES_PERSON_SYS_ID != "" ||
        this.addupdatebuyerdata.SALES_PERSON_SYS_ID != 0
      ) {
        this.addupdatebuyerdata.SALES_PERSON = this.brokerdetails.find(
          (items) =>
            items.USER_SYS_ID == this.addupdatebuyerdata.SALES_PERSON_SYS_ID
        ).PERSON_NAME;
      }
      if (this.GLOBAL_BUYER_CUSTOMER_ID == "")
        this.addupdatebuyerdata["ITEM"] = "ADD";
      else this.addupdatebuyerdata["ITEM"] = "UPDATE";
      this.addupdatebuyerdata["COM_RTN"] = "0";
      this.addupdatebuyerdata["COM_RTN_2"] = "0";
      this.addupdatebuyerdata["GST_TYPE"] = this.tax_info_detai.find(
        (tax) => tax.TAX_SYS_ID == this.addupdatebuyerdata.TAX_SYS_ID
      ).TAX_NAME;
      this.addupdatebuyerdata["GST"] = this.addupdatebuyerdata.GST;
      this.nullToZeroChangeForBuyer();
      this.axios
        .post(apiEndPoints.add_update_buyer_details, this.addupdatebuyerdata)
        .then((response) => {
          if (response.data.status == "true") {
            this.getBuyerDetails(this.addupdatebuyerdata.RECORD_SYS_ID);
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Update Buyer Below Section Form
    //Author : Prasanta Saren
    addUpdateBuyerBelowSectionDetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addupdatebuyerdata["CREATED_BY"] = user_details.UserId;
      if (this.GLOBAL_BUYER_CUSTOMER_ID == "")
        this.addupdatebuyerdata["ITEM"] = "ADD";
      else this.addupdatebuyerdata["ITEM"] = "UPDATE";
      this.addupdatebuyerdata["GST_TYPE"] = this.tax_info_detai.find(
        (tax) => tax.TAX_SYS_ID == this.addupdatebuyerdata.TAX_SYS_ID
      ).TAX_NAME;
      this.addupdatebuyerdata["GST"] = this.addupdatebuyerdata.GST;
      this.addupdatebuyerdata["COM_RTN"] = "0";
      this.addupdatebuyerdata["COM_RTN_2"] = "0";
      this.addupdatebuyerdata["TRANSFER_OUT_BY_USER_SYS_ID"] =
        user_details.UserId;
      this.nullToZeroChangeForBuyer();
      this.axios
        .post(apiEndPoints.add_update_buyer_details, this.addupdatebuyerdata)
        .then((response) => {
          if (response.data.status == "true") {
            this.uploadLogCardOutForBuyerDetails();
            this.getBuyerDetails(this.addupdatebuyerdata.RECORD_SYS_ID);
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (err.response.status != 401 || err.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Handle Log Card Out File Object
    //Author : Prasanta Saren
    handleLogCardOutFileObject() {
      let file = this.$refs.logCardOutFile.files[0];
      let fileName = file.name;
      let fileExtension = fileName.split(".").pop();
      const today = new Date();
      const date =
        today.getFullYear() +
        "_" +
        (today.getMonth() + 1) +
        "_" +
        today.getDate();
      const time =
        today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds();
      let reFileName =
        this.GLOBAL_RECORD_SYS_ID +
        "_" +
        this.LOCAL_VEHICLE_NUMBER +
        "_" +
        date +
        "_" +
        time +
        "_" +
        "BUYER." +
        fileExtension;
      this.addupdatebuyerdata.LOG_CARD_TRANSFER_OUT = reFileName;
      this.BUYER_LOG_CARD_TRANSFER_OUT = new File([file], reFileName, {
        type: file.type,
      });
    },
    handleLogCardOut2FileObject() {
      let file = this.$refs.logCardOut2File.files[0];
      let fileName = file.name;
      let fileExtension = fileName.split(".").pop();
      const today = new Date();
      const date =
        today.getFullYear() +
        "_" +
        (today.getMonth() + 1) +
        "_" +
        today.getDate();
      const time =
        today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds();
      let reFileName =
        this.GLOBAL_RECORD_SYS_ID +
        "_" +
        this.LOCAL_VEHICLE_NUMBER +
        "_" +
        date +
        "_" +
        time +
        "_" +
        "BUYER." +
        fileExtension;
      this.addupdatebuyerdata.LOG_CARD_TRANSFER_OUT_2 = reFileName;
      this.BUYER_LOG_CARD_TRANSFER_OUT_2 = new File([file], reFileName, {
        type: file.type,
      });
    },

    //Function To Upload Log Card Out File In Buyer
    //Author : Prasanta Saren
    uploadLogCardOutForBuyerDetails() {
      let formData = new FormData();
      formData.append(
        "BUYER_LOG_CARD_TRANSFER_OUT",
        this.BUYER_LOG_CARD_TRANSFER_OUT
      );
      formData.append(
        "BUYER_LOG_CARD_TRANSFER_OUT_2",
        this.BUYER_LOG_CARD_TRANSFER_OUT_2
      );
      formData.append("ITEM", "BUYER_LOG_CARD_TRANSFER_OUT");
      this.axios
        .post(apiEndPoints.file_upload, formData, {
          headers: {
            "Content-Type":
              "multipart/form-data; charset=utf-8; boundary=" +
              Math.random().toString().substr(2),
          },
        })
        .then((response) => {
          this.$refs.logCardOutFile.value = null;
          this.$refs.logCardOut2File.value = null;
        })
        .catch((err) => {})
        .finally(() => {});
    },

    //Function To Get bank Name For Buyer AR Pyament Add
    //Author :
    getBankNameForBuyer() {
      this.addupdatebuyerapdata.BANK_NAME = this.Banklist.find(
        (o) => o.BANK_SYS_ID === this.addupdatebuyerapdata.BANK_SYS_ID
      ).BANK_NAME;
    },

    //Function To AR Amount Calculation For Buyer Payment Add
    //Author : Prasanta Saren
    addARAmountCalculation() {
      if (this.addupdatebuyerapdata.AMOUNT) {
        let reducedReceivableAmount = this.VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT;
        let amount = this.addupdatebuyerapdata.AMOUNT;
        let balance = parseFloat(reducedReceivableAmount) - parseFloat(amount);
        this.AR_REDUCED_RECEIVABLE_AMOUNT = balance;
      }
    },

    //Function To Get Buyer AR Payment Details
    //Author :
    getBuyerARPaymentDetails(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.view_ap_payment_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: RECORD_SYS_ID,
            P_MODULE: "BUYER",
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.buyerapdetails = response.data.response;
            this.updat_user.REDUCED_PURCHASE_AMOUNT =
              response.data.response[0].REDUCED_PURCHASE_AMOUNT;
          } else this.buyerapdetails = [];
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Reset Buyer AR Payment Form
    //Author :
    resetBuyerARPaymentForm() {
      (this.addupdatebuyerapdata.PAYMENT_DESC = ""),
        (this.addupdatebuyerapdata.AMOUNT = ""),
        (this.addupdatebuyerapdata.BALANCE = 0),
        (this.addupdatebuyerapdata.ID_NUMBER1 = ""),
        (this.addupdatebuyerapdata.ID_NUMBER = ""),
        (this.addupdatebuyerapdata.VENDORCODE = ""),
        (this.addupdatebuyerapdata.CUSTOMERTYPE = ""),
        (this.addupdatebuyerapdata.PAYMENT_MODE = ""),
        (this.addupdatebuyerapdata.BANK_NAME = ""),
        (this.addupdatebuyerapdata.BANK_SYS_ID = ""),
        (this.addupdatebuyerapdata.ADMIN_FEE = ""),
        (this.addupdatebuyerapdata.CHEQUE_DATE = ""),
        (this.addupdatebuyerapdata.CHEQUE_NO = ""),
        (this.addupdatebuyerapdata.TO_FROM_WHOM = ""),
        (this.addupdatebuyerapdata.SETTLEMENT_REMARKS = ""),
        (this.addupdatebuyerapdata.REMARKS = ""),
        (this.addupdatebuyerapdata.PAYMENT_DESC_OTHER = "");
    },

    //Function To Add Buyer AR Payment Details
    //Author :
    addBuyerARPaymentDetails() {
      if (
        this.addupdatebuyerapdata.PAYMENT_DESC == "" ||
        this.addupdatebuyerapdata.PAYMENT_DESC == "null" ||
        this.addupdatebuyerapdata.PAYMENT_DESC == null
      ) {
        Global.showErrorAlert(true, "error", "AR Payment Field Is Required!");
        this.AR_REDUCED_RECEIVABLE_AMOUNT =
          this.VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT;
        return false;
      } else if (
        this.addupdatebuyerapdata.AMOUNT == "" ||
        this.addupdatebuyerapdata.AMOUNT == "null" ||
        this.addupdatebuyerapdata.AMOUNT == null
      ) {
        Global.showErrorAlert(true, "error", "Amount Field Is Required!");
        this.AR_REDUCED_RECEIVABLE_AMOUNT =
          this.VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT;
        return false;
      } else if (
        this.addupdatebuyerapdata.PAYMENT_MODE == "" ||
        this.addupdatebuyerapdata.PAYMENT_MODE == "null" ||
        this.addupdatebuyerapdata.PAYMENT_MODE == null
      ) {
        Global.showErrorAlert(true, "error", "Payment Mode Field Is Required!");
        this.AR_REDUCED_RECEIVABLE_AMOUNT =
          this.VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT;
        return false;
      } else if (
        this.addupdatebuyerapdata.TO_FROM_WHOM == "" ||
        this.addupdatebuyerapdata.TO_FROM_WHOM == "null" ||
        this.addupdatebuyerapdata.TO_FROM_WHOM == null
      ) {
        Global.showErrorAlert(true, "error", "To/From Who Field Is Required!");
        this.AR_REDUCED_RECEIVABLE_AMOUNT =
          this.VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT;
        return false;
      }
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addupdatebuyerapdata["CREATED_BY"] = user_details.UserId;
      this.addupdatebuyerapdata["ITEM"] = "ADD";
      this.addupdatebuyerapdata["BALANCE"] = this.AR_REDUCED_RECEIVABLE_AMOUNT;
      if (this.addupdatebuyerapdata.PAYMENT_DESC == "Others")
        this.addupdatebuyerapdata["PAYMENT_DESC"] =
          this.addupdatebuyerapdata.PAYMENT_DESC_OTHER;
      if (this.addupdatebuyerapdata.BANK_SYS_ID == "")
        this.addupdatebuyerapdata["BANK_SYS_ID"] = "0";
      this.axios
        .post(
          apiEndPoints.add_update_ap_payment_details,
          this.addupdatebuyerapdata
        )
        .then((response) => {
          if (response.data.status == "true") {
            this.resetBuyerARPaymentForm();
            Global.showSuccessAlert(true, "success", response.data.response);
            this.VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT =
              this.AR_REDUCED_RECEIVABLE_AMOUNT;
            this.getBuyerARPaymentDetails(
              this.addupdatebuyerapdata.RECORD_SYS_ID
            );
          } else if (response.data.status == false || "false") {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Calculate Amount For Buyer AR Payment Update
    //Author : Prasanta Saren
    updateBuyerARAmountCalculation() {
      if (this.updat_user.AMOUNT) {
        let reducedReceivableAmount = this.VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT;
        let amount = this.updat_user.AMOUNT;
        let balance = parseFloat(reducedReceivableAmount) - parseFloat(amount);
        this.AR_REDUCED_RECEIVABLE_AMOUNT = balance;
      }
    },

    //Function To Get bank Name For Buyer AR Pyament Update
    //Author :
    getBankNameForBuyerARPaymentUpdate() {
      this.updat_user.BANK_NAME = this.Banklist.find(
        (o) => o.BANK_SYS_ID === this.updat_user.BANK_SYS_ID
      ).BANK_NAME;
    },

    //Function To Update Buyer AR Payment
    //Author :
    updateBuyerARPayment() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.updat_user["CREATED_BY"] = user_details.UserId;
      this.updat_user["ITEM"] = "UPDATE";
      this.updat_user["RECORD_SYS_ID"] = this.updat_user.VEHICLE_SYS_ID;
      this.updat_user["BALANCE"] = this.AR_REDUCED_RECEIVABLE_AMOUNT;
      this.axios
        .post(apiEndPoints.add_update_ap_payment_details, this.updat_user)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT =
              this.AR_REDUCED_RECEIVABLE_AMOUNT;
            this.getBuyerARPaymentDetails(this.updat_user.VEHICLE_SYS_ID);
            $("#buyerAPPaymentUpdateModal").modal("hide");
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Delete Buyer AR Payment Details
    //Author :
    async deleteBuyerARPayment(data) {
      const result = await Global.showConfirmationAlert(
        "Are you sure?",
        "you want to delete this record!",
        "warning"
      );
      if (result.isConfirmed) {
        NProgress.start();
        NProgress.set(0.1);
        this.loading = true;
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarrydata = {
          ITEM: "DELETE",
          RECORD_SYS_ID: data.RECORD_SYS_ID,
          MODULE: data.MODULE,
          PAYMENT_DESC: data.PAYMENT_DESC,
          CREATED_BY: user_details.UserId,
        };
        this.axios
          .post(apiEndPoints.add_update_ap_payment_details, postarrydata)
          .then((response) => {
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getBuyerARPaymentDetails(
                this.addupdatebuyerapdata.RECORD_SYS_ID
              );
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          })
          .finally(() => {
            NProgress.done();
            this.loading = false;
          });
      }
    },

    //Function To Recalculate Payment Receivable
    //Author : Prasanta Saren
    recalculateReceivablePayment(ITEM) {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      this.axios
        .get(apiEndPoints.calculate_payment_receivable, {
          params: {
            ITEM: ITEM,
            VEHICLE_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true" || "TRUE") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          // if (err.response.status != 401 || err.response.status != 403) {
          //   Global.showErrorAlert(true, "error", "Something went wrong");
          // }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },
    //------------------------------For Buyer Details Section End-----------------------------

    //------------------------------For ETS Transaction Details Section Start-----------------

    //Function To Onchange ETS Paper Buyer
    //Author :
    onChnageETSPaperBuyer() {
      let TO_FROM_WHO = this.addupdateetsdata.TO_FROM_WHO;
      if (TO_FROM_WHO == "Vendor") {
        this.ETS_CUSTOMER_INFO = "";
        this.DisplayEtsCustomerType = "";
        this.DisplayEtsCustomerIdNumber = "";
        this.DisplayEtsCustomerName = "";
        this.DisplayEtsCustomerAddress = "";
        this.axios
          .get(apiEndPoints.view_vendor, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 100,
              PAGE_NO: 10,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllEtsVendorDetailsArray = response.data.response;
            } else {
              this.AllEtsVendorDetailsArray = "";
            }
          })
          .catch((err) => {
            //console.log(err);
            this.AllEtsVendorDetailsArray = "";
          });
      } else if (TO_FROM_WHO == "Customer") {
        this.DisplayEtsVendorName = "";
        this.DisplayEtsVendorCode = "";
        this.DisplayEtsVendorAddress = "";
        this.axios
          .get(apiEndPoints.view_customer, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 20,
              PAGE_NO: 1,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllEtsCustomerDetailsArray = response.data.response;
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    },

    //Function To Onchange ETS Vendor Info
    //Author :
    onChnageETSVendorInfo() {
      let vendorName = this.ETS_VENDOR_INFO;
      let vendorObj = this.AllEtsVendorDetailsArray.find(
        (items) => items.VENDOR_NAME == vendorName
      );
      if (vendorObj) {
        this.addupdateetsdata.CUSTOMER_SYS_ID = vendorObj.VENDOR_SYS_ID;
        this.addupdateetsdata.ETS_PAPER_BUYER = vendorObj.VENDOR_NAME;
        this.DisplayEtsVendorName = vendorObj.VENDOR_NAME;
        this.DisplayEtsVendorCode = vendorObj.VENDOR_CODE;
        this.DisplayEtsVendorAddress =
          vendorObj.ADDRESS_LINE_1 +
          "," +
          vendorObj.COUNTRY_NAME +
          "-" +
          vendorObj.PIN_CODE;
      }
    },

    //Function To Onkeyup ETS Vendor Info
    //Author :
    onKeyUpETSVendorInfo() {
      if (this.ETS_VENDOR_INFO == "") {
        this.DisplayEtsVendorName = "";
        this.DisplayEtsVendorCode = "";
        this.DisplayEtsVendorAddress = "";
        this.axios
          .get(apiEndPoints.view_vendor, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 100,
              PAGE_NO: 10,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllEtsVendorDetailsArray = response.data.response;
            } else {
              this.AllEtsVendorDetailsArray = "";
            }
          })
          .catch((err) => {
            //console.log(err);
            this.AllEtsVendorDetailsArray = "";
          });
      }
    },

    //Function To Onkeyup ETS Customer Info
    //Author :
    onKeyUpETSCustomerInfo() {
      if (this.ETS_CUSTOMER_INFO == "") {
        this.DisplayEtsCustomerType = "";
        this.DisplayEtsCustomerIdNumber = "";
        this.DisplayEtsCustomerName = "";
        this.DisplayEtsCustomerAddress = "";
        this.axios
          .get(apiEndPoints.view_customer, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 100,
              PAGE_NO: 10,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllEtsCustomerDetailsArray = response.data.response;
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    },

    //Function To Onchange ETS Customer Info
    //Author :
    onChnageETSCustomerInfo() {
      let CUSTOMER_NAME = this.ETS_CUSTOMER_INFO;
      let cusObj = this.AllEtsCustomerDetailsArray.find(
        (items) => items.CUSTOMER_NAME == CUSTOMER_NAME
      );
      if (cusObj) {
        this.addupdateetsdata.CUSTOMER_SYS_ID = cusObj.CUSTOMER_SYS_ID;
        this.addupdateetsdata.ETS_PAPER_BUYER = cusObj.CUSTOMER_NAME;
        this.DisplayEtsCustomerType = cusObj.CUSTOMER_TYPE;
        this.DisplayEtsCustomerIdNumber = cusObj.ID_NUMBER;
        this.DisplayEtsCustomerName = cusObj.CUSTOMER_NAME;
        let address =
          cusObj.ADDRESS_LINE_1 +
          " , " +
          cusObj.COUNTRY_NAME +
          "-" +
          cusObj.PIN_CODE;
        this.DisplayEtsCustomerAddress = address;
      }
    },

    //Function To Get ETS Transaction Details
    //Author :
    getETSTransactionDetails(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.view_ets_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.addupdateetsdata = response.data.response[0];
            this.BUY_OVER_DATE =
            response.data.response[0].BUY_OVER_DATE ||"-";
            this.FIGHT_PL =
            response.data.response[0].FIGHT_PL ||"-";
            this.ETS_PAPER_TO =
            response.data.response[0].ETS_PAPER_TO ||"-";
            this.DEREG_DATE =
            response.data.response[0].DEREG_DATE ||"-";
            this.COE_ENCASHMENT =
            response.data.response[0].COE_ENCASHMENT ||"-";
            this.BODY_PRICE =
            response.data.response[0].BODY_PRICE ||"-";
           
            this.ETS_TRANSFER_VALUE =
            response.data.response[0].ETS_TRANSFER_VALUE ||"-";
            this.INVOICE_NUMBER =
            response.data.response[0].INVOICE_NUMBER ||"-";
            this.ETS_PAPER_BUYER =
            response.data.response[0].ETS_PAPER_BUYER ||"-";
            this.BUY_OVER_DATE =
            response.data.response[0].BUY_OVER_DATE ||"-";











            this.addupdateetsdata.RECORD_SYS_ID = RECORD_SYS_ID;
            if (response.data.response[0].FIGHT_PL == "Y")
              this.addupdateetsdata.FIGHT_PL = true;
            else this.addupdateetsdata.FIGHT_PL = false;
            let CustomerType = response.data.response[0].TO_FROM_WHO;
            let ETS_PAPER_BUYER = response.data.response[0].ETS_PAPER_BUYER;
            if (CustomerType == "Customer") {
              let cusObj = this.AllCustomerArray.find(
                (items) => items.CUSTOMER_NAME == ETS_PAPER_BUYER
              );
              if (cusObj) {
                this.ETS_CUSTOMER_INFO = ETS_PAPER_BUYER;
                this.addupdateetsdata.CUSTOMER_SYS_ID = cusObj.CUSTOMER_SYS_ID;
                this.addupdateetsdata.ETS_PAPER_BUYER = cusObj.CUSTOMER_NAME;
                this.DisplayEtsCustomerType = cusObj.CUSTOMER_TYPE;
                this.DisplayEtsCustomerIdNumber = cusObj.ID_NUMBER;
                this.DisplayEtsCustomerName = cusObj.CUSTOMER_NAME;
                let address =
                  cusObj.ADDRESS_LINE_1 +
                  " , " +
                  cusObj.COUNTRY_NAME +
                  "-" +
                  cusObj.PIN_CODE;
                this.DisplayEtsCustomerAddress = address;
              }
            } else if (CustomerType == "Vendor") {
              let vendorObj = this.AllVendordetails.find(
                (items) => items.VENDOR_NAME == ETS_PAPER_BUYER
              );
              if (vendorObj) {
                this.ETS_VENDOR_INFO = ETS_PAPER_BUYER;
                this.addupdateetsdata.CUSTOMER_SYS_ID = vendorObj.VENDOR_SYS_ID;
                this.addupdateetsdata.ETS_PAPER_BUYER = vendorObj.VENDOR_NAME;
                this.DisplayEtsVendorName = vendorObj.VENDOR_NAME;
                this.DisplayEtsVendorCode = vendorObj.VENDOR_CODE;
                this.DisplayEtsVendorAddress =
                  vendorObj.ADDRESS_LINE_1 +
                  "," +
                  vendorObj.COUNTRY_NAME +
                  "-" +
                  vendorObj.PIN_CODE;
              }
            } else {
              this.ETS_CUSTOMER_INFO = "";
              this.ETS_VENDOR_INFO = "";
              this.DisplayEtsCustomerType = "";
              this.DisplayEtsCustomerIdNumber = "";
              this.DisplayEtsCustomerName = "";
              this.DisplayEtsCustomerAddress = "";
              this.DisplayEtsVendorName = "";
              this.DisplayEtsVendorCode = "";
              this.DisplayEtsVendorAddress = "";
            }
          }else{
            this.BUY_OVER_DATE =
          "-";
            this.FIGHT_PL =
          "-";
            this.ETS_PAPER_TO =
           "-";
            this.DEREG_DATE =
          "-";
            this.COE_ENCASHMENT =
           "-";
            this.BODY_PRICE =
           "-";
           
            this.ETS_TRANSFER_VALUE =
           "-";
            this.INVOICE_NUMBER =
         "-";
            this.ETS_PAPER_BUYER =
           "-";
            this.BUY_OVER_DATE =
          "-";
          }
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          let outputsObj = {};
          var obj = this.addupdateetsdata;
          Object.keys(obj).forEach(function (key, el) {
            if (
              obj[key] == null ||
              obj[key] == "null" ||
              obj[key] == "0" ||
              obj[key] == 0
            )
              outputsObj[key] = "";
            else outputsObj[key] = obj[key];
          });
          this.addupdateetsdata = outputsObj;
        });
      this.addupdateetsdata.RECORD_SYS_ID = RECORD_SYS_ID;
    },

    //Function To Add Update ETS Transaction Details
    //Author :
    addUpdateETSTransactionDetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addupdateetsdata["CREATED_BY"] = user_details.UserId;
      this.addupdateetsdata["ITEM"] = "ADD";
      this.addupdateetsdata["FIGHT_PL"] =
        this.addupdateetsdata.FIGHT_PL == true ? "Y" : "N";
      //console.log(JSON.stringify(this.addupdateetsdata));
      this.axios
        .post(apiEndPoints.add_update_ets_details, this.addupdateetsdata)
        .then((response) => {
          if (response.data.status == "true") {
            this.getETSTransactionDetails(this.addupdateetsdata.RECORD_SYS_ID);
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },
    //------------------------------For ETS Transaction Details Section End------------------------

    //------------------------------For Sales Receipt Section Start--------------------------------
    //For Insurance Info
    //Function To Get Insurance Company Name
    //Author : Prasanta Saren
    getInsuranceCompanyName() {
      this.axios
        .get(apiEndPoints.insurance_company_api, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.insurance_company_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.insurance_company_details = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //pdf download pl report
    plreportpdfdownload(){
      this.$refs.PLREPORTNEWPDFDONE.generatePdf();
    },

    //Function To Get Insurance Info Details
    //Author :
    getInsuranceCompanyDetails(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.view_insurance_information, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.InsuranceInfoDetails = response.data.response[0];
            this.INSURANCE_PREMIUM=response.data.response[0].PREMIUM_AMOUNT ||"-"
          
          }else{
            this.INSURANCE_PREMIUM="-"
          }
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          let outputsObj = {};
          var obj = this.InsuranceInfoDetails;
          Object.keys(obj).forEach(function (key, el) {
            if (
              obj[key] == null ||
              obj[key] == "null" ||
              obj[key] == "0" ||
              obj[key] == 0
            )
              outputsObj[key] = "";
            else outputsObj[key] = obj[key];
          });
          this.InsuranceInfoDetails = outputsObj;
        });
    },

    //Function To Reset Insurance Info form
    //Author : Prasanta Saren
    resetAddInsuranceForm() {
      this.InsuranceInfoDetails.INSURANCE_COMPANY_SYS_ID = "";
      this.InsuranceInfoDetails.PREMIUM_AMOUNT = "";
    },

    //Function To Add Insurance Info
    //Author : Prasanta Saren
    createInsuranceDetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.InsuranceInfoDetails["CREATED_BY"] = user_details.UserId;
      this.InsuranceInfoDetails.VEHICLE_SYS_ID = this.GLOBAL_RECORD_SYS_ID;
      this.InsuranceInfoDetails.ITEM = "INSURANCE_INFO";
      this.InsuranceInfoDetails.INSURANCE_COMPANY = this.insurance_company_details.find((o) => o.INSURANCE_COMPANY_SYS_ID === this.InsuranceInfoDetails.INSURANCE_COMPANY_SYS_ID).INSURANCE_COMPANY;
      this.axios
        .post(apiEndPoints.add_insurance_info, this.InsuranceInfoDetails)
        .then((response) => {
          if (response.data.status == "true") {
            this.resetAddInsuranceForm();
            this.getInsuranceCompanyDetails(this.GLOBAL_RECORD_SYS_ID);
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //For Trade Info
    //Function To Get All Vehicle Details For Trade Info
    //Author : Prasanta Saren
    getSearchAllVehicleDetailsForTradeInfo() {
      this.axios
        .get(apiEndPoints.view_vehicle_details, {
          params: {
            ITEM: "SEARCH_ETS_VEHICLE",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.search_all_vehicle_details = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Onchange Trade In Vehicle Number
    //Author : Prasanta Saren
    onChnageTradeInVehicleInfo(TRADE_IN_VEH_NO) {
      let vehicle_sys_id = this.search_all_vehicle_details.find(
        (item) => item.VEHICLE_NUMBER === TRADE_IN_VEH_NO
      ).RECORD_SYS_ID;
      this.autoCompleteValueForTradeInfo(vehicle_sys_id, TRADE_IN_VEH_NO);
    },

    //Function To Onkeyup Trade In Vehicle Number
    //Author : Prasanta Saren
    onKeyUpTradeInVehicleInfo(TRADE_IN_VEH_NO) {
      if (!TRADE_IN_VEH_NO) {
        this.getSearchAllVehicleDetailsForTradeInfo();
      }
    },

    formatMultiTradeInfo(data, TRADE_IN_VEH_NO='') {
      return data.map(item => {
        if (!item.FULL_SETTLEMENT_REIMBURSEMENT || item.FULL_SETTLEMENT_REIMBURSEMENT == '0' || item.FULL_SETTLEMENT_REIMBURSEMENT == 'null') {
          item.FULL_SETTLEMENT_REIMBURSEMENT = 0;
        }
        if (!item.PAYMENT_MADE || item.PAYMENT_MADE == '0' || item.PAYMENT_MADE == 'null') {
          item.PAYMENT_MADE = 0;
        }
        if (!item.TRADE_IN_AMOUNT || item.TRADE_IN_AMOUNT == '0' || item.TRADE_IN_AMOUNT == 'null') {
          item.TRADE_IN_AMOUNT = 0;
        }
        if (!item.TRADE_IN_VEH_NO || item.TRADE_IN_VEH_NO == '0' || item.TRADE_IN_VEH_NO == 'null') {
          item.TRADE_IN_VEH_NO = TRADE_IN_VEH_NO;
        }
        return item;
      });
    },

    onAddTradeInfo() {
      if (this.multiTradeInfo.length > 0) {
        this.trade_info.TRADE_IN_VEH_NO = this.multiTradeInfo[0].TRADE_IN_VEH_NO
      }
      const tradeInfo = JSON.parse(JSON.stringify(this.trade_info));
      this.multiTradeInfo.push(tradeInfo);
    },

    //Function To Get Vehicle Trade Details
    //Author : Prasanta Saren
    getVechileTradeDetatils() {
      this.axios
        .get(apiEndPoints.view_trade, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true" && response.data.response.length > 0) {
            this.multiTradeInfo = this.formatMultiTradeInfo(response.data.response);
          } else {
            const tradeInfo = JSON.parse(JSON.stringify(this.trade_info));
            this.multiTradeInfo.push(tradeInfo);
          }
        }).catch((err) => {
          //console.log(err);
        });
    },

    //Funbction To Auto Complete value For Trade Info
    autoCompleteValueForTradeInfo(vehicle_sys_id, TRADE_IN_VEH_NO) {
      this.axios
        .get(apiEndPoints.view_specific_vehicle_trade, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: vehicle_sys_id,
          },
        })
        .then((response) => {
          if (response.data.status == "true" && response.data.response.length > 0) {
            this.multiTradeInfo = this.formatMultiTradeInfo(response.data.response, TRADE_IN_VEH_NO);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Add Trade Info
    //Author : Prasanta Saren
    AddTradeInfo(tradeInfo) {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      tradeInfo["ITEM"] = "ADD";
      tradeInfo["CREATED_BY"] = user_details.UserId;
      tradeInfo.VEHICLE_SYS_ID = this.GLOBAL_RECORD_SYS_ID;
      this.axios
        .post(apiEndPoints.add_trade, tradeInfo)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getVechileTradeDetatils(this.GLOBAL_RECORD_SYS_ID);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //For Other Info Tab
    //Function To Onchange Tax in Other Payment Tab
    //Author : Prasanta Saren
    onchangeTaxInOtherPaymentTab() {
      let TAX_PERCENTAGE = this.tax_info_details.find(
        (o) => o.TAX_SYS_ID === this.addotherpayment.TAX_SYS_ID
      ).TAX_PERCENTAGE;
      let RATE = this.addotherpayment.UNIT_PRICE;
      let TAX_AMOUNT = (RATE * TAX_PERCENTAGE) / 100;
      let GROSS_AMOUNT = parseInt(RATE) + TAX_AMOUNT;
      this.addotherpayment.TAX_VALUE = TAX_AMOUNT.toFixed(2);
      this.addotherpayment.TOTAL_PRICE = GROSS_AMOUNT.toFixed(2);
    },

    //Function To Reset Other Payment Form
    //Author : Prasanta Saren
    resetOtherPaymentForm() {
      this.addotherpayment.TAX_SYS_ID = "";
      this.addotherpayment.TAX_VALUE = "";
      this.addotherpayment.TOTAL_PRICE = "";
      this.addotherpayment.UNIT_PRICE = "";
      this.addotherpayment.DESCRIPTION = "";
      this.addotherpayment.DESCRIPTION_OTHER = "";
      this.addotherpayment.RATE_WITHOUT_TAX = "";
    },

    //Function To Add Other Payment
    //Author : Prasanta Saren
    AddOtherPayment() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addotherpayment["CREATED_BY"] = user_details.UserId;
      this.addotherpayment["VEHICLE_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;
      if (this.addotherpayment.DESCRIPTION == "Others Fee") {
        this.addotherpayment["DESCRIPTION"] =
          this.addotherpayment.DESCRIPTION_OTHER;
      }
      this.axios
        .post(apiEndPoints.create_other_payment, this.addotherpayment)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.resetOtherPaymentForm();
            this.getOtherPayment();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Delete Other Payment
    //Author : Prasanta Saren
    async deleteOtherPaymentDetails(data) {
      const result = await Global.showConfirmationAlert(
        "Are you sure?",
        "you want to delete this record!",
        "warning"
      );
      if (result.isConfirmed) {
        NProgress.start();
        NProgress.set(0.1);
        this.loading = true;
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let inputsObj = {
          ITEM: "DELETE",
          RECORD_SYS_ID: data.RECORD_SYS_ID,
          VEHICLE_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
          DESCRIPTION: data.DESCRIPTION,
          TOTAL_PRICE: data.TOTAL_PRICE,
          CREATED_BY: user_details.UserId,
        };
        this.axios
          .post(apiEndPoints.create_other_payment, inputsObj)
          .then((response) => {
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getOtherPayment();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          })
          .finally(() => {
            NProgress.done();
            this.loading = false;
          });
      }
    },

    //Function To Get Other Payment Details
    //Author : Prasanta Saren
    getOtherPayment() {
      this.axios
        .get(apiEndPoints.view_otherpayment, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.OTHER_PAYMENT_DETAILS = response.data.response;
            // this.TOTAL_PRICE= response.data.response[0].TOTAL_PRICE||"-"
          } else {
            this.OTHER_PAYMENT_DETAILS = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Update Other Payment
    //Author : Prasanta Saren
    UpdateOtherPayment() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.updat_user["ITEM"] = "UPDATE";
      this.updat_user["CREATED_BY"] = user_details.UserId;
      this.updat_user["VEHICLE_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;
      if (this.updat_user.DESCRIPTION == "Others Fee") {
        this.updat_user["DESCRIPTION"] = this.updat_user.DESCRIPTION_OTHER;
      }
      this.axios
        .post(apiEndPoints.create_other_payment, this.updat_user)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getOtherPayment();
            $("#update_other_payment_modal").modal("hide");
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //For Sales Receipt Tab
    //Function To Get Sales Receipt Note Details
    //Author :
    getSalesReceiptNoteDetails() {
      this.axios
        .get(apiEndPoints.view_sales_note_info, {
          params: {
            ITEM: "CUSTOMER",
            VEHICLE_NO: "null",
            RECORD_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "false") {
            this.truesales = false;
          } else {
            this.truesales = true;
            this.SalesReceiptNoteDetails = response.data.response[0];
            let TRADE_IN_AMOUNT = response.data.response[0].TRADE_IN_AMOUNT;
            let PURCHASE_SETTLEMENT_AMOUNT =
              response.data.response[0].PURCHASE_SETTLEMENT_AMOUNT;
            let FINANCE_DUE = TRADE_IN_AMOUNT - PURCHASE_SETTLEMENT_AMOUNT;
            this.calculation = FINANCE_DUE.toFixed(2);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function to save invoice no and date in sales recipt
    //Author : Riju mukherjee
    AddInvoiceNoAndDateForSalesReceipt() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.salesinvoicedata["ITEM"] = "SALES_RECEIPT";
      this.salesinvoicedata["VEHICLE_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;
      this.salesinvoicedata["CREATED_BY"] = user_details.UserId;
      this.axios
        .post(apiEndPoints.vehicle_document_add, this.salesinvoicedata)
        .then((response) => {
          if (response.data.status == "true") {
            this.salesinvoicedata.SALES_RECEIPT_INVOICE_NO = "";
            this.salesinvoicedata.SALES_RECEIPT_INVOICE_DATE = "";
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getSalesReceiptNoteDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Generate Sales Receipt PDF
    //Author :
    generateReportsales() {
      this.$refs.htmlPdfForSalesReceipt.generatePdf();
    },

    //------------------------------For Sales Receipt Section End--------------------------------

    //------------------------------For Loan Info Section Start----------------------------------
    //For Loan Info Tab
    //Finance Company Onchange Function
    //Author : Prasanta Saren
    financeCompanyOnchangeFunc() {
      this.loan_info.FINANCE_COMPANY = this.FinanceCompanyDetails.find(
        (role) => role.FINANCE_COMPANY_SYS_ID == this.loan_info.FIN_COMP_SYS_ID
      ).FINANCE_COMPANY;
    },

    //Loan Amount Calculation
    //Author : Prasanta Saren
    loanInfoCalculation() {
      let loanAmount = this.loan_info.LOAN_AMOUNT;
      let loanTenure = this.loan_info.LOAN_TENURE;
      let loanInRate = this.loan_info.INTEREST_RATE;
      if (loanAmount != "" && loanTenure != "" && loanInRate != "") {
        let interestAmount =
          (parseInt(loanAmount) *
            (parseInt(loanTenure) / 12) *
            parseFloat(loanInRate)) /
          100;
        this.loan_info.INTEREST_AMOUNT = interestAmount.toFixed(2);
        let subTotal = parseFloat(interestAmount) + parseFloat(loanAmount);
        this.loan_info.SUBTOTAL = subTotal.toFixed(2);
        let value = (this.loan_info.SUBTOTAL * parseFloat(7)) / 100;
        this.calculategstvalue = value;
        let monthlyInstalment =
          parseFloat(subTotal) / (parseInt(loanTenure) / 12) / 12;
        this.loan_info.ADVANCE_PAYMENT = monthlyInstalment.toFixed(2);
        this.loan_info.ADVANCE_PAYMENT_BEFORE_ADJUSTMENT =
          monthlyInstalment.toFixed(2);
        this.globalCalculateGST("LOAN_INFO");
      }
      if (loanAmount == "" || loanTenure == "" || loanInRate == "") {
        this.loan_info.INTEREST_AMOUNT = "";
        this.loan_info.SUBTOTAL = "";
        this.loan_info.ADVANCE_PAYMENT = "";
      }
    },

    //Loan Amount OnkeyUp Enter Function For Loan Info
    //Author : Prasanta Saren
    loanAmountKeyupFunc() {
      let loanAmount = this.loan_info.LOAN_AMOUNT;
      if (loanAmount != "") {
        this.globalCalculateGST("LOAN_INFO");
      }
    },

    //Loan Commision OnkeyUp Function For Loan Info
    //Author : Prasanta Saren
    loanCommisionCalculator() {
      let loanAmount = this.loan_info.LOAN_AMOUNT;
      let loanComPercentage = this.loan_info.LOAN_COMMISSION_PERCENTAGE;
      if (loanComPercentage != "") {
        let loanComAmount =
          (parseInt(loanAmount) * parseFloat(loanComPercentage)) / 100;
        this.loan_info.LOAN_COMMISSION = loanComAmount.toFixed(2);
      } else {
        this.loan_info.LOAN_COMMISSION = null;
      }
    },

    //Add Loan Info
    //Author : Prasanta Saren
    AddLoanInfo() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      //this.globalCalculateGST('LOAN_INFO');
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.loan_info["CREATED_BY"] = user_details.UserId;
      if (this.GLOBAL_LOAN_INFO_FIN_COMP_SYS_ID == "")
        this.loan_info["ITEM"] = "ADD";
      else this.loan_info["ITEM"] = "UPDATE";
      let inputsObj = {};
      var obj = this.loan_info;
      Object.keys(obj).forEach(function (key, el) {
        if (obj[key] == "") inputsObj[key] = "0.0";
        else inputsObj[key] = obj[key];
      });
      this.axios
        .post(apiEndPoints.add_loan, inputsObj)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.resetVehicleLoanInfoForm();
            this.getVechileLoanDetails(this.loan_info.RECORD_SYS_ID);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Add Loan Info
    //Author : Prasanta Saren
    async DeleteLoanInfo() {
      const result = await Global.showConfirmationAlert(
        "Are you sure?",
        "you want to delete this loan info!",
        "warning"
      );
      if (result.isConfirmed) {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.loan_info["CREATED_BY"] = user_details.UserId;
      this.loan_info["ITEM"] = "DELETE";
      this.axios.post(apiEndPoints.add_loan, this.loan_info).then((response) => {
          if (response.data.status == "true") {
            this.GLOBAL_LOAN_INFO_FIN_COMP_SYS_ID = "";
            Global.showSuccessAlert(true, "success", response.data.response);
            this.resetVehicleLoanInfoForm();
          } 
          else if ( response.data.status == false || response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
      }
    },

    //Reset Add Vehicle Loan Info form
    //Author : Prasanta Saren
    resetVehicleLoanInfoForm() {
      this.loan_info.FINANCE_COMPANY = "";
      this.loan_info.FIN_COMP_SYS_ID = "";
      this.loan_info.LOAN_TAX_AMOUNT = "";
      this.loan_info.LOAN_TAX_SYS_ID = "";
      this.loan_info.LOAN_AMOUNT_WITHOUT_TAX = "";
      this.loan_info.LOAN_TENURE = "";
      this.loan_info.INTEREST_RATE = "";
      this.loan_info.INTEREST_AMOUNT = "";
      this.loan_info.ADVANCE_PAYMENT = "";
      this.loan_info.SUBTOTAL = "";
      this.loan_info.LOAN_COMMISSION_PERCENTAGE = "";
      this.loan_info.LOAN_COMMISSION = "";
      this.loan_info.SALES_COMMISSION = "";
      this.loan_info.HP_FACILITIES_FEE = "";
      this.loan_info.INVOICE_NO = "";
      this.loan_info.BANK_SYS_ID = "";
      this.loan_info.LOAN_TAX_DECIMAL_ADJUSTMENT = "";
      this.loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT = "";
      this.loan_info.ADVANCE_PAYMENT_DECIMAL_ADJUSTMENT = "";
      this.loan_info.ADVANCE_PAYMENT_BEFORE_ADJUSTMENT = "";
      this.loan_info.LOAN_AMOUNT = "";
    },

    //Get Vehicle Loan Info Details
    //Author : Prasanta Saren
    getVechileLoanDetails(vechile_id) {
      this.axios
        .get(apiEndPoints.view_loan, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: vechile_id,
          },
        })
        .then((response) => {
        
            let loan_details = response.data.response[0];

            this.LOAN_AMOUNT_NEW =
            response.data.response[0].LOAN_AMOUNT ||"-";
            this.ADVANCE_PAYMENT =
            response.data.response[0].ADVANCE_PAYMENT ||"-";
            this.LOAN_COMMISSION =
            response.data.response[0].LOAN_COMMISSION ||"-";
            this.INTEREST_RATE =
            response.data.response[0].INTEREST_RATE ||"-";
            this.LOAN_TENURE =
            response.data.response[0].LOAN_TENURE ||"-";
            this.FINANCE_COMPANY =
            response.data.response[0].FINANCE_COMPANY ||"-";
         
         



            this.GLOBAL_LOAN_INFO_FIN_COMP_SYS_ID =
              loan_details.FIN_COMP_SYS_ID;
            this.loan_info.FIN_COMP_SYS_ID = loan_details.FIN_COMP_SYS_ID;
            this.loan_info.FINANCE_COMPANY = loan_details.FINANCE_COMPANY;
            this.loan_info.BANK_SYS_ID = loan_details.BANK_SYS_ID;
            this.loan_info.INVOICE_NO = loan_details.INVOICE_NO;
            this.loan_info.LOAN_AMOUNT = loan_details.LOAN_AMOUNT;
            this.loan_info.LOAN_TENURE = loan_details.LOAN_TENURE;
            this.loan_info.INTEREST_RATE = loan_details.INTEREST_RATE;
            this.loan_info.ADVANCE_PAYMENT = loan_details.ADVANCE_PAYMENT;
            this.loan_info.ADVANCE_PAYMENT_BEFORE_ADJUSTMENT =
              loan_details.ADVANCE_PAYMENT_BEFORE_ADJUSTMENT;
            this.loan_info.ADVANCE_PAYMENT_DECIMAL_ADJUSTMENT =
              loan_details.ADVANCE_PAYMENT_DECIMAL_ADJUSTMENT;
            this.loan_info.INTEREST_AMOUNT = loan_details.INTEREST_AMOUNT;
            let subTotal =
              parseFloat(loan_details.INTEREST_AMOUNT) +
              parseFloat(loan_details.LOAN_AMOUNT);
            this.loan_info.SUBTOTAL = subTotal.toFixed(2);
            this.loan_info.LOAN_TAX_SYS_ID = loan_details.LOAN_TAX_SYS_ID;
            this.loan_info.LOAN_TAX_AMOUNT = loan_details.LOAN_TAX_AMOUNT;
            this.loan_info.LOAN_AMOUNT_WITHOUT_TAX =
              loan_details.LOAN_AMOUNT_WITHOUT_TAX;
            this.loan_info.HP_FACILITIES_FEE = loan_details.HP_FACILITIES_FEE;
            this.loan_info.LOAN_COMMISSION_PERCENTAGE =
              loan_details.LOAN_COMMISSION_PERCENTAGE;
            this.loan_info.LOAN_COMMISSION = loan_details.LOAN_COMMISSION;
            this.loan_info.SALES_COMMISSION = loan_details.SALES_COMMISSION;
            this.loan_info.LOAN_TAX_DECIMAL_ADJUSTMENT =
              loan_details.LOAN_TAX_DECIMAL_ADJUSTMENT;
            this.loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT =
              loan_details.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT;
          
        })
        .catch((err) => {
          this.LOAN_AMOUNT_NEW = "-";
            this.ADVANCE_PAYMENT =  "-";
            this.LOAN_COMMISSION =
          "-";
            this.INTEREST_RATE =
        "-";
            this.LOAN_TENURE =
         "-";
            this.FINANCE_COMPANY =
           "-";
        })
        .finally(() => {
          let outputsObj = {};
          var obj = this.loan_info;
          Object.keys(obj).forEach(function (key, el) {
            if (
              obj[key] == null ||
              obj[key] == "null" ||
              obj[key] == "0" ||
              obj[key] == 0
            )
              outputsObj[key] = "";
            else outputsObj[key] = obj[key];
          });
          this.loan_info = outputsObj;
        });
    },

    //For Finance Company Tax Invoice Tab
    //Function to Get Finance Company Tax Invoice details
    //Author : Prasanta Saren
    getFinanceCompanyInvoiceDetailsWithTax() {
      this.axios
        .get(apiEndPoints.finace_company_tax, {
          params: {
            ITEM: "FINANCE_COMPANY_WITH_TAX_INVOICE",
            VEHICLE_NO: "null",
            RECORD_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
          } else {
            this.finance_company_tax_invoice = response.data.response[0];
          }
        })
        .catch((err) => {
          //console.log("axiosErr", err);
        });
    },

    //Function To Download Finance Company Tax Invoice PDF
    //Author :
    generateReporttaxinvoicefinancial() {
      this.$refs.htmlPdfForFinanceCompanyTaxInvoice.generatePdf();
    },

    //Function To Add Invoice No And Date In Finance Company Tax Invoice
    //Author : Riju mukherjee
    AddInvoiceNumberAndDateForFinanceCompanyTaxInvoice() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.financetaxinvoicedata["ITEM"] = "FINANCE_TAX_INVOICE";
      this.financetaxinvoicedata["CREATED_BY"] = user_details.UserId;
      this.axios
        .post(apiEndPoints.vehicle_document_add, this.financetaxinvoicedata)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.ResetInvoiceNumberAndDateForFinanceCompanyTaxInvoice();
            this.getFinanceCompanyInvoiceDetailsWithTax();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Reset Invoice No And Date In Finance Company Tax Invoice
    //Author : Riju mukherjee
    ResetInvoiceNumberAndDateForFinanceCompanyTaxInvoice() {
      this.financetaxinvoicedata.FINANCE_TAX_INVOICE_DATE = "";
      this.financetaxinvoicedata.FINANCE_TAX_INVOICE_NO = "";
    },

    //For Finance Company Invoice Tab
    //Function to Get Finance Company Invoice details
    //Author :
    getFinanceCompanyInvoiceDetailsWithoutTax() {
      this.axios
        .get(apiEndPoints.finace_company_tax, {
          params: {
            ITEM: "FINANCE_COMPANY_WITHOUT_TAX_INVOICE",
            VEHICLE_NO: "null",
            RECORD_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
          } else {
            this.finance_company_invoice = response.data.response[0];
            this.VEHICLE_NO =response.data.response[0].VEHICLE_NUMBER
            this.CHASSIS_NO =response.data.response[0].CHASIS_NO
            this.ENGINE_NO =response.data.response[0].ENGINE_NO
            this.FINANCE_COMPANY_NAME =response.data.response[0].FINANCE_COMPANY
            this.FINANCE_COMPANY_ADDRESS =response.data.response[0].FINANCE_COMPANY_ADDRESS
            this.INVOICE_NO =response.data.response[0].INVOICE_NO
            this.INVOICE_DATE =response.data.response[0].TAX_INVOICE_DATE
            this.CUSTOMER_NAME =response.data.response[0].CUSTOMER_NAME
            this.SELLING_PRICE =response.data.response[0].SELLING_PRICE
            this.DEPOSIT_PRICE =response.data.response[0].DEPOSIT_AMOUNT
            this.LOAN_AMOUNT =response.data.response[0].LOAN_AMOUNT
            this.TOTAL_PAYABLE =response.data.response[0].V_TOTAL_PAID
          }
        })
        .catch((err) => {
          //console.log("axiosErr", err);
        });
    },

    //Function To Add Invoice No And Date In Finance Company Invoice
    //Author : Riju mukherjee
    AddInvoiceNumberAndDateForFinanceCompanyInvoice() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.finance_company_invoice_form["ITEM"] = "FINANCE_COMPANY_INVOICE";
      this.finance_company_invoice_form["VEHICLE_SYS_ID"] =
        this.GLOBAL_RECORD_SYS_ID;
      this.finance_company_invoice_form["CREATED_BY"] = user_details.UserId;
      this.axios
        .post(
          apiEndPoints.vehicle_document_add,
          this.finance_company_invoice_form
        )
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.finance_company_invoice_form.FINANCE_COMPANY_INVOICE_NO = "";
            this.finance_company_invoice_form.FINANCE_COMPANY_INVOICE_DATE = "";
            this.getFinanceCompanyInvoiceDetailsWithoutTax();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Generate Finance Company Invoice PDF
    //Author :
    generateReportForFCompanyInvoice() {
      this.$refs.htmlPdfForFinanceCompanyInvoice.generatePdf();
    },

    //For Official Receipt Tab
    //Function To Get Official Receipt Details List
    //Author : Prasanta Saren
    getOfficialReceiptDetailsList() {
      this.axios
        .get(apiEndPoints.vehic_cost, {
          params: {
            ITEM: "RECEIPT",
            RECORD_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.OFFICIAL_RECEIPT_DETAILS_LIST = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Onchange Officialreceipt Customer Details
    //Author : Prasanta Saren
    onchangeOfficialReceiptCustomer() {
      let existOfficialObj = this.OFFICIAL_RECEIPT_DETAILS_LIST.find(
        (item) => item.CUSTOMER_SYS_ID === this.OFFICIAL_RECEIPT_CUSTOMER
      );
      if (existOfficialObj) {
        this.OFFICIAL_RECEIPT_PDF_OBJ = existOfficialObj;
      }
    },

    //Function To Generate Official Receipt PDF
    //Author : Prasanta Saren
    generateOfficeReceipt() {
      this.$refs.htmlPdfForOfficialReceipt.generatePdf();
    },

    //Function To Add Invoice No And Date In Official Receipt
    //Author : Riju mukherjee
    AddInvoiceNumberAndDateForOfficialReceipt() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.officalreceipt["ITEM"] = "OFFICIAL_RECEIPT";
      this.officalreceipt["CREATED_BY"] = user_details.UserId;
      this.officalreceipt["VEHICLE_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;
      this.officalreceipt["VEHICLE_NUMBER"] = this.LOCAL_VEHICLE_NUMBER;
      this.officalreceipt["CUSTOMER_SYS_ID"] = this.OFFICIAL_RECEIPT_CUSTOMER;
      this.axios
        .post(apiEndPoints.vehicle_document_add, this.officalreceipt)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.officalreceipt.OFFICIAL_RECEIPT_INVOICE_NO = "";
            this.officalreceipt.OFFICIAL_RECEIPT_INVOICE_DATE = "";
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //For Tax Commision Invoice Tab
    //Function To Get Loan Commision Details
    //Author : Prasanta Saren
    getLoanCommisionDetails() {
      this.axios
        .get(apiEndPoints.get_taxcommissioninvoice, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
            VEHICLE_NO: this.LOCAL_VEHICLE_NUMBER,
          },
        })
        .then((response) => {
          //console.log("getLoanCommisionDetails",response);
          this.LOAN_COMMISSION_DETAILS = response.data.response[0];
          this.VEHICLE_NO =response.data.response[0].VEHICLE_NUMBER
          this.INVOICE_NO =response.data.response[0].INVOICE_NO
          this.INVOICE_DATE =response.data.response[0].INVOICE_DATE
          this.FINANCE_COMPANY_NAME =response.data.response[0].FINANCE_COMPANY
          this.FINANCE_COMPANY_ADDRESS =response.data.response[0].ADDRESS
          this.CUSTOMER_NAME =response.data.response[0].CUSTOMER_NAME
          this.CUSTOMER_ADDRESS =response.data.response[0].ADDRESS
          this.CHASSIS_NO =response.data.response[0].CHASIS_NO
          this.COMMISSION_BEFORE_GST =response.data.response[0].LOAN_AMOUNT_WITHOUT_TAX
          this.COMMISSION_GST_AMOUNT =response.data.response[0].LOAN_COMMISSION
          this.COMMISSION_INCLUDE_GST_AMOUNT ="0"
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Generate Tax Commission Invoice PDF
    //Author : Prasanta Saren
    generateReportForTaxCommissionInvoice() {
      this.$refs.htmlPdfForTaxCommissionInvoice.generatePdf();
    },

    //Function To Add Invoice No And Date In Tax Commission Invoice
    //Author : Riju mukherjee
    AddInvoiceNumberAndDateForTaxCommissionInvoice() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.taxcommisoninvoicedata["ITEM"] = "TAX_COMMISSION";
      this.taxcommisoninvoicedata["CREATED_BY"] = user_details.UserId;
      this.taxcommisoninvoicedata["VEHICLE_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;
      this.axios
        .post(apiEndPoints.vehicle_document_add, this.taxcommisoninvoicedata)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.taxcommisoninvoicedata.TAX_COMMISSION_INVOICE_DATE = "";
            this.taxcommisoninvoicedata.TAX_COMMISSION_INVOICE_NO = "";
            this.getLoanCommisionDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //-----------------------------------For Loan Info Section End--------------------------------

    //-----------------------------------For Tax Invoice Section Start----------------------------
    //For Manage Tax Invoice Tab
    //Function To Onchange Item Description For Manage Tax Invoice
    //Author :
    onChangeItemDescriptionForMIT() {
      this.addmanagetaxinvoice.DESCRIPTION =
        this.item_description_details_for_MTI.find(
          (o) =>
            o.ITEM_LIST_SYS_ID === this.addmanagetaxinvoice.ITEM_LIST_SYS_ID
        ).DESCRIPTION;
    },

    //Function to Get Item Description List For Manage Tax Invoice Tab
    //Author :
    getItemDescriptionListForManageTaxInvoice() {
      this.axios
        .get(apiEndPoints.item_description, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.item_description_details_for_MTI = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Reset Manage Tax Invoice Form
    //Author : Prasanta saren
    resetManageTaxInvoiceForm() {
      this.addmanagetaxinvoice.DESCRIPTION = "";
      this.addmanagetaxinvoice.UNIT_PRICE = "";
      this.addmanagetaxinvoice.QTY = "";
      this.addmanagetaxinvoice.TAX_SYS_ID = "";
      this.addmanagetaxinvoice.TAX_VALUE = "";
      this.addmanagetaxinvoice.TOTAL_PRICE = "";
      this.addmanagetaxinvoice.ITEM_LIST_SYS_ID = "";
    },

    //Function To Add Manage Tax Invoice
    //Author : Prasanta Saren
    AddManageTaxinvoice() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addmanagetaxinvoice["CREATED_BY"] = user_details.UserId;
      //console.log("AddManageTaxinvoice",JSON.stringify(this.addmanagetaxinvoice));
      this.axios
        .post(apiEndPoints.Add_tax, this.addmanagetaxinvoice)
        .then((response) => {
          if (response.data.status == "true") {
            this.resetManageTaxInvoiceForm();
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getManageTaxInvoiceDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Get Manage Tax Invoice Details
    //Author : Prasanta Saren
    getManageTaxInvoiceDetails() {
      this.axios
        .get(apiEndPoints.Tax_detailes, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
            PAGE_SIZE: 1000,
            PAGE_NO: 100,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            let responsArray = response.data.response;
            let ManageTaxInvoiceDetails = [];
            responsArray.forEach((item, index) => {
              if (item.RECORD_SYS_ID == -1) {
                item["RECORD_SYS_ID"] = "-1" + index;
                item["EDIT_FLAG"] = "N";
              } else {
                item["EDIT_FLAG"] = "Y";
              }
              ManageTaxInvoiceDetails.push(item);
            });
            this.ManageTaxInvoiceDetails = ManageTaxInvoiceDetails;
          } else {
            this.ManageTaxInvoiceDetails = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Update Manage Tax Invoice
    //Author : Prasanta Saren
    updateManageTaxInvoice() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.updat_user["ITEM"] = "UPDATE";
      this.updat_user["TOTAL_PRICE"] = this.updat_user.TOTAL_AMOUNT;
      this.updat_user["RECOED_SYS_ID"] =
        this.addmanagetaxinvoice.VEHICLE_SYS_ID;
      this.updat_user["CREATED_BY"] = user_details.UserId;
      //console.log(JSON.stringify(this.updat_user));
      this.axios
        .post(apiEndPoints.Add_tax, this.updat_user)
        .then((response) => {
          if (response.data.status == "true") {
            this.getManageTaxInvoiceDetails();
            Global.showSuccessAlert(true, "success", response.data.response);
            $("#manage_tax_invoice_update_modal").modal("hide");
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Delete Manage Tax Invoice
    //Author :
    async deleteManageTaxInvoice(data) {
      const result = await Global.showConfirmationAlert(
        "Are you sure?",
        "you want to delete this record!",
        "warning"
      );
      if (result.isConfirmed) {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarryda = {
          ITEM: "DELETE",
          VEHICLE_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
          DESCRIPTION: data.DESCRIPTION,
          UNIT_PRICE: data.UNIT_PRICE,
          RECORD_SYS_ID: data.RECORD_SYS_ID,
          CREATED_BY: user_details.UserId,
        };
        this.axios
          .post(apiEndPoints.Add_tax, postarryda)
          .then((response) => {
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getManageTaxInvoiceDetails();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },

    //For Tax Invoice Tab
    //Function To Get Invoice Details With Tax
    //Author :
    getInvoiceDetailsWithTax() {
      this.axios
        .get(apiEndPoints.view_tax_note_info, {
          params: {
            ITEM: "WITH_TAX_INVOICE",
            VEHICLE_NO: "null",
            RECORD_SYS_ID: this.LOCAL_RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "false") {
          
            // Global.showErrorAlert(true, "error", response.data.response);
            this.OTHERT_AMOUNT_GST_AMOUNT = "0"
this.HP_FACILITIES_FEE_NEW =   "-"
this.LOAN_DISBURSEMENT_AMOUNT =   "-"
this.RECEIVED_DATE =   "-"
this.MARK_UP_FEE =   "0"
            this.truettax = false;
          } else {
            this.truettax = true;
            this.InvoiceDetailsWithTax = response.data.response[0];

            this.LESS_TRADE_IN_TAX =response.data.response[0].TRADE_IN_AMOUNT 
            this.LESS_TRADE_IN_FINANCIAL_TAX =response.data.response[0].TRADE_IN_REIMBURSEMENT
            this.LESS_PAYMENT_PREVIOUSLY_MADE_TAX =response.data.response[0].PAYMENT_MADE
            this.HIRE_PURCHASE_AMOUNT_DUE_TAX =response.data.response[0].HIRE_PURCHASE_AMOUNT_DUE_TAX
        
this.OTHERT_AMOUNT_GST_AMOUNT =   this.InvoiceDetailsWithTax.OTHERT_AMOUNT_GST_AMOUNT || "-"
this.HP_FACILITIES_FEE_NEW =   this.InvoiceDetailsWithTax.HP_FACILITIES_FEE ||"-"
this.LOAN_DISBURSEMENT_AMOUNT =   this.InvoiceDetailsWithTax.LOAN_DISBURSEMENT_AMOUNT ||"-"
this.RECEIVED_DATE =   this.InvoiceDetailsWithTax.RECEIVED_DATE ||"-"
this.MARK_UP_FEE =   this.InvoiceDetailsWithTax.SELLING_PRICE_WITHOUT_GST - this.InvoiceDetailsWithTax.ASKING_PRICE ||"0"

            let ADMIN_FEE_TAX_VALUE =
              this.InvoiceDetailsWithTax.ADMIN_FEE_TAX_VALUE;
            this.InvoiceDetailsWithTax["ADMIN_FEE_TAX_VALUE"] =
              parseFloat(ADMIN_FEE_TAX_VALUE).toFixed(2);
            this.InvoiceDetailsWithTax["OTHER_FEE_TAX_VALUE"] = parseFloat(
              this.InvoiceDetailsWithTax.OTHER_FEE_TAX_VALUE
            ).toFixed(2);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function to Add Invoice No And Date In Tax Invoice
    //Author : Riju mukherjee
    AddInvoiceNoAndDateForTaxInvoice() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.taxinvoicedata["ITEM"] = "TAX_INVOICE";
      this.taxinvoicedata["CREATED_BY"] = user_details.UserId;
      this.axios
        .post(apiEndPoints.vehicle_document_add, this.taxinvoicedata)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.ResetInvoiceNoAndDateForTaxInvoice();
            this.getInvoiceDetailsWithTax();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function to Reset Invoice No And Date In Tax Invoice
    //Author : Riju mukherjee
    ResetInvoiceNoAndDateForTaxInvoice() {
      this.taxinvoicedata.TAX_INVOICE_NO = "";
      this.taxinvoicedata.TAX_INVOICE_DATE = "";
    },

    //Generate Report For Tax Invoice
    generateReportTax() {
      this.$refs.htmlPdf3.generatePdf();
    },

    //For Invoice Tab
    //Function To Get Invoice Details Without Tax
    //Author :
    getInvoiceDetailsWithoutTax() {
      this.axios
        .get(apiEndPoints.view_tax_note_info, {
          params: {
            ITEM: "WITHOUT_TAX_INVOICE",
            VEHICLE_NO: "null",
            RECORD_SYS_ID: this.LOCAL_RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
            this.truettax = false;
          } else {
            this.truettax = true;
            this.InvoiceDetailsWithoutTax = response.data.response[0];
            this.LOAN_COMMISSION_DETAILS = response.data.response[0];
            this.VEHICLE_NO =response.data.response[0].VEHICLE_NUMBER
            this.CHASSIS_NO =response.data.response[0].CHASIS_NO
            this.ENGINE_NO =response.data.response[0].ENGINE_NO
            this.INVOICE_NO =response.data.response[0].INVOICE_NO
            this.TAX_INVOICE_DATE =response.data.response[0].TAX_INVOICE_DATE
            this.VSA_NUMBER =response.data.response[0].VSA_NO
            this.HP_CHARGES_TAX =response.data.response[0].HP_FEE_UNIT_PRICE
            this.LOAN_ADVANCE_PAYMENT_TAX =response.data.response[0].LOAN_ADVANCE
            this.INSURANCE_TAX =response.data.response[0].INSURANCE_PREMIUM
         
            this.SALES_DEPOSIT_TAX =response.data.response[0].DEPOSIT_AMOUNT
            this.FINANCE_COMPANY =response.data.response[0].FINANCE_COMPANY
        
            this.LOAN_AMOUNT_TAX =response.data.response[0].LOAN_AMOUNT
            this.LOAN_TENURE_TAX =response.data.response[0].LOAN_TENURE
            this.INTEREST_RATE_TAX =response.data.response[0].INTEREST_RATE
            this.AMOUNT_DUE_FROM_CUSTOMER_TAX =response.data.response[0].BALANCE_DUE
            this.INVOICE_AMOUNT_TAX =response.data.response[0].TOTAL_PAYABLE
            this.CUSTOMER_NAME =response.data.response[0].CUSTOMER_NAME
            this.CUSTOMER_ADDRESS =response.data.response[0].ADDRESS_LINE_1
            this.SALES_PERSON =response.data.response[0].INCHARGE_NAME
            this.GST_METHOD ="Gross Margin"
           
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function to Add Invoice No And Date In  Invoice
    //Author : Riju mukherjee
    AddInvoiceNoAndDateForInvoice() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.invoice_obj["ITEM"] = "TAX_INVOICE";
      this.invoice_obj["CREATED_BY"] = user_details.UserId;
      this.invoice_obj["VEHICLE_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;
      this.axios
        .post(apiEndPoints.vehicle_document_add, this.invoice_obj)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.invoice_obj.TAX_INVOICE_NO = "";
            this.invoice_obj.TAX_INVOICE_DATE = "";
            this.getInvoiceDetailsWithoutTax();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Generate Report for Invoice
    generateReportInvoice() {
      this.$refs.htmlPdfForInvoice.generatePdf();
    },
    //-----------------------------------For Tax Invoice Section End----------------------------

    //-----------------------------------For Credit Note  Section Start--------------------------
    //For Manage Credit Note Tab
    //Function To Manage Credit Note Issued Type OnChange
    //Author : Prasanta Saren
    onChnageTaxCreditIssuedTypeFun() {
      let IssuedType = this.TaxCreditIssuedType;
      if (IssuedType == "Vendor") {
        this.TAX_CREDIT_CUSTOMER_INFO = "";
        this.DisplayTaxCreditCustomerType = "";
        this.DisplayTaxCreditCustomerIdNumber = "";
        this.DisplayTaxCreditCustomerName = "";
        this.DisplayTaxCreditCustomerAddress = "";
        this.GetAllVendorDetails();
      } else if (IssuedType == "Customer") {
        this.TAX_CREDIT_VENDOR_INFO = "";
        this.DisplayTaxCreditVendorCode = "";
        this.DisplayTaxCreditVendorName = "";
        this.DisplayTaxCreditVendorAddress = "";
        this.GetAllCustomerInfo();
      } else {
        this.TAX_CREDIT_VENDOR_INFO = "";
        this.DisplayTaxCreditVendorCode = "";
        this.DisplayTaxCreditVendorName = "";
        this.DisplayTaxCreditVendorAddress = "";
        this.TAX_CREDIT_CUSTOMER_INFO = "";
        this.DisplayTaxCreditCustomerType = "";
        this.DisplayTaxCreditCustomerIdNumber = "";
        this.DisplayTaxCreditCustomerName = "";
        this.DisplayTaxCreditCustomerAddress = "";
      }
    },

    //Function To Manage Credit Note Vendor Info onKeyUp
    //Author : Prasanta Saren
    onKeyUpTaxCreditVendorInfo() {
      if (this.TAX_CREDIT_VENDOR_INFO == "") {
        this.DisplayTaxCreditVendorCode = "";
        this.DisplayTaxCreditVendorName = "";
        this.DisplayTaxCreditVendorAddress = "";
        this.CUSTOMER_SYS_ID = "";
        this.TAX_CREDIT_CUSTOMER_INFO = "";
        this.DisplayTaxCreditCustomerType = "";
        this.DisplayTaxCreditCustomerIdNumber = "";
        this.DisplayTaxCreditCustomerName = "";
        this.DisplayTaxCreditCustomerAddress = "";
      }
    },

    //Function To OnChange Manage Credit Note Vendor Info
    //Author : Prasanta Saren
    onChnageTaxCreditVendorInfo() {
      let vendorName = this.TAX_CREDIT_VENDOR_INFO;
      let vendorObj = this.AllVendordetails.find(
        (items) => items.VENDOR_NAME == vendorName
      );
      if (vendorObj) {
        this.MCN_CUSTOMER_SYS_ID = vendorObj.VENDOR_SYS_ID;
        this.DisplayTaxCreditVendorName = vendorObj.VENDOR_NAME;
        this.DisplayTaxCreditVendorCode = vendorObj.VENDOR_CODE;
        this.DisplayTaxCreditVendorAddress =
          vendorObj.ADDRESS_LINE_1 +
          "," +
          vendorObj.COUNTRY_NAME +
          "-" +
          vendorObj.PIN_CODE;
      }
    },

    //Function To Manage Credit Note Customer Info onKeyUp
    //Author : Prasanta Saren
    onKeyUpTaxCreditCustomerInfo() {
      if (this.TAX_CREDIT_CUSTOMER_INFO == "") {
        this.DisplayTaxCreditCustomerType = "";
        this.DisplayTaxCreditCustomerIdNumber = "";
        this.DisplayTaxCreditCustomerName = "";
        this.DisplayTaxCreditCustomerAddress = "";
        this.CUSTOMER_SYS_ID = "";
      }
    },

    //Function To Manage Credit Note Customer Info onChange
    //Author : Prasanta Saren
    onChnageTaxCreditCustomerInfo() {
      let CUSTOMER_NAME = this.TAX_CREDIT_CUSTOMER_INFO;
      let cusObj = this.AllCustomerArray.find(
        (items) => items.CUSTOMER_NAME == CUSTOMER_NAME
      );
      if (cusObj) {
        this.DisplayTaxCreditCustomerType = cusObj.CUSTOMER_TYPE;
        this.DisplayTaxCreditCustomerIdNumber = cusObj.ID_NUMBER;
        this.DisplayTaxCreditCustomerName = cusObj.CUSTOMER_NAME;
        let address =
          cusObj.ADDRESS_LINE_1 +
          " , " +
          cusObj.COUNTRY_NAME +
          "-" +
          cusObj.PIN_CODE;
        this.DisplayTaxCreditCustomerAddress = address;
        this.MCN_CUSTOMER_SYS_ID = cusObj.CUSTOMER_SYS_ID;
      }
    },

    //Function To Get Manage Credit Note Details
    //Author : Prasanta Saren
    getManageCreditNoteDetails() {
      this.axios
        .get(apiEndPoints.view_credit_note_information, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "false") {
            this.ManageCreditNoteDetailsArray = [];
          } else {
            this.credit_note = response.data.response;
            this.ManageCreditNoteDetailsArray = response.data[0];
            this.PAYMENT_TERMS = response.data[0].PAYMENT_TERMS;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Reset Manage Credit Note Form
    //Author : Prasanta Saren
    resetManageCreditNoteForm() {
      this.TaxCreditIssuedType = "";
      this.TAX_CREDIT_VENDOR_INFO = "";
      this.DisplayTaxCreditVendorCode = "";
      this.DisplayTaxCreditVendorName = "";
      this.DisplayTaxCreditVendorAddress = "";
      this.TAX_CREDIT_CUSTOMER_INFO = "";
      this.DisplayTaxCreditCustomerType = "";
      this.DisplayTaxCreditCustomerIdNumber = "";
      this.DisplayTaxCreditCustomerName = "";
      this.DisplayTaxCreditCustomerAddress = "";
      this.ManageCreditNoteObj.PAYMENT_TERMS = "";
      this.ManageCreditNoteObj.QTY = "";
      this.ManageCreditNoteObj.UNIT_PRICE = "";
      this.ManageCreditNoteObj.TAX_SYS_ID = "";
      this.ManageCreditNoteObj.TAX_AMOUNT = "";
      this.ManageCreditNoteObj.DESCRIPTION = "";
      this.ManageCreditNoteObj.AMOUNT = "";
    },

    //Function To Add Manage Credit Note Details
    //Author : Prasanta Saren
    AddManageCreditnote() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.ManageCreditNoteObj["RECORD_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;
      this.ManageCreditNoteObj["VEHICLE_NUMBER"] = this.LOCAL_VEHICLE_NUMBER;
      this.ManageCreditNoteObj["CREATED_BY"] = user_details.UserId;
      this.ManageCreditNoteObj["SUB_ITEM"] = "ADD_UPDATE";
      this.ManageCreditNoteObj["CUSTOMER_ADDRESS"] =
        this.DisplayTaxCreditCustomerAddress ||
        this.DisplayTaxCreditVendorAddress;
      this.ManageCreditNoteObj["ISSUE_TO_CUSTOMER_TYPE"] =
        this.TaxCreditIssuedType;
      this.ManageCreditNoteObj["CUSTOMER_SYS_ID"] = this.MCN_CUSTOMER_SYS_ID;
      this.axios
        .post(apiEndPoints.add_vehicle_details, this.ManageCreditNoteObj)
        .then((response) => {
          if (response.data.status == "true") {
            this.resetManageCreditNoteForm();
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getManageCreditNoteDetails();
          }
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Update Manage Credit Note
    //Author : Prasanta Saren
    updateManageCreditNote() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.updat_user["ITEM"] = "CREDIT_NOTE";
      this.updat_user["SUB_ITEM"] = "ADD_UPDATE";
      this.updat_user["VEHICLE_NUMBER"] = this.LOCAL_VEHICLE_NUMBER;
      this.updat_user["CREDIT_NOTE_RECORD_SYS_ID"] =
        this.updat_user.CREDIT_NOTE_DETAIL_ID;
      this.updat_user["RECORD_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;
      this.updat_user["CREATED_BY"] = user_details.UserId;
      this.axios
        .post(apiEndPoints.add_vehicle_details, this.updat_user)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getManageCreditNoteDetails();
            $("#manage_credit_note_update_modal").modal("hide");
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Delete Manage Credit Note
    //Author : Prasanta Saren
    async deleteManageCreditNote(data) {
      const result = await Global.showConfirmationAlert(
        "Are you sure?",
        "you want to delete this record!",
        "warning"
      );
      if (result.isConfirmed) {
        NProgress.start();
        NProgress.set(0.1);
        this.loading = true;
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarrydata = {
          ITEM: "CREDIT_NOTE",
          SUB_ITEM: "DELETE",
          VEHICLE_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
          VEHICLE_NUMBER: this.LOCAL_VEHICLE_NUMBER,
          RECORD_SYS_ID: data.RECORD_SYS_ID,
          CREDIT_NOTE_RECORD_SYS_ID: data.CREDIT_NOTE_DETAIL_ID,
          CREATED_BY: user_details.UserId,
        };
        this.axios
          .post(apiEndPoints.add_vehicle_details, postarrydata)
          .then((response) => {
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getManageCreditNoteDetails();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .finally(() => {
            NProgress.done();
            this.loading = false;
          });
      }
    },

    //For Credit Note Tab
    //Function To Get Credit Note Details
    //Author :
    getCreditNoteDetails() {
      this.axios
        .get(apiEndPoints.view_credit_note_info, {
          params: {
            ITEM: "SPECIFIC",
            VEHICLE_NO: this.LOCAL_VEHICLE_NUMBER,
            RECORD_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "false") {
            $("#staticBackdrop-2").modal("hide");
          } else {
            this.pdf_detail = response.data.response;
            this.DynamicproductFlagArr = response.data[0];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function to Add Invoice No And Date In  Invoice
    //Author :
    AddInvoiceNoAndDateForCreditNote() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.creditnote_doc_obj["ITEM"] = "CREDIT_NOTE";
      this.creditnote_doc_obj["CREATED_BY"] = user_details.UserId;
      this.creditnote_doc_obj["VEHICLE_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;
      this.axios
        .post(apiEndPoints.vehicle_document_add, this.creditnote_doc_obj)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.creditnote_doc_obj.CREDIT_NOTE_INVOICE_NO = "";
            this.creditnote_doc_obj.CREDIT_NOTE_INVOICE_DATE = "";
            this.getCreditNoteDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (err.response.status != 401 || err.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Generate Credit Note Documents
    //Author :
    generateReportcredit() {
      this.$refs.html2Pdf.generatePdf();
    },
    //-----------------------------------For Credit Note Section End--------------------------

    //-----------------------------------For Cost Center Section Start------------------------
    //Function To Get Cost Type Details
    //Author :
    getCostTypeInfo() {
      this.axios
        .get(apiEndPoints.cost_type_info, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.CostTypeDetailsList = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To OnChange Cost Center To_From
    //Author : Prasanta Saren
    onChangeCostCenterToFrom() {
      let CostCenterTo_FomType = this.VehicleCostCenterForm.TO_FROM;
      if (CostCenterTo_FomType == "Vendor") {
        this.COST_CENTER_CUSTOMER_INFO = "";
        this.DisplayCostCenterCustomerType = "";
        this.DisplayCostCenterCustomerIdNumber = "";
        this.DisplayCostCenterCustomerName = "";
        this.DisplayCostCenterCustomerAddress = "";
        this.axios
          .get(apiEndPoints.view_vendor, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 1000,
              PAGE_NO: 1,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllCostCenterVendorDetailsArray = response.data.response;
            } else {
              this.AllCostCenterVendorDetailsArray = "";
            }
          })
          .catch((err) => {
            //console.log(err);
            this.AllCostCenterVendorDetailsArray = "";
          });
      } else if (CostCenterTo_FomType == "Customer") {
        this.COST_CENTER_VENDOR_INFO = "";
        this.DisplayCostCenterVendorName = "";
        this.DisplayCostCenterVendorCode = "";
        this.DisplayCostCenterVendorAddress = "";
        this.axios
          .get(apiEndPoints.view_customer, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 1000,
              PAGE_NO: 100,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllCostCenterCustomerDetailsArray = response.data.response;
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    },

    //Function To OnChange Cost Center Vendor Info
    //Author : Prasanta Saren
    onChnageCostCenterVendorInfo() {
      let vendorObj = this.AllCostCenterVendorDetailsArray.find(
        (items) => items.VENDOR_NAME == this.COST_CENTER_VENDOR_INFO
      );
      if (vendorObj) {
        this.VehicleCostCenterForm.CUSTOMER_SYS_ID = vendorObj.VENDOR_SYS_ID;
        this.DisplayCostCenterVendorName = vendorObj.VENDOR_NAME;
        this.DisplayCostCenterVendorCode = vendorObj.VENDOR_CODE;
        this.DisplayCostCenterVendorAddress =
          vendorObj.ADDRESS_LINE_1 +
          "," +
          vendorObj.COUNTRY_NAME +
          "-" +
          vendorObj.PIN_CODE;
      }
    },

    //Function To OnKeyUp Cost Center Vendor Info
    //Author : Prasanta Saren
    onKeyUpCostCenterVendorInfo() {
      if (this.COST_CENTER_VENDOR_INFO == "") {
        this.DisplayCostCenterVendorName = "";
        this.DisplayCostCenterVendorCode = "";
        this.DisplayCostCenterVendorAddress = "";
        this.axios
          .get(apiEndPoints.view_vendor, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 1000,
              PAGE_NO: 1,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllCostVendorDetailsArray = response.data.response;
            } else {
              this.AllCostCenterVendorDetailsArray = "";
            }
          })
          .catch((err) => {
            //console.log(err);
            this.AllCostCenterVendorDetailsArray = "";
          });
      }
    },

    //Function To OnKeyUp Cost Center Customer Info
    //Author : Prasanta Saren
    onKeyUpCostCenterCustomerInfo() {
      if (this.COST_CENTER_CUSTOMER_INFO == "") {
        this.DisplayCostCenterCustomerType = "";
        this.DisplayCostCenterCustomerIdNumber = "";
        this.DisplayCostCenterCustomerName = "";
        this.DisplayCostCenterCustomerAddress = "";
        this.axios
          .get(apiEndPoints.view_customer, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
              PAGE_SIZE: 20,
              PAGE_NO: 1,
            },
          })
          .then((response) => {
            if (response.data.status == "true") {
              this.AllCostCenterCustomerDetailsArray = response.data.response;
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    },

    //Function To OnChange Cost Center Customer Info
    //Author : Prasanta Saren
    onChnageCostCenterCustomerInfo() {
      let cusObj = this.AllCostCenterCustomerDetailsArray.find(
        (items) => items.CUSTOMER_NAME == this.COST_CENTER_CUSTOMER_INFO
      );
      if (cusObj) {
        this.VehicleCostCenterForm.CUSTOMER_SYS_ID = cusObj.CUSTOMER_SYS_ID;
        this.DisplayCostCenterCustomerType = cusObj.CUSTOMER_TYPE;
        this.DisplayCostCenterCustomerIdNumber = cusObj.ID_NUMBER;
        this.DisplayCostCenterCustomerName = cusObj.CUSTOMER_NAME;
        let address =
          cusObj.ADDRESS_LINE_1 +
          " , " +
          cusObj.COUNTRY_NAME +
          "-" +
          cusObj.PIN_CODE;
        this.DisplayCostCenterCustomerAddress = address;
      }
    },

    //Function To resetVehicleCostCenterForm
    //Author : Prasanta Saren
    resetVehicleCostCenterForm() {
      this.COST_CENTER_VENDOR_INFO = "";
      this.VehicleCostCenterForm.COST_TYPE = "";
      this.VehicleCostCenterForm.DETAIL_DESCRIPTION = "";
      this.VehicleCostCenterForm.PAYMENT_MODE = "";
      this.VehicleCostCenterForm.AMOUNT_BEF_GST = "";
      this.VehicleCostCenterForm.PV_DESCRIPTION = "";
      this.VehicleCostCenterForm.TAX_SYS_ID = "";
      this.VehicleCostCenterForm.GST_AMOUNT = "";
      this.VehicleCostCenterForm.TOTAL_AMOUNT = "";
      this.VehicleCostCenterForm.CUSTOMER_SYS_ID = "";
      this.VehicleCostCenterForm.CUSTOMER_NAME = "";
      this.VehicleCostCenterForm.TO_FROM = "";
      this.VehicleCostCenterForm.INVOICE_NO = "";
      this.VehicleCostCenterForm.PV_NO = "";
      this.VehicleCostCenterForm.REQUIRE_PAYMENT_VOUCHER = "";
      this.VehicleCostCenterForm.GST_PERCENTAGE = "";
      (this.DisplayCostCenterCustomerType = ""),
        (this.DisplayCostCenterCustomerIdNumber = ""),
        (this.DisplayCostCenterCustomerName = ""),
        (this.DisplayCostCenterCustomerAddress = ""),
        (this.DisplayCostCenterVendorName = "");
      this.DisplayCostCenterVendorCode = "";
      this.DisplayCostCenterVendorAddress = "";
      this.AdditionalCostType = [];
            this.AdditionalCostType.push({
              DETAIL_DESCRIPTION: "",
              AMOUNT_BEF_GST: "",
            });
            this.GlobalCostAmount = 0;
    },
    resetformpayinvoice(){
      this.payinvoice.PAYMENT_CODE = "";
      this.payinvoice.PAYMENT_DATE = "";
      this.payinvoice.BANK_SYS_ID = "";
      this.payinvoice.PAYMENT_TO = "";
    },

    //Function To Add Vehicle Cost Center Details
    //Author : Prasanta Saren
    addVehicleCostCenterDetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.VehicleCostCenterForm["CREATED_BY"] = user_details.UserId;
      if (this.VehicleCostCenterForm.TO_FROM == "Vendor")
        this.VehicleCostCenterForm["CUSTOMER_NAME"] =
          this.DisplayCostCenterVendorName;
      else if (this.VehicleCostCenterForm.TO_FROM == "Customer")
        this.VehicleCostCenterForm["CUSTOMER_NAME"] =
          this.DisplayCostCenterCustomerName;
      let InputsArrayObj = [];
      this.AdditionalCostType.forEach((data) => {
        InputsArrayObj.push({
            ITEM: "ADD",
            RECORD_SYS_ID: 0,
            CREATED_BY: this.VehicleCostCenterForm.CREATED_BY,
            VEHICLE_NUMBER: this.VehicleCostCenterForm.VEHICLE_NUMBER,
            VEHICLE_SYS_ID: this.VehicleCostCenterForm.VEHICLE_SYS_ID,
            COST_TYPE: data.COST_TYPE,
            DETAIL_DESCRIPTION: data.DETAIL_DESCRIPTION,
            UNIT_AMOUNT : data.AMOUNT_BEF_GST,
            TO_FROM: this.VehicleCostCenterForm.TO_FROM,
            PAYMENT_MODE: this.VehicleCostCenterForm.PAYMENT_MODE,
            TOTAL_AMOUNT_BEF_GST: this.VehicleCostCenterForm.AMOUNT_BEF_GST,
            TAX_SYS_ID: this.VehicleCostCenterForm.TAX_SYS_ID,
            GST_AMOUNT: this.VehicleCostCenterForm.GST_AMOUNT,
            TOTAL_AMOUNT: this.VehicleCostCenterForm.TOTAL_AMOUNT,
            CUSTOMER_SYS_ID: this.VehicleCostCenterForm.CUSTOMER_SYS_ID,
            CUSTOMER_NAME: this.VehicleCostCenterForm.CUSTOMER_NAME,
            INVOICE_NO: this.VehicleCostCenterForm.INVOICE_NO,
            PV_NO: this.VehicleCostCenterForm.PV_NO,
            REQUIRE_PAYMENT_VOUCHER: this.VehicleCostCenterForm.REQUIRE_PAYMENT_VOUCHER,
            GST_PERCENTAGE: this.VehicleCostCenterForm.GST_PERCENTAGE
        });
    });
      this.axios
        .post(apiEndPoints.add_update_vehicle_cost, InputsArrayObj)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            
            this.resetVehicleCostCenterForm();
            this.getCostCenterDetailsList(
              this.VehicleCostCenterForm.VEHICLE_SYS_ID
            );
            this.getVehicleCostCreatedDetailsList(
              this.VehicleCostCenterForm.VEHICLE_SYS_ID
            );
            this.getCostCenterCreatedSalesList(
              this.VehicleCostCenterForm.VEHICLE_SYS_ID
            );
            this.getCostCenterCreatedPurchaseList(
              this.VehicleCostCenterForm.VEHICLE_SYS_ID
            );
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Get Cost Center Created Sales List
    //Author : Prasanta Saren
    getCostCenterCreatedSalesList(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.cost_sales, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.CostCreatedSalesList = response.data.response;
          } else {
            this.CostCreatedSalesList = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Get Cost Center Created Purchase List
    //Author : Prasanta Saren
    getCostCenterCreatedPurchaseList(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.cost_purchase, {
          params: { ITEM: "SPECIFIC", RECORD_SYS_ID: RECORD_SYS_ID },
        })
        .then((response) => {
          if (response.data.status == "true") {
         
            this.CostCreatedPurchaseList = response.data.response;
this.TOTAL_COST =response.data.Grand_Total
this.Grand_Total_GST=response.data.Grand_Total_GST
var costcenteramount =this.calculation_plstatement -this.TOTAL_COST 
this.costcenteramount =costcenteramount


          //   let totalAmount = 0.0;

          //   for (const entry of data) {
          //       const amount = parseFloat(entry["AMOUNT"]);
          //       totalAmount += amount;
          //   }
            
          //  alert("Total Amount:", totalAmount);




          } else {
            this.CostCreatedPurchaseList = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Get Cost Center Details List
    //Author : Prasanta Saren
    getCostCenterDetailsList(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.vehic_cost, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.CostCenterDetailsList = response.data.response;
          } else {
            this.CostCenterDetailsList = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Vehicle Cost Created Details List
    //Author : Prasanta Saren
    getVehicleCostCreatedDetailsList(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.vehicle_cost, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: RECORD_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.VehicleCostCreatedDetailsList = response.data.response;
          } else {
            this.VehicleCostCreatedDetailsList = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Update Vehicle Cost
    //Author : Prasanta Saren
    updateVehicleCostCenterDetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.updat_user["ITEM"] = "UPDATE";
      this.updat_user["RECORD_SYS_ID"] = this.updat_user.VEHICLE_COST_SYS_ID;
      this.updat_user["VEHICLE_NUMBER"] = this.LOCAL_VEHICLE_NUMBER;
      this.updat_user["VEHICLE_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;
      this.updat_user["CREATED_BY"] = user_details.UserId;
      if (this.VehicleCostCenterForm.TO_FROM == "Vendor")
        this.updat_user["CUSTOMER_NAME"] = this.DisplayCostCenterVendorName;
      else if (this.VehicleCostCenterForm.TO_FROM == "Customer")
        this.updat_user["CUSTOMER_NAME"] = this.DisplayCostCenterCustomerName;
      this.updat_user["TO_FROM"] = this.VehicleCostCenterForm.TO_FROM;
      this.axios
        .post(apiEndPoints.add_update_vehicle_cost, this.updat_user)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.resetVehicleCostCenterForm();
            this.getCostCenterDetailsList(this.GLOBAL_RECORD_SYS_ID);
            this.getVehicleCostCreatedDetailsList(this.GLOBAL_RECORD_SYS_ID);
            this.getCostCenterCreatedSalesList(this.GLOBAL_RECORD_SYS_ID);
            this.getCostCenterCreatedPurchaseList(this.GLOBAL_RECORD_SYS_ID);
            $("#cost_center_update_modal").modal("hide");
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },



    updatePayInvoice() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.payinvoice["ITEM"] = "UPDATE_PAYMENT";
      this.payinvoice["RECORD_SYS_ID"] = this.updat_user.VEHICLE_COST_SYS_ID;
      this.payinvoice["PAYMENT_DATE"] =this.updat_user.PAYMENT_DATE ;
      // this.updat_user["VEHICLE_NUMBER"] = this.LOCAL_VEHICLE_NUMBER;
      // this.updat_user["VEHICLE_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;
      // this.updat_user["CREATED_BY"] = user_details.UserId;
      // if (this.VehicleCostCenterForm.TO_FROM == "Vendor")
      //   this.updat_user["CUSTOMER_NAME"] = this.DisplayCostCenterVendorName;
      // else if (this.VehicleCostCenterForm.TO_FROM == "Customer")
      //   this.updat_user["CUSTOMER_NAME"] = this.DisplayCostCenterCustomerName;
      // this.updat_user["TO_FROM"] = this.VehicleCostCenterForm.TO_FROM;
      this.axios
        .post(apiEndPoints.add_update_vehicle_cost, this.payinvoice)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.resetformpayinvoice();
            this.getCostCenterDetailsList(this.GLOBAL_RECORD_SYS_ID);
            this.getVehicleCostCreatedDetailsList(this.GLOBAL_RECORD_SYS_ID);
            this.getCostCenterCreatedSalesList(this.GLOBAL_RECORD_SYS_ID);
            this.getCostCenterCreatedPurchaseList(this.GLOBAL_RECORD_SYS_ID);
            $("#pay_invoice_update_modal").modal("hide");
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Delete Vehicle Cost
    //Author : Prasanta Saren
    async deleteVehicleCost(data) {
      const result = await Global.showConfirmationAlert(
        "Are you sure?",
        "you want to delete this record!",
        "warning"
      );
      if (result.isConfirmed) {
        NProgress.start();
        NProgress.set(0.1);
        this.loading = true;
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarrydata = {
          ITEM: "DELETE",
          RECORD_SYS_ID: data.VEHICLE_COST_SYS_ID,
          VEHICLE_SYS_ID: this.GLOBAL_RECORD_SYS_ID,
          VEHICLE_NO: this.LOCAL_VEHICLE_NUMBER,
          CREATED_BY: user_details.UserId,
        };
        this.axios
          .post(apiEndPoints.add_update_vehicle_cost, postarrydata)
          .then((response) => {
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getCostCenterDetailsList(this.GLOBAL_RECORD_SYS_ID);
              this.getVehicleCostCreatedDetailsList(this.GLOBAL_RECORD_SYS_ID);
              this.getCostCenterCreatedSalesList(this.GLOBAL_RECORD_SYS_ID);
              this.getCostCenterCreatedPurchaseList(this.GLOBAL_RECORD_SYS_ID);
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          })
          .finally(() => {
            NProgress.done();
            this.loading = false;
          });
      }
    },

    //Function To Create Floor Stock Interrest
    //Author : Prasanta Saren
    createFloorstock() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.floorstock["CREATED_BY"] = user_details.UserId;
      if (this.floorstock.FLOOR_STOCK_INTEREST_SYS_ID == "") {
        this.floorstock["ITEM"] = "ADD";
        this.floorstock["RECORD_SYS_ID"] = 0;
      } else {
        this.floorstock["ITEM"] = "UPDATE";
        this.floorstock["RECORD_SYS_ID"] =
          this.floorstock.FLOOR_STOCK_INTEREST_SYS_ID;
      }
      //console.log("createFloorstock",this.floorstock);
      this.axios
        .post(apiEndPoints.create_floorstock, this.floorstock)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            this.resetFloorStockForm();
            this.getCostCenterDetailsList(this.floorstock.VEHICLE_SYS_ID);
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getFloorStockInterestDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Function To Reset Floor Stock Interrest Form
    //Author : Prasanta Saren
    resetFloorStockForm() {
      this.floorstock.FLOOR_STOCK_INTEREST = "";
      this.floorstock.NO_OF_DAYS = "";
      this.floorstock.E_TRANSFER_IN_DATE = "";
      this.floorstock.E_TRANSFER_OUT_DATE = "";
      this.floorstock.PURCHASE_PRICE = "";
      this.floorstock.FLOOR_STOCK_AMOUNT = "";
    },

    //Function To Get Floor Stock Interest Details
    //Author : Prasanta Saren
    getFloorStockInterestDetails() {
      this.axios
        .get(apiEndPoints.view_floorstock, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.floorstock.VEHICLE_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.getfloorstock = response.data.response[0];
            this.floorstock.FLOOR_STOCK_INTEREST =
              this.getfloorstock.FLOOR_STOCK_INTEREST;
            this.floorstock.NO_OF_DAYS = this.getfloorstock.NO_OF_DAYS;
            this.floorstock.E_TRANSFER_IN_DATE =
              this.getfloorstock.E_TRANSFER_IN_DATE;
            this.floorstock.E_TRANSFER_OUT_DATE =
              this.getfloorstock.E_TRANSFER_OUT_DATE;
            this.floorstock.PURCHASE_PRICE = this.getfloorstock.PURCHASE_PRICE;
            this.floorstock.FLOOR_STOCK_AMOUNT =
              this.getfloorstock.FLOOR_STOCK_AMOUNT;
            this.floorstock.FLOOR_STOCK_INTEREST_SYS_ID =
              this.getfloorstock.FLOOR_STOCK_INTEREST_SYS_ID;


this.FLOOR_STOCK_E_TRRASFER_IN_DATE=this.getfloorstock.E_TRANSFER_IN_DATE ||"-";
this.FLOOR_STOCK_E_TRRASFER_OUT_DATE=this.getfloorstock.E_TRANSFER_OUT_DATE ||"-";
this.NO_OF_DAYS=this.getfloorstock.NO_OF_DAYS ||"-" ;
this.FLOOR_STOCK_AMOUNT=this.getfloorstock.FLOOR_STOCK_AMOUNT ||"-";




          } else {
            this.floorstock["FLOOR_STOCK_INTEREST_SYS_ID"] = "";
            this.FLOOR_STOCK_E_TRRASFER_IN_DATE="-";
this.FLOOR_STOCK_E_TRRASFER_OUT_DATE="-";
this.NO_OF_DAYS="-" ;
this.FLOOR_STOCK_AMOUNT="-";
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Calculate Floor Stock Interest
    //Author : Prasanta Saren
    calculateFloorStock() {
      let FLOOR_STOCK_INTEREST = this.floorstock.FLOOR_STOCK_INTEREST;
      if (FLOOR_STOCK_INTEREST != "") {
        let FLOOR_STOCK_AMOUNT =
          ((this.floorstock.FLOOR_STOCK_INTEREST / 30) *
            this.floorstock.NO_OF_DAYS *
            this.floorstock.PURCHASE_PRICE) /
          100;
        this.floorstock.FLOOR_STOCK_AMOUNT = FLOOR_STOCK_AMOUNT.toFixed(2);
      } else {
        this.floorstock.FLOOR_STOCK_AMOUNT = "";
      }
    },

    //Function To Generate Payment Voucher
    //Author : Prasanta Saren
    generatePaymentVoucher(obj) {
      this.axios
        .get(apiEndPoints.vehic_cost, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: obj.VEHICLE_COST_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.pdfPaymentVoucherDetails = response.data.response[0];
            this.$refs.htmlPdfPaymentVoucher.generatePdf();
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Function To Calculate No Of Days In Floor Stock
    //Author : Prasanta Saren
    calculateNoOfDaysInFoorStock() {
      let E_TRANSFER_OUT_DATE = this.addupdatebuyerdata.E_TRANSFER_OUT_DATE;
      let E_TRANSFER_IN_DATE = this.addupdatesellerdata.E_TRANSFER_IN_DATE;
      var diff = moment(E_TRANSFER_OUT_DATE, "DD-MM-YYYY").diff(
        moment(E_TRANSFER_IN_DATE, "DD-MM-YYYY"),
        "days"
      );
      if (diff != "" || diff != null) {
        this.floorstock.E_TRANSFER_IN_DATE = E_TRANSFER_IN_DATE;
        this.floorstock.E_TRANSFER_OUT_DATE = E_TRANSFER_OUT_DATE;
        this.floorstock.PURCHASE_PRICE =
          this.addupdatesellerdata.PURCHASE_PRICE_SD;
        this.floorstock.NO_OF_DAYS = diff;
      }
    },

    onAddDetailsDescriptionData(index,keyName,event){
     this.AdditionalCostType[index][keyName] = event.target.value;
     if(keyName == 'AMOUNT_BEF_GST'){
        this.GlobalCostAmount += parseFloat(event.target.value);
        this.VehicleCostCenterForm.AMOUNT_BEF_GST = this.GlobalCostAmount.toFixed(2);
        this.globalCalculateGST('COST_CENTER');
     }
    },
    
    addMoreAdditionalCostType() {
      let template = {
        DETAIL_DESCRIPTION: "",
        AMOUNT_BEF_GST: "",
        COST_TYPE: "",
      };
      let items = this.AdditionalCostType;
      if(items.length >=1 ){
        items.push(template);
        this.AdditionalCostType = items;
        console.log("AdditionalCostType2",this.AdditionalCostType);
      }
      else{
        this.AdditionalCostType.push(template);
      }
      
    },

    async removeAdditionalCostType(index){
      const result = await Global.showConfirmationAlert(
        "Are you sure?",
        "you want to delete this row!",
        "warning"
      );
      if (result.isConfirmed) {
        let items = JSON.parse(JSON.stringify(this.AdditionalCostType));
        items.splice(index, 1);
        this.AdditionalCostType = items;
        let GrandTotalPrice = 0;
        this.AdditionalCostType.forEach((data) => {
            let totalAmount = parseFloat(data.AMOUNT_BEF_GST);
            GrandTotalPrice = GrandTotalPrice + totalAmount;
        });
        this.VehicleCostCenterForm.AMOUNT_BEF_GST = GrandTotalPrice.toFixed(2);
        this.globalCalculateGST('COST_CENTER');
      }
    },

    clickVehicleCostCenterDetailsRow(row){
      console.log(row);
      this.CostCenterDetailsDescriptionItems = row.DESCRIPTION;
      this.$bvModal.show("cost_center_details_description_preview_modal");
    },

    
    //-----------------------------------For Cost Center Section End--------------------------

    freezeInvoice(item){
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let apiParams = "";
      if(item == 'TAX_COMMISSION_INVOICE'){
        apiParams = this.LOAN_COMMISSION_DETAILS;
      }
      else if(item == 'FINANCE_COMPANY_TAX_INVOICE'){
        apiParams = this.finance_company_tax_invoice;
      }
      else if(item == 'FINANCE_COMPANY_INVOICE'){
        apiParams = this.finance_company_invoice;
      }
      else if(item == 'TAX_INVOICE'){
        apiParams = this.InvoiceDetailsWithTax;
      }
      else if(item == 'INVOICE'){
        apiParams = this.InvoiceDetailsWithoutTax;
      }
      this.axios.post(apiEndPoints.create_floorstock, apiParams).then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } 
          else if (response.data.status == false || response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
          }
      })
      .finally(() => {
        NProgress.done();
        this.loading = false;
      });
    },
   
    freezeVechilestatement() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.invoice_obj_new["ITEM"] = "FINANCE_COMPANY_INVOICE";
      this.invoice_obj_new["CREATED_BY"] = user_details.UserId;
      this.invoice_obj_new["VEHICLE_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;

      this.invoice_obj_new["VEHICLE_NO"] =   this.VEHICLE_NO;
      this.invoice_obj_new["RECORD_SYS_ID"] = "0";
      this.invoice_obj_new["CHASSIS_NO"] = this.CHASSIS_NO;
      this.invoice_obj_new["ENGINE_NO"] = this.ENGINE_NO;
      this.invoice_obj_new["FINANCE_COMPANY_NAME"] = this.FINANCE_COMPANY_NAME;
      this.invoice_obj_new["FINANCE_COMPANY_ADDRESS"] =this.FINANCE_COMPANY_ADDRESS;
      this.invoice_obj_new["INVOICE_NO"] = this.INVOICE_NO;
      this.invoice_obj_new["INVOICE_DATE"] = this.INVOICE_DATE;
      this.invoice_obj_new["CUSTOMER_NAME"] = this.CUSTOMER_NAME;
      this.invoice_obj_new["SELLING_PRICE"] =this.SELLING_PRICE;
      this.invoice_obj_new["DEPOSIT_PRICE"] = this.DEPOSIT_PRICE;
      this.invoice_obj_new["LOAN_AMOUNT"] = this.LOAN_AMOUNT;
      this.invoice_obj_new["TOTAL_PAYABLE"] = this.TOTAL_PAYABLE;
      this.axios
        .post(apiEndPoints.freeze_invoice, this.invoice_obj_new)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
           
         
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },
    taxcommisonfredge(){
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.tax_obj_new["ITEM"] = "TAX_COMMISSION_INVOICE";
      this.tax_obj_new["CREATED_BY"] = user_details.UserId;
      this.tax_obj_new["VEHICLE_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;

      this.tax_obj_new["VEHICLE_NO"] =   this.VEHICLE_NO;
      this.tax_obj_new["RECORD_SYS_ID"] = "0";
      this.tax_obj_new["INVOICE_NO"] =  this.INVOICE_NO;
      this.tax_obj_new["INVOICE_DATE"] = this.INVOICE_DATE;
      this.tax_obj_new["FINANCE_COMPANY_NAME"] = this.FINANCE_COMPANY;
      this.tax_obj_new["FINANCE_COMPANY_ADDRESS"] =  this.FINANCE_COMPANY_ADDRESS;
      this.tax_obj_new["CUSTOMER_NAME"] = this.CUSTOMER_NAME;
      this.tax_obj_new["CUSTOMER_ADDRESS"] = this.CUSTOMER_ADDRESS;
      this.tax_obj_new["CHASSIS_NO"] = this.CHASSIS_NO;
      this.tax_obj_new["COMMISSION_BEFORE_GST"] =this.COMMISSION_BEFORE_GST;
      this.tax_obj_new["COMMISSION_GST_AMOUNT"] = this.COMMISSION_GST_AMOUNT;
      this.tax_obj_new["COMMISSION_INCLUDE_GST_AMOUNT"] = this.COMMISSION_INCLUDE_GST_AMOUNT;
    
      this.axios
        .post(apiEndPoints.freeze_invoice, this.tax_obj_new)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
           
         
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },
    invoiceFredge(){
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.tax_invoice_obj_new["ITEM"] = "INVOICE_INFO";
      this.tax_invoice_obj_new["CREATED_BY"] = user_details.UserId;
      this.tax_invoice_obj_new["VEHICLE_SYS_ID"] = this.GLOBAL_RECORD_SYS_ID;

      this.tax_invoice_obj_new["LESS_TRADE_IN_FINANCIAL_TAX "] =   this.LESS_TRADE_IN_FINANCIAL_TAX ;
      this.tax_invoice_obj_new["RECORD_SYS_ID"] = "0";
      this.tax_invoice_obj_new["LESS_PAYMENT_PREVIOUSLY_MADE_TAX "] =  this.LESS_PAYMENT_PREVIOUSLY_MADE_TAX ;
      this.tax_invoice_obj_new["HIRE_PURCHASE_AMOUNT_DUE_TAX"] = this.HIRE_PURCHASE_AMOUNT_DUE_TAX;
      this.tax_invoice_obj_new["VEHICLE_NO"] = this.VEHICLE_NO;
      this.tax_invoice_obj_new["CHASSIS_NO"] =  this.CHASSIS_NO;
      this.tax_invoice_obj_new["ENGINE_NO"] = this.ENGINE_NO;
      this.tax_invoice_obj_new["INVOICE_NO"] = this.INVOICE_NO;
      this.tax_invoice_obj_new["TAX_INVOICE_DATE "] = this.TAX_INVOICE_DATE ;
      this.tax_invoice_obj_new["VSA_NUMBER"] =this.VSA_NUMBER;

      this.tax_invoice_obj_new["HP_CHARGES_TAX"] =   this.HP_CHARGES_TAX;
      this.tax_invoice_obj_new["LOAN_ADVANCE_PAYMENT_TAX"] = this.LOAN_ADVANCE_PAYMENT_TAX;
      this.tax_invoice_obj_new["INSURANCE_TAX "] = this.INSURANCE_TAX ;
      this.tax_invoice_obj_new["SALES_DEPOSIT_TAX "] = this.SALES_DEPOSIT_TAX ;
      this.tax_invoice_obj_new["FINANCE_COMPANY"] = this.FINANCE_COMPANY;
      this.tax_invoice_obj_new["LOAN_AMOUNT_TAX "] = this.LOAN_AMOUNT_TAX ;
      this.tax_invoice_obj_new["LOAN_TENURE_TAX"] =this.LOAN_TENURE_TAX;

      this.tax_invoice_obj_new["INTEREST_RATE_TAX "] =   this.INTEREST_RATE_TAX;
      this.tax_invoice_obj_new["AMOUNT_DUE_FROM_CUSTOMER_TAX"] = this.AMOUNT_DUE_FROM_CUSTOMER_TAX;
      this.tax_invoice_obj_new["INVOICE_AMOUNT_TAX  "] = this.INVOICE_AMOUNT_TAX  ;
   
      this.tax_invoice_obj_new["CUSTOMER_NAME "] =   this.CUSTOMER_NAME;
      this.tax_invoice_obj_new["LESS_CONTRA_TAX "] =  "";
      this.tax_invoice_obj_new["EXCESS_PAYMENT_TAX"] = "";
      this.tax_invoice_obj_new["INDIVIDUAL_AMOUNT_TAX  "] = "" ;
   
      this.tax_invoice_obj_new["PAYMENT_DETAILS_TAX "] =   "";
      this.tax_invoice_obj_new["CUSTOMER_ADDRESS"] = this.CUSTOMER_ADDRESS;
      this.tax_invoice_obj_new["SALES_PERSON  "] = this.SALES_PERSON  ;
   
      this.tax_invoice_obj_new["INTEREST_RATE_TAX "] =   this.INTEREST_RATE_TAX;
      this.tax_invoice_obj_new["LOAN_ADVANCE_PAYMENT_TAX"] = this.LOAN_ADVANCE_PAYMENT_TAX;
      this.tax_invoice_obj_new["GST_METHOD "] = this.GST_METHOD ;
   
      this.axios
        .post(apiEndPoints.freeze_invoice, this.tax_invoice_obj_new)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
           
         
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    
    }

 


  },
};
